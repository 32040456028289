import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@material-ui/core/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';

import textLogoWhite from '../../img/textLogo.png';
import tossPayWhiteLogo from '../../img/tossPayWhite.svg';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { useStyles } from './PaymentModal.styles';
import { getCurrencySymbol, showMessage } from '../../utils/page.utils';
import * as pageService from '../../services/page.services';
import { Currency, PaymentService } from '../../constants/app.constants';

const PaymentModal = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [promoCode, setPromoCode] = useState();
  const [showPromocode, setShowPromoCode] = useState(false);
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const createOrder = async () => {
    setLoading(true);
    try {
      const { subscriptionType, purchaseType } = props;
      const order = await pageService.createOrder({ subscriptionType, purchaseType });
      setOrder(order);
      setShowPromoCode(false);
    } catch (e) {
      showMessage(dispatch, t('subscriptionTab.paymentModal.genericError'));
      props.onClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) createOrder();
  }, [props.open]);

  const validateAndApplyPromoCode = async (orderId, code) => {
    try {
      const { valid, invalidReason } = await pageService.validatePromoCode(orderId, code);

      if (valid) {
        const updatedOrder = await pageService.applyPromoCode(orderId, code);
        setOrder(updatedOrder);
      } else {
        if (invalidReason) {
          showMessage(dispatch, t(`subscriptionTab.paymentModal.promoCodeError.${invalidReason}`));
        } else {
          showMessage(dispatch, t('subscriptionTab.paymentModal.genericError'));
        }
      }
    } catch (e) {
      showMessage(dispatch, t('subscriptionTab.paymentModal.genericError'));
    } finally {
      setPromoCode('');
      setShowPromoCode(false);
    }
  };

  const removePromoCode = async orderId => {
    try {
      const updatedOrder = await pageService.removePromoCode(orderId);
      setOrder(updatedOrder);
    } catch (e) {
      showMessage(dispatch, t('subscriptionTab.paymentModal.genericError'));
    }
  };

  return (
    <Modal className={fontStyle} open={props.open} onClose={() => !props.submitting && props.onClose()}>
      <div className={classes.container}>
        <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />
        {loading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <>
            <div className={classes.orderInfo}>
              {order?.name} {getCurrencySymbol(order?.currency)}
              {order?.price}
            </div>
            {props.submitting ? (
              <CircularProgress />
            ) : (
              <div className={classes.buttonContainer}>
                <div
                  onClick={() => props.onClickPaymentOption(order, PaymentService.STRIPE)}
                  className={classes.stripeButton}
                >
                  credit/debit card
                </div>
                {user.currency === Currency.KRW && (user.id === "ee913f11-6d12-44d4-82d4-6bddbc012aa0") && (
                  <img
                    className={classes.tossButton}
                    src={tossPayWhiteLogo}
                    alt="tossPay"
                    onClick={() => props.onClickPaymentOption(order, PaymentService.TOSS)}
                  />
                )}
              </div>
            )}
            {!props.submitting && !order?.metadata?.promoCode && (
              <>
                {!showPromocode ? (
                  <div className={classes.usePromocode} onClick={() => setShowPromoCode(true)}>
                    {t('subscriptionTab.paymentModal.usePromoCode')}
                  </div>
                ) : (
                  <div className={classes.promoCodeContainer}>
                    <OutlinedInput
                      placeholder={t('subscriptionTab.paymentModal.promoCodePlaceholder')}
                      onChange={e => setPromoCode(e.target.value)}
                      value={promoCode}
                      className={classes.promoCodeInput}
                    />
                    <Button
                      // defining style here because for some reason some styles
                      // doesn't get applied correctly on production
                      style={{
                        backgroundColor: '#242A2D',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                        boxShadow: 'none',
                        cursor: 'pointer',
                        color: '#fff',
                      }}
                      onClick={async () => validateAndApplyPromoCode(order.id, promoCode)}
                    >
                      {loading ? (
                        <CircularProgress
                          // defining style here because for some reason some styles
                          // doesn't get applied correctly on production
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      ) : (
                        <div className={classes.buttonText}>{t('subscriptionTab.paymentModal.applyButton')}</div>
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
            {!props.submitting && order?.metadata?.promoCode && (
              <div className={classes.promoCodeApplied}>
                <div className={classes.promoCodeAppliedText}>
                  {t('subscriptionTab.paymentModal.promoCodeApplied')}:
                  <span className={classes.appliedPromocode}>{order.metadata.promoCode}</span>
                </div>
                <DeleteIcon onClick={() => removePromoCode(order.id)} className={classes.deleteButtonImg} />
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default PaymentModal;
