import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@material-ui/core/Modal';
import CircularProgress from '@mui/material/CircularProgress';

import textLogoWhite from '../../img/textLogo.png';
import tossPayWhiteLogo from '../../img/tossPayWhite.svg';

import { useStyles } from './PaymentOptionModal.styles';
import { Currency, PaymentService } from '../../constants/app.constants';
import { showMessage } from '../../utils/page.utils';
import * as pageService from '../../services/page.services';

const PaymentOptionModal = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { t } = useTranslation();
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const createOrder = async () => {
    setLoading(true);
    try {
      const order = await pageService.createOrder({ changeMethod: true });
      setOrder(order);
    } catch (e) {
      showMessage(dispatch, t('subscriptionTab.paymentModal.genericError'));
      props.onClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) createOrder();
  }, [props.open]);

  return (
    <Modal className={fontStyle} open={props.open} onClose={() => props.onClose()}>
      <div className={classes.container}>
        <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />
        {loading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <>
            <div className={classes.buttonContainer}>
              <div
                onClick={() => props.onClickPaymentOption(order, PaymentService.STRIPE)}
                className={classes.stripeButton}
              >
                credit/debit card
              </div>
              {user.currency === Currency.KRW && (user.id === "ee913f11-6d12-44d4-82d4-6bddbc012aa0") && (
                <img
                  className={classes.tossButton}
                  src={tossPayWhiteLogo}
                  alt="tossPay"
                  onClick={() => props.onClickPaymentOption(order, PaymentService.TOSS)}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PaymentOptionModal;
