import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../actions/app.actions';

import { userActions } from '../../actions/user.actions';

import { Grid, Card, ButtonGroup, Button } from '@mui/material';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';

import HtmlTooltip from '../HtmlTooltip';
import PaymentModal from '../layout/PaymentModal';
import ConfirmationModal from '../layout/ConfirmationModal';

import tooltipImg from '../../img/tooltip.png';
import popularPurchaseIcon from '../../img/crown_3.png';
import { useStyles } from './SubscriptionPage.styles';
import getSubscriptionItems from './subscriptionItems.js';
import getOnetimeItems from './ontimeItems';

import * as pageService from '../../services/page.services';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { getCurrency, getSubscriptionAction, showMessage, showMessageV2 } from '../../utils/page.utils';

import {
  PaymentService,
  portOnePurchaseChannelKey,
  portOneSubscriptionChannelKey,
  SubscriptionAction,
  SubscriptionType,
} from '../../constants/app.constants';

import * as PortOne from '@portone/browser-sdk/v2';

const portOneStoreId = 'store-94887967-6f19-478d-b8dd-5320faafae96';

const SubscriptionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const user = useSelector(state => state.user);
  const userId = user.id;
  const [currency, setCurrency] = useState(null);
  const [paymentModalProps, setPaymentModalProps] = useState({});
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  const subscriptionItems = currency ? getSubscriptionItems(t, currency) : [];
  const onetimeItems = currency ? getOnetimeItems(t, currency) : [];
  const subscriptionType = user?.subscription?.type;
  const isCustom = subscriptionType === 'CUSTOM';
  const subscriptionStatus = user?.subscription?.status;

  const [purchaseType, setPurchasetype] = useState('subscription');

  useEffect(() => {
    (async () => {
      const currencyToSet = await getCurrency();
      setCurrency(value => {
        if (!value) return currencyToSet;
        return value;
      });
    })();
  }, [currency]);

  useEffect(() => {
    if (userId) {
      setCurrency(user.currency);
      initData();
    }
  }, [userId]);

  const initData = () => setTimeout(() => userActions.getUser(dispatch), 2000);

  const cardStyle = {
    padding: '1.5rem 1rem',
    backgroundColor: '#18181b',
    color: '#fff',
    fontWeight: '400',
    borderRadius: '0.5rem',
    height: '30.5rem',
    minWidth: '19rem',
    margin: '0.01rem',
  };

  const oneTimeCardStyle = {
    padding: '1.5rem 1rem',
    backgroundColor: '#18181b',
    color: '#fff',
    fontWeight: '400',
    borderRadius: '0.5rem',
    minWidth: '19rem',
  };

  const selectedStyle = { border: '0.1rem solid #D1FE75' };
  const pastDueStyle = { border: '0.1rem solid #e34c4c' };
  const featureIcon = { fontSize: 'medium', color: '#71717A' };
  const infoIcon = { fontSize: '1.5rem', marginLeft: '0.5rem', color: '#71717A' };

  const initiateSubscription = async newPlan => {
    setPaymentModalProps({
      open: true,
      subscriptionType: newPlan,
      onClickPaymentOption: async (order, paymentOption) => {
        setPaymentModalProps({ open: true, submitting: true });

        if (paymentOption === PaymentService.STRIPE) {
          try {
            await pageService.triggerStripeSubscriptionSession(order.id);
          } catch (e) {
            showMessage(dispatch, t('requestFailed'));
          }
        } else if (paymentOption === PaymentService.TOSS) {
          const tempCustomerId = uuidv4();
          const result = await PortOne.requestIssueBillingKey({
            storeId: portOneStoreId,
            channelKey: portOneSubscriptionChannelKey,
            billingKeyMethod: 'EASY_PAY',
            issueId: order.id,
            issueName: order.name,
            customer: { customerId: tempCustomerId, email: user.email, fullName: user.name, firstName: user.id },
            customData: { userId: user.id, tempId: tempCustomerId },
          });

          if (!result.code && result.billingKey) {
            try {
              await pageService.payWithBillingKey(order.id, result.billingKey);
              userActions.getUser(dispatch);
              await setTimeout(() => {
                userActions.getUser(dispatch);
              }, 4000);
            } catch {
              showMessage(dispatch, t('requestFailed'));
            } finally {
              setPaymentModalProps({ open: false });
            }
          }
        }
        setPaymentModalProps({ open: false });
      },
    });
  };

  const downgradeSubscription = async newPlan => {
    setConfirmationModalProps({
      open: true,
      message: t('subscriptionTab.downgrade.message'),
      onConfirmation: async response => {
        if (response === true) {
          setConfirmationModalProps({ open: true, loading: true });
          try {
            await pageService.downgradeSubscription(newPlan);
            showMessage(dispatch, t('subscriptionTab.downgrade.success'));
            navigate('/profile');
          } catch (e) {
            showMessage(dispatch, t('subscriptionTab.downgrade.fail'));
          }
        }
        setConfirmationModalProps({ open: false });
      },
    });
  };

  const upgradeSubscription = async newPlan => {
    setConfirmationModalProps({
      open: true,
      message: t('subscriptionTab.upgrade.message'),
      onConfirmation: async response => {
        if (response === true) {
          setConfirmationModalProps({ open: true, loading: true });
          try {
            const success = await pageService.upgradeSubscription(newPlan);
            if (success) {
              showMessage(dispatch, t('subscriptionTab.upgrade.success'));
              initData();
            } else {
              showMessageV2(dispatch, t('subscriptionTab.upgrade.paymentFailed'), {
                showProfileButton: true,
              });
            }
          } catch (e) {
            showMessage(dispatch, t('subscriptionTab.upgrade.fail'));
          }
        }
        setConfirmationModalProps({ open: false });
      },
    });
  };

  const onClick = async model => {
    if (checkValidLoginStatus(user.id, dispatch)) {
      if (user.subscription?.paymentService === 'iap_ios') {
        dispatch(appActions.openMessageModal(t('subscriptionTab.mobilePaymentRequired')));
        return;
      }
      const actionType = getSubscriptionAction(subscriptionType, model);

      if (actionType === SubscriptionAction.CANCEL) {
        navigate('/profile');
      } else if (actionType === SubscriptionAction.INITIATE) {
        await initiateSubscription(model);
      } else if (actionType === SubscriptionAction.DOWNGRADE) {
        await downgradeSubscription(model);
      } else if (actionType === SubscriptionAction.UPGRADE) {
        // await pageService.triggerStripeCustomerPortalSession();
        await upgradeSubscription(model);
      }
    }
  };

  const initiatePurchase = async purchaseType => {
    setPaymentModalProps({
      open: true,
      purchaseType,
      onClickPaymentOption: async (order, paymentOption) => {
        setPaymentModalProps({ open: true, submitting: true });
        await pageService.updateOrder(order.id, { paymentService: paymentOption });

        if (paymentOption === PaymentService.STRIPE) {
          try {
            await pageService.triggerStripeOneTimeSession(order.id);
          } catch (e) {
            showMessage(dispatch, t('requestFailed'));
          }
        } else if (paymentOption === PaymentService.TOSS) {
          try {
            const result = await PortOne.requestPayment({
              storeId: portOneStoreId,
              channelKey: portOnePurchaseChannelKey,
              paymentId: `${order.id}`,
              orderName: `${order.name}`,
              totalAmount: order.price,
              currency: 'KRW',
              payMethod: 'EASY_PAY',
              customer: { customerId: user.id },
              customData: { userId: user.id },
            });
            if (result.code === 'FAILURE_TYPE_PG') {
              await pageService.updateOrder(order.id, { status: 'CANCELED' });
            }
          } catch (e) {
            showMessage(dispatch, t('requestFailed'));
          }
        }
        setPaymentModalProps({ open: false });
      },
    });
  };

  const onClickOnetimePurchase = async type => {
    if (checkValidLoginStatus(user.id, dispatch)) {
      await initiatePurchase(type);
    }
  };

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const useThaiStyle = selectedLanguage === 'th';
  return (
    <>
      <PaymentModal onClose={() => setPaymentModalProps({ open: false })} {...paymentModalProps} />
      <ConfirmationModal
        onConfirmationModalClose={() => setConfirmationModalProps({ open: false })}
        {...confirmationModalProps}
      />
      <Grid container className={classes.container} spacing={3}>
        <Grid style={{ padding: 0 }} item xs={12} sm={12} md={12}>
          <div className={classes.pageHeaderContainer}>
            <div className={classes.pageHeader}>
              {purchaseType === 'subscription' && t('subscriptionTab.tabs.subscription.pageTitleTwo')}
              {purchaseType === 'onetime' && t('subscriptionTab.tabs.onetime.pageTitleTwo')}
            </div>
          </div>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12} sm={12} md={12}>
          <ButtonGroup className={classes.purchaseTypeButtonContainer}>
            <Button
              className={`${classes.purchaseTypeButton} ${purchaseType == 'subscription' ? classes.selected : ''}`}
              onClick={() => setPurchasetype('subscription')}
            >
              {t('subscriptionTab.tabs.subscription.title')}
            </Button>
            <Button
              className={`${classes.purchaseTypeButton} ${purchaseType == 'onetime' ? classes.selected : ''}`}
              onClick={() => setPurchasetype('onetime')}
            >
              {t('subscriptionTab.tabs.onetime.title')}
            </Button>
          </ButtonGroup>
        </Grid>
        {purchaseType === 'onetime' && (
          <>
            {onetimeItems.map(({ name, price, type }) => (
              <Grid item className={classes.cardGrid} xs={12} sm={6} md={3}>
                <Card sx={oneTimeCardStyle}>
                  <div className={classes.cardHeaderContainer}>
                    <div className={classes.cardHeader}>{name}</div>
                  </div>
                  <div className={classes.onetimeFee}>{price}</div>
                  <div className={classes.onetimeCardButton} onClick={() => onClickOnetimePurchase(type)}>
                    {t('subscriptionTab.tabs.onetime.purchase')}
                  </div>
                </Card>
              </Grid>
            ))}
          </>
        )}
        {purchaseType === 'subscription' &&
          subscriptionItems.map(({ name, model, price, discountedPrice, discountRate, period, features }) => (
            <Grid item className={classes.cardGrid} xs={12} sm={6} md={3}>
              <Card
                className={
                  (!subscriptionType || subscriptionType === SubscriptionType.FREE) && model === SubscriptionType.PRO
                    ? classes.popularSubscription
                    : classes.normalSubscription
                }
              >
                {(!subscriptionType || subscriptionType === SubscriptionType.FREE) && model === SubscriptionType.PRO ? (
                  <div className={classes.cardSubscriptionHeader}>
                    <img className={classes.pupularPurchaseIcon} src={popularPurchaseIcon} alt="popular-icon" />
                    {t('subscriptionTab.popular')}
                  </div>
                ) : (
                  <div className={classes.cardSubscriptionHeader}></div>
                )}
                <div
                  style={{
                    ...cardStyle,
                    ...(model === subscriptionType && subscriptionStatus !== 'past_due' ? selectedStyle : {}),
                    ...(model === subscriptionType && subscriptionStatus === 'past_due' ? pastDueStyle : {}),
                  }}
                >
                  <div className={classes.cardHeaderContainer}>
                    <div className={classes.cardHeader}>{name}</div>
                    <div className={classes.cardHeaderBadgeContainer}>
                      {!!discountRate && <div className={classes.promoBadge}>{discountRate}</div>}
                      {model === subscriptionType && subscriptionStatus !== 'past_due' && (
                        <div className={classes.cardHeaderCurrent}>{t('subscriptionTab.tabs.subscription.status')}</div>
                      )}
                      {model === subscriptionType && subscriptionStatus === 'past_due' && (
                        <div className={classes.cardHeaderPastDue}>
                          {t('subscriptionTab.tabs.subscription.pastDue')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.cardFee}>
                    <span className={!!discountedPrice ? classes.strikethroughPrice : undefined}>{price}</span>
                    {!!discountedPrice && <span>{discountedPrice}</span>}
                    <span className={classes.cardFeeSubText}>{period}</span>
                  </div>
                  <div className={classes.divider} />

                  {(subscriptionType === model || (!subscriptionType && model === SubscriptionType.FREE)) && (
                    <>
                      <div className={classes.cardButton} onClick={() => onClickOnetimePurchase('INFERENCE')}>
                        {`${t('onetimeItems.inference.name')}: ${t(`onetimeItems.inference.price.${user.currency || currency}`)}`}

                        <HtmlTooltip
                          title={
                            <div className={classes.tooltipContainer}>
                              <div className={classes.tooltipText}>{t('onetimeItems.inference.tooltip')}</div>
                            </div>
                          }
                          onClick={e => e.stopPropagation()}
                        >
                          <InfoOutlined sx={infoIcon} />
                        </HtmlTooltip>
                      </div>
                      <div className={classes.cardButton} onClick={() => onClickOnetimePurchase('TRAINING')}>
                        {`${t('onetimeItems.training.name')}: ${t(`onetimeItems.training.price.${user.currency || currency}`)}`}
                      </div>
                    </>
                  )}
                  {((!!subscriptionType && subscriptionType !== model) ||
                    (!subscriptionType && model !== SubscriptionType.FREE)) && (
                    <div
                      className={`${classes.cardButton} ${isCustom && classes.cardButtonDisabled}`}
                      onClick={() => !isCustom && onClick(model)}
                    >
                      {!subscriptionType || subscriptionType === 'FREE'
                        ? t('subscriptionTab.tabs.subscription.subscribe')
                        : t('subscriptionTab.tabs.subscription.change')}
                    </div>
                  )}
                  {features.map(({ text, tooltips }) => (
                    <div className={classes.featureContainer}>
                      <CheckCircle sx={featureIcon} />
                      <div className={`${useThaiStyle && classes.featureTextThai} ${classes.featureText} `}>
                        {text}
                        {tooltips && (
                          <HtmlTooltip
                            title={
                              <div className={classes.tooltipContainer}>
                                {tooltips.map(tooltipText => (
                                  <div className={classes.tooltipTextContainer}>
                                    <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>
                                      &#x2022;
                                    </div>
                                    <div className={classes.tooltipText}>{tooltipText}</div>
                                  </div>
                                ))}
                              </div>
                            }
                          >
                            <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                          </HtmlTooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default SubscriptionPage;
