import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'ko',
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        languageDetector: 'en',
        requestFailed: 'Request Failed. Please try again',
        pageLoadFailed: 'Page failed to load. Please refresh the page',
        signIn: 'Sign in',
        defaultModels: ['Sample Voice A (Male)'],
        yes: 'Yes',
        no: 'No',
        sectionProduction: ['AI Cover'],
        trainingTab: {
          title: 'AI Training',
          tabs: ['train', 'external'],
          stepOne: {
            title: 'Step 1: Upload Audio Files',
            tooltip: {
              titleOne: 'Help',
              contentsOne: [
                'Quality over quantity. 15 minutes of high quality files is better than 30 minutes of low quality files',
                'Avoid soundtrack with excessive autotune',
                'Official soundtrack is recommended over live performance',
                'Avoid duet songs',
                'Use singing voice rather than conversational voice',
                'Training rap voice helps cover rap songs',
              ],
              titleTwo: 'When Recording Voice',
              contentsTwo: [
                'You may use any voice recording app on your mobile device',
                'You may upload multiple files. No need to record all at once',
                'Recording without background music recommended',
                'Songs recorded in karaoke are not recommended',
                'Please review your recorded voice before uploading',
              ],
            },
            trainingSubTitles: ['Please upload audio files for training'],
            externalSubTitles: ['Please upload external model files'],
            dragAndDropText: 'Drag and drop audio files',
            dragAndDropDuration: '({{minDuration}}-{{maxDuration}}min in total)',
            dragAndDropButton: 'Select Files',
            durationText: 'Total Duration',
            durationUnit: 'min',
            tabs: {
              fileUpload: {
                vocalExtractionText: 'Are these all vocal extracted files?',
                vocalExtractionYes: 'Yes',
                vocalExtractionNo: 'No',
                vocalExtractionTooltip: {
                  title: 'What does this mean?',
                  contents: [
                    "Click 'Yes' only if vocals have already been extracted from all provided soundtracks.",
                    'Not applicable in most cases',
                  ],
                },
              },
            },
          },
          stepTwo: {
            title: 'Step 2: Label Your Model',
            subTitles: ['Please label your AI voice model and start training'],
            placeholder: 'Give a label to your model',
          },
          submit: {
            upload: 'Upload',
            free: 'Please subscribe to train a voice model',
            inProgress: 'Training in progress',
            noRemaining: 'No remaining credit left',
            start: 'Start Training',
            uploading: 'Uploading. Please Wait',
            remainingCountText: '# of credits left',
            tooltip: {
              contents: [
                'Voice training takes about 0.5-1 hours.',
                'Training duration may differ by plans',
                "You can check the status in the 'History' tab",
              ],
            },
          },
          modal: {
            exceedModelCount:
              'The number of AI voices that can be retained has reached its limit. To proceed, please upgrade to more advanced plans or go to the history tab and remove your existing voice models',
            exceedMaxDuration: 'The total duration of audio files cannot exceed {{maxDuration}} minutes',
            minDuration: 'The total duration of audio files must be at least {{minDuration}} minutes',
            notSupportedFileType: 'This file format is not supported',
            imgModalConfirm: 'Start',
            imgModalCancel: 'Go Back',
            noLabel: 'Please add a label',
            missingFile: 'Please submit all files',
            noFile: 'Please upload files',
            longLabel: 'Label cannot exceed 25 characters',
          },
        },
        communityUploadTab: {
          title: 'Community Upload',
          tabs: [' share ', 'external'],
          stepOne: {
            tabs: {
              fileUpload: {
                dragAndDropText: 'Drag and drop file',
              },
            },
            title: 'Step 1: Upload Image',
            subTitles: ['Please upload an Image for your profile picture'],
          },
          stepTwo: {
            title: 'Step 2: Choose a Voice Model',
            trainingSubTitles: ['Please select a model you want to share'],
            externalSubTitles: ['Please upload a model you want to share'],
            dragAndDropText: ['Upload a Path file', 'Upload a Index file'],
            dragAndDropButton: 'Select File',
            voice: 'voice',
          },
          stepThree: {
            title: 'Step 3: Label the model',
            subTitle: 'Give a label to the model',
            genderLabel: 'Please choose a gender for the voice you want to share',
            gender: ['Male', 'Female'],
            anonymousText: 'Hide my username',
          },
          stepFour: {
            title: 'Step 4: Tag your model',
            subTitle: 'Please select appropriate tags for you model',
            tags: ['Genre', 'Country'],
          },
          submit: {
            upload: 'Share',
            start: 'Share',
            uploading: 'Uploading. Please Wait',
          },
          modal: {
            noImage: 'Please upload an image',
            noModel: 'Please select a voice model to share',
            noTag: 'Please select at least one tag for each field',
            noLabel: 'Please give a label',
            longLabel: 'Label cannot exceed 25 characters',
          },
        },
        inferenceTab: {
          title: 'AI Cover',
          stepOne: {
            tooltip: {
              title: 'Help',
              contents: [
                'The sound quality of the audio file is VERY important',
                'Recommend using offical soundtrack',
                'Do NOT recommend soundtracks with exccessive autotune',
                'Do NOT recommend duet songs',
              ],
            },
            title: 'Step 1: Upload Your Target Music',
            subTitles: ['Please upload the song you want to cover'],
            tabs: {
              fileUpload: {
                title: 'File Upload',
                dragAndDropText: 'Drag and drop file',
                dragAndDropButton: 'Select File',
                dragAndDropDuration: '(Max 75 MB)',
                vocalExtractionText: 'Is this vocal extracted file?',
                vocalExtractionYes: 'Yes',
                vocalExtractionNo: 'No',
                vocalExtractionTooltip: {
                  title: 'What does this mean?',
                  contents: [
                    'Click “Yes” only if vocal has already been extracted from the soundtrack.',
                    'Not applicable in most cases',
                  ],
                },
              },
              youtube: {
                title: 'Youtube',
                linkPlaceholder: 'Max 10 minutes',
                linkbutton: 'Search',
                durationUnit: ['min', 'sec'],
              },
              recording: {
                title: 'Record',
                startButton: 'Start',
                saveButton: 'Save',
                recordingButton: 'Recording',
                userRecording: 'User Recording',
              },
            },
          },
          stepTwo: {
            title: 'Step 2: Select AI Voice Model',
            modelInput: {
              placeHolder: 'Select AI voice model',
              myModel: 'My Voices',
              defaultModel: 'Default Voices',
            },
            dragAndDropText: 'Click to find and select a model',
          },
          stepThree: {
            title: 'Step 3: Configuration',
            subTitles: 'Choose mixer settings, output format, and label your cover.',
            labelInput: {
              placeHolder: 'Give a label to your cover',
            },
            ouputFormatLabel: 'Please select the output file format',
            tooltip: {
              outputFormatMessage: 'WAV format is for PRO/PREMIUM users only',
              upgradePrompt: 'Click here to upgrade',
              subscribePrompt: 'Click here to subscribe',
              title: 'What does this mean?',
              contents: ["If not set, it will be 'Voice - Source'", 'Can be changed later in History'],
            },
          },
          submit: {
            completedAndLoadingPlayer:
              "Inference completed. Loading audio player, but you may also download the song in the 'History' tab",
            alreadyInProgress: 'In Progress',
            noRemaining: 'No remaining credit for covers',
            start: 'Start',
            uploading: 'Uploading - Please wait a moment',
            tryAgain: 'Please Try Again',
            inProgress: 'Converting - You may wait or leave the page and download the song in the "History" tab',
            remainingCountText: '# of remaining credits for cover',
            remainingCountUnit: '',
            tooltip: {
              contents: [
                'Generating an AI cover takes approximately 1-4 minutes',
                'You may wait to play the music',
                "You can check the status in the 'History' tab",
              ],
            },
          },
          modal: {
            watermark:
              'Free version includes audio watermark. Watermark will disappear once you become paid subscriber',
            exceedModelCount:
              'The basic plan only allows you to have up to total 3 voice models. To proceed, please upgrade your plan or delete the existing model.',
            exceedMaxSize: 'You can only upload files up to 75MB',
            tooManyFiles: 'Only 1 file can be uploaded',
            notSupportedFileType: 'This file format is not supported',
            inProgress: 'Conversion in progress',
            noRemaining: 'No remaining cover credits left for this month',
            emptyYoutubeLink: 'Please upload the song you want to cover',
            noModalSelected: 'Please select a voice model',
            noLabel: 'Please add a label',
            invalidYoutubeLink: 'This is an incorrect YouTube link',
            youtubeLinkTooLong: 'The YouTube video may not exceed 10 minutes',
            longLabel: 'Label cannot exceed 100 characters',
          },
        },
        ttsInferenceTab: {
          title: 'AI Text To Speech',
          stepOne: {
            modelInput: {
              placeHolder: 'Select AI voice model',
              myModel: 'My Voices',
            },
            ttsTextAreaPlaceholder: "Type any text you'd like to convert into speech",

            dragAndDropText: 'Click to find and select a model',
          },
          submit: {
            completedAndLoadingPlayer:
              "TTS Inference completed. Loading audio player, but you may also download the speech in the 'AI Speech'-'History' tab",
            alreadyInProgress: 'In Progress',
            noRemaining: 'No remaining quota left for this month',
            start: 'Start',
            uploading: 'Uploading - Please wait a moment',
            tryAgain: 'Please Try Again',
            inProgress:
              'Converting - You may wait or leave the page and download the speech in the "AI Speech"-"History" tab',
            remainingCountText: '# of remaining quota for AI speech',
            remainingCountUnit: 'character',
            remainingMonthly: 'Quota remaining',
            setting: '  Setting',
          },
          modal: {
            selectModalTitle: 'AI Voices',
            addNewModelText: 'Click to make a new model',
            exceedTextSize: 'Can not convert more than {{characterLimitPerInference}} characters at a time',
            inProgress: 'Conversion in progress',
            noRemaining: 'No remaining quota left for this month',
            noModalSelected: 'Please select a voice model',
            noText: 'Please add text',
            variability: 'variability',
            stable: 'stable',
            variable: 'variable',
            similarity: 'similiarity',
            low: 'low',
            high: 'high',
            longLabel: 'Label cannot exceed 50 characters',
          },
        },
        ttsTrainingTab: {
          title: 'AI TTS Training',
          stepOne: {
            tooltip: {
              contentsOne: [
                'Quality over quantity. 5 minutes of high quality files is better than 10 minutes of low quality files',
                'Keep your training data clean and free from background noise.',
                'Use conversational voice rather than singing voice',
                'Each file should be smaller than 10mb',
                'A maximum of 10 files can be uploaded',
              ],
            },
            sizeLimitText: '(Each file should be smaller than 10mb)',
            numberLimitText: '(Up to 10 Files)',
            dragAndDropDuration: '({{minDuration}}sec-{{maxDuration}}min in total)',
            second: 'sec',
          },
          modal: {
            sizeTooLarge: 'Each file should be smaller than 10mb',
            tooManyFiles: 'A maximum of 10 files can be uploaded',
            duplicateFiles: "duplicate files can't be uploaded",
            exceedMaxDuration: 'The total duration of audio files cannot exceed {{maxDuration}} minutes',
            minDuration: 'The total duration of audio files must be at least {{minDuration}} seconds',
          },
          submit: {
            tooltip: {
              contents: [
                'Voice training takes about 0.5-10 minutes.',
                'Training duration may differ by plans',
                "You can check the status in the 'AI Speech'-'History' tab",
              ],
            },
          },
        },
        ttsAppBar: {
          sectionTTS: 'AI Speech',
          subSectionTTS: ['Text to Speech', 'TTS Training', 'TTS History'],
        },
        ttsHistoryTab: {
          title: 'TTS History',
          tabs: {
            inference: {
              title: 'AI Speech',
            },
            training: {
              title: 'AI Voice',
            },
          },
          infoIcon: {
            voice: 'Voice',
            variability: 'variability',
            similarity: 'similarity',
          },
        },
        videoEditorTab: {
          title: 'Video Editor',
          addSource: 'Source',
        },
        addSourceModal: {
          title: 'Add a source',
          sourceOptions: [
            'AI Cover',
            'TTS',
            'Youtube',
            'Upload',
          ],
          searchbar: 'Search',
          pagination: {
            loader: 'Loading...',
          },
          voice: 'AI Voice',
          youtube: {
            placeholder: 'Max 10 minutes',
            button: 'Search',
            invalidYoutubeLink: 'This is an incorrect YouTube link',
            youtubeLinkTooLong: 'The YouTube video may not exceed 10 minutes',
          },
          upload: {
            dragAndDropText: 'Drag and drop a video/audio/image file',
            sizeLimitText: '(Max 75 MB)',
            acceptedAudio: '.mp3, .wav, .flac, and .ogg',
            acceptedVideo: '.mp4 and .mkv',
            acceptedImage: '.jpeg, .png, and .webp',
            button: 'Select file',
            notSupportedFileType: 'This file format is not supported',
            tooManyFiles: 'Please upload one file at a time',
            exceedMaxSize: 'You can only upload files up to 75MB',
          },
          loading: [
            'Uploading file',
            '(may take up to a minute for larger files)',
          ],
        },
        textEditorModal: {
          title: [
            'Add text',
            'Edit text',
          ],
          placeholder: 'Type your text here...',
          add: 'Add',
          save: 'Save',
        },
        videoExportModal: {
          title: 'Export video',
          placeholder: 'Give your exported video a label',
          watermark: 'Show watermark',
          start: 'Start',
          rendering: [
            'Please stay on the page',
            'Exporting your video (may take up to a minute)',
          ],
          success: [
            'Video is ready',
            'Your download will start automatically. If not, click the button below.',
          ],
          tooltip: {
            contents: [
              'Export time will depend on the length of your video',
              'You will be taken to the History tab where you can check the progress',
            ]
          },
          videoRenderError: 'Could not render the video. Please try again',
        },
        // vocalExtractorTab
        vocalExtractorTab: {
          title: 'Vocal Extractor',
          stepOne: {
            title: 'Step 1: Upload Audio Files',
            tabs: {
              fileUpload: {
                title: 'File Upload',
                dragAndDropText: 'Drag and drop file',
                dragAndDropButton: 'Select File',
                dragAndDropDuration: '(Max 75 MB each)',
              },
              youtube: {
                title: 'Youtube',
                linkPlaceholder: 'Max 10 minutes',
                linkbutton: 'Search',
                durationUnit: ['min', 'sec'],
              },
            },
            vocalExtractorSubTitles: ['Please upload audio file for the vocal extraction'],
            tooltip: {
              contents: [
                'Premium users can upload up to 10 files at a time',
                'Pro users can upload up to 5 files at a time',
                'Basic/Free users can upload 1 file at a time',
                'The total duration of audio files must not exceed 60 minutes',
              ],
            },
            maxUploadNumText: {
              premiumCustomAdmin: 'Upload up to 10 files',
              pro: 'Upload up to 5 files',
              basicFree: 'Upload up to 1 file',
            },
          },
          stepTwo: {
            title: 'Step 2: Configuration',
            subTitles: 'Choose output format and label your vocal extract',
            labelInput: {
              placeHolder: 'Give a label to your vocal extract',
            },
            tooltip: {
              outputFormatMessage: 'WAV format is for PRO/PREMIUM users only',
              upgradePrompt: 'Click here to upgrade',
              subscribePrompt: 'Click here to subscribe',
            },
          },
          submit: {
            alreadyInProgress: 'In Progress',
            upload: 'Upload',
            inProgress: 'Converting - You may wait or leave the page and download the song in the "History" tab',
            noRemaining: 'Please subscribe for additional vocal extraction',
            start: 'Start',
            uploading: 'Uploading. Please Wait',
            tryAgain: 'Please Try Again',
            remainingCountText: '# of remaining credits for vocal extraction',
            remainingCountUnit: '',
          },
          modal: {
            notSupportedFileType: 'This file format is not supported',
            invalidYoutubeLink: 'This is an incorrect YouTube link',
            youtubeLinkTooLong: 'The YouTube video may not exceed 10 minutes',
            noRemaining: 'Please subscribe to train additional voice model',
            emptyYoutubeLink: 'Please upload the song you want to remove the VocalExtract',
            inProgress: 'Removing in progress',
            exceedMaxSize: 'You can only upload files up to 75MB each',
            exceedMaxDuration: 'The total duration of audio files cannot exceed 60 minutes',
            premiumCustomAdmin: 'Premium/Custom',
            pro: 'Pro',
            filePlural: ' files',
            tooManyFiles: ['users can upload', 'at a time'],
            noFiles: 'Please add a file for vocal extraction',
            lackRemaining: 'Please subscribe for additional vocal extraction',
            uploadStatusNotValid: 'Remaining credits lack or pending request',
            tryAgain: 'Please Try Again:',
            noLabel: 'Please give a label to your vocal extract',
            longLabel: 'Label cannot exceed 50 characters',
          },
        },
        historyTab: {
          title: 'History',
          inProgress: 'In Progress',
          failed: 'Failed',
          freeVersionTag: 'Free Trial',
          freePrefix: 'Free version includes SorisoriAI ads',
          tooltip: {
            contents: [
              'Generating an AI cover takes on average 1-4 minutes',
              'Voice training takes about 0.5-1 hours on average',
              'When failed, please reach out to support',
            ],
          },
          tabs: {
            inference: {
              title: 'AI Cover',
            },
            training: {
              title: 'AI Voice',
            },
            vocalExtract: {
              title: 'Vocal Extract',
            },
            community: {
              title: 'Community',
            },
          },
          changeButton: 'Edit',
          cancelButton: 'Cancel',
          confirmModelDelete:
            'Are you sure you want to delete this model? Deleting a model will not restore available monthly training credit',
          confirmInferenceDelete: 'Are you sure you want to delete?',
          confirmVocalExtractDelete: 'Are you sure you want to delete?',
          searchbar: 'Search',
          infoIcon: {
            source: 'Source',
            voice: 'Voice',
            pitch: 'Pitch',
            mixing: {
              title: 'Mixer settings',
              vocalLevel: 'Vocal Level',
              reverbLevel: 'Reverb Level',
              reverbLength: 'Reverb Length',
            },
            errorMessage: 'Error',
          },
          pagination: {
            loader: 'Loading...',
          },
          modal: {
            error: 'Please refresh the webpage',
            label: 'Label',
            username: 'Username',
            image: 'Image',
            filterError: 'Voice filter was not found',
          },
          trainingInfo: {
            files: 'Files used',
            extracted: 'Vocal extracted',
            shared: 'Shared to Community',
            yes: 'Yes',
            no: 'No',
            status: 'Status',
            errorFile: 'File with error',
          },
          voice: 'AI Voice',
        },
        subscriptionTab: {
          title: 'Subscription',
          pageTitleOne: 'Sorisori AI',
          popular: 'Most popular',
          cancel: {
            message: 'Are you sure you want to cancel subscription?',
            success: 'Subscription canceled successfully!',
            fail: 'Subscription cancel failed. Please try again.',
          },
          cancelChange: {
            message: 'Are you sure you want to undo subscription change?',
            success: 'Subscription change reverted successfully!',
            fail: 'Subscription change undo failed. Please try again.',
          },
          onetime: {
            message: 'Are you sure you want to purchase?',
            success: 'Purchased successfully!',
            fail: 'Purchase failed. Please try again.',
          },
          downgrade: {
            message: 'Are you sure you want to downgrade?',
            success: 'Subscription downgraded successfully!',
            fail: 'Subscription downgrade failed. Please try again.',
          },
          upgrade: {
            message:
              "Are you sure you want to upgrade? Once you click 'Yes', payment will be made with the existing payment method.",
            success: 'Subscription upgraded successfully!',
            fail: 'Subscription upgrade failed. Please try again.',
            paymentFailed: 'Payment failed. Please update the payment method from My Profile or try again.',
          },
          mobilePaymentRequired:
            'Since your subscription was set up in a mobile app, please manage subscription from a mobile app.',
          tabs: {
            subscription: {
              title: 'Subscription',
              pageTitleTwo: 'Subscription',
              status: 'Current',
              pastDue: 'Payment Failed',
              month: 'Month',
              subscribe: 'Subscribe',
              change: 'Change',
            },
            onetime: {
              title: 'Credits',
              pageTitleTwo: 'Individual Purchase',
              purchase: 'Purchase',
              history: 'Purchase History',
              invalidSubscriptionType: 'Please subscribe to a plan first',
            },
          },
          paymentModal: {
            usePromoCode: 'Use promo code',
            promoCodePlaceholder: 'promo code',
            applyButton: 'apply',
            promoCodeApplied: 'Promo code applied',
            invalidPromoCode: 'Invalid promo code',
            genericError: 'Could not complete the request. Please try again.',
            promoCodeError: {
              doesNotExist: 'Invalid promo code. Please check the code',
              invalidSubscriptionType: 'This promo code is only valid for PRO and PREMIUM plans',
              onlyFirstTime: 'This promo code is valid for first-time users only'
            }
          },
        },
        communityTab: {
          title: 'Community',
          subTitle: 'Select a voice to cover a song!',
          inProgress: 'In Progress',
          tags: 'tags',
          failed: 'Failed',
          sortOptions: ['recent', 'likes', 'creations', 'label'],
          freeVersionTag: 'Free Trial',
          freePrefix: 'Free version includes SorisoriAI ads',
          voiceOptions: ['Community', 'My Voices', 'Favorites'],
          tooltip: {
            contents: [
              'Generating an AI cover takes on average 1-4 minutes',
              'Voice training takes about 0.5-1 hours on average',
              'When failed, please reach out to support',
            ],
          },
          tabs: {
            inference: {
              title: 'AI Cover',
            },
            training: {
              title: 'AI Voice',
            },
            vocalExtract: {
              title: 'Vocal Extract',
            },
          },
          changeButton: 'Edit',
          cancelButton: 'Cancel',
          confirmModelDelete:
            'Are you sure you want to delete this model? Deleting a model will not restore available monthly training credit',
          confirmInferenceDelete: 'Are you sure you want to delete?',
          confirmVocalExtractDelete: 'Are you sure you want to delete?',
          searchbar: 'Search',
          infoIcon: {
            source: 'Source',
            voice: 'Voice',
            pitch: 'Pitch',
          },
          pagination: {
            loader: 'Loading...',
          },
          modal: {
            error: 'Please refresh the webpage.',
          },
          button: {
            share: 'share',
          },
        },
        supportTab: {
          title: 'Discord',
          link: 'https://discord.gg/AVREYrRZv4',
        },
        pipeline: {
          inferenceStatusMessage: {
            formatError: "The file format and extension don't match, or the file could be corrupted or unsafe",
            volumeError:
              "The vocal volume in the file is too low, or there's no voice in the file. Please check the recording settings or use audio editing software to increase the volume.",
            invalidFileError: "The file can't be opened. Please check the file.",
            progress10: 'In progress 10%',
            progress20: 'In progress 20%',
            progress40: 'In progress 40%',
            progress50: 'In progress 50%',
            progress60: 'In progress 60%',
            progress80: 'In progress 80%',
            progress100: 'In progress 100%',
            complete: 'Complete',
            generalMessage:
              "AI Cover has failed. Please try again. If the issue persists, please contact SORISORI's support team",
            starting: 'Starting',
          },
          trainingStatusMessage: {
            formatError: "The file format and extension don't match, or the file could be corrupted or unsafe",
            volumeError:
              "The vocal volume in the file is too low, or there's no voice in the file. Please check the recording settings or use audio editing software to increase the volume.",
            invalidFileError: "The file can't be opened. Please check the file.",
            progress10: 'In progress 10%',
            progress20: 'In progress 20%',
            progress40: 'In progress 40%',
            progress50: 'In progress 50%',
            progress60: 'In progress 60%',
            progress80: 'In progress 80%',
            progress100: 'In progress 100%',
            complete: 'Complete',
            generalMessage:
              "Training has failed. Please try again. If the issue persists, please contact SORISORI's support team",
            starting: 'Starting',
          },
        },
        modal: {
          confirmationYes: 'Yes',
          confirmationNo: 'No',
          combined: 'Combined',
          backVocal: 'Back Vocal',
          vocal: 'Vocal',
          instrument: 'Instrumental',
          fileUploadFail: 'Could not upload the files. Please check the files and try again',
          trainingInProgressError: 'Training already in progress',
          noRemainingError: 'No remaining credit left',
          genericError: 'Could not complete the request. Please try again',
          pageLoadFail: 'Could not load the page, plase refresh the page',
          pageLoadFailOnSubmit:
            "Successfully uploaded files, but could not reload the page. Please refresh the page. You can still check the status in the 'History' tab",
          gotoProfile: 'Go to My Profile',
        },
        mixingModal: {
          label: 'Mix',
          title: 'Mixing',
          sliders: {
            contents: ['Vocal Pitch', 'Vocal Level', 'Reverb Level', 'Reverb Length'],
          },
          tooltip: {
            contents: [
              '-4 to -6 recommended when covering a female song with a male voice',
              '+4 to +6 recommended when covering a male song with a female voice',
              'Excessive pitch adjustment may lower the sound quality',
              'Loudness of the vocals',
              '0db is the loudness of the original vocals',
              'Strength of the reverb effect',
              '0 removes reverb effect, 6 creates strong reverb effect',
              'Length of the reverb effect in seconds',
              '2s recommended for ballads, shorter length recommended for faster songs',
            ],
          },
          demo: {
            toggle: 'Mix',
            status: ['Unmixed', 'Mixed'],
            description: 'Hear the difference between unmixed and mixed vocals',
          },
          dialog: {
            title: "Sorry, this feature isn't available for you",
            text: 'Upgrade to PRO or PREMIUM tier to get access to the mixing feature!',
          },
          upgradeButton: ['Upgrade to ', 'PRO'],
          alert: 'You must set a value for each mixer',
        },
        freeInferenceMessageModal: {
          messeageOne: ['Free version contains ads', 'and is limited to 45 seconds'],
          messeageTwo: ['Use paid products to generate', 'a full length ad free song'],
          button: 'Subscribe',
        },
        freeTrainingMessageModal: {
          messages: ['AI training will be available', 'once you subscribe or buy', 'onetime product'],
          button: 'Subscribe',
        },
        freeVocalExtractorMessageModal: {
          free: {
            message: ['Free users', 'can upload 1 file at a time', 'Upgrade to upload more'],
          },
          basic: {
            message: ['Basic users', 'can upload 1 file at a time', 'Upgrade to upload more'],
          },
          button: 'Subscribe',
        },
        onetimeItems: {
          training: {
            name: '1 Training Credit',
            price: {
              usd: '$7',
              thb: '฿250',
              krw: '₩8,500',
              jpy: '¥990',
            },
          },
          vocalExtract: {
            name: '100 Vocal Extract Credits',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
          inference: {
            tooltip: 'Generate full song without ads',
            name: '5 Cover Credits',
            price: {
              usd: '$4.99',
              thb: '฿75',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
          tts: {
            name: '3000 TTS Credits',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
        },
        subscriptionItems: [
          {
            name: 'FREE',
            model: 'FREE',
            price: {
              usd: '$0',
              thb: '฿0',
              krw: '₩0',
              jpy: '¥0',
            },
            period: '',
            features: [
              {
                text: 'Speed - Slow',
              },
              {
                text: 'AI Cover Generation: 3 songs',
                tooltips: ['Generate up to 3 AI Covers'],
              },
              {
                text: 'Vocal Extraction and AI Mixing',
                tooltips: ['Sorisori applies AI mixing technology to enhance song rendition'],
              },
              {
                text: 'Train Quality - Normal',
                tooltips: [
                  'Upload up to 30 minutes of audio that train for ~2 hours',
                  'Uses a normal speed GPU. You can expect higher training quality once you subscribe',
                ],
              },
              {
                text: 'Sound Quality: Normal (MP3)',
                tooltips: ['Provide MP3 file', 'Includes watermark'],
              },
              {
                text: 'Vocal Extractor Feature: 3 songs',
                tooltips: ['You may extract vocals, instrument and back vocals for up to 3 songs'],
              },
              {
                text: 'Beta - AI Speech: 20 characters',
                tooltips: ['You can change text to speech up to 20 characters a month'],
              },
            ],
          },
          {
            name: 'BASIC',
            model: 'BASIC',
            price: {
              usd: '$9.99',
              thb: '฿350',
              krw: '₩14,800',
              jpy: '¥1,699',
            },
            period: '/Month',
            features: [
              {
                text: '1 Custom Voice Model to Train',
                tooltips: [
                  'Train one custom voice model every month. Unused credit does not carry over to the following month',
                ],
              },
              {
                text: 'Speed - Normal',
              },
              {
                text: 'AI Cover Generation: 200 songs',
                tooltips: [
                  'Generate up to 200 AI Covers every month.  Unused credit does not carry over to the following month',
                ],
              },
              {
                text: 'Vocal Extraction and AI Mixing',
                tooltips: ['Sorisori applies AI mixing technology to enhance song rendition'],
              },
              {
                text: 'Train Quality - High',
                tooltips: [
                  'Upload up to 30 minutes of audio that train for ~2 hours',
                  'Uses a normal speed GPU. You may expect higher training quality than that of FREE plan',
                ],
              },
              {
                text: 'Sound Quality: Normal (MP3)',
                tooltips: ['Provide MP3 file'],
              },
              {
                text: 'Vocal Extractor Feature: 10 songs',
                tooltips: ['Extract vocals, instrument and back vocals for up to 10 songs'],
              },
              {
                text: 'Beta - AI Speech: 200 characters',
                tooltips: [
                  'You can change text to speech up to 200 characters(Up to 50 characters per generation) a month.',
                ],
              },
              {
                text: 'Beta - AI Speech Training: 1 model',
                tooltips: [
                  'Train one custom voice model every month. Unused credit does not carry over to the following month',
                ],
              },
            ],
          },
          {
            name: 'PRO',
            model: 'PRO',
            price: {
              usd: '$24.80',
              thb: '฿880',
              krw: '₩28,800',
              jpy: '¥3,490',
            },
            period: '/Month',
            features: [
              {
                text: '3 Custom Voice Model to Train',
                tooltips: [
                  'Train 3 custom voice models every month. Unused credit does not carry over to the following month',
                ],
              },
              {
                text: 'Speed - Fast',
              },
              {
                text: 'AI Cover Generation: 1000 songs',
                tooltips: [
                  'Generate up to 1000 AI Covers every month.  Unused credit does not carry over to the following month',
                ],
              },
              {
                text: 'Vocal Extraction and AI Mixing',
                tooltips: ['Sorisori applies AI mixing technology to enhance song rendition'],
              },
              {
                text: 'Train Quality - Very High',
                tooltips: [
                  'Upload up to 45 minutes of audio that train for ~2 hours',
                  'Uses a high speed GPU. You may expect higher training quality than that of BASIC plan',
                ],
              },
              {
                text: 'Sound Quality: High (WAV)',
                tooltips: ['Provide WAV file'],
              },
              {
                text: 'Vocal Extractor Feature: 50 songs',
                tooltips: ['Extract vocals, instrument and back vocals for up to 50 songs'],
              },
              {
                text: 'Beta - AI Speech: 4000 characters',
                tooltips: [
                  'You can change text to speech up to 4000 characters(Up to 200 characters per generation) a month.',
                ],
              },
              {
                text: 'Beta - AI Speech Training: 2 model',
                tooltips: [
                  'Train two custom voice model every month. Unused credit does not carry over to the following month',
                ],
              },
            ],
          },
          {
            name: 'PREMIUM',
            model: 'PREMIUM',
            price: {
              usd: '$68.80',
              thb: '฿2400',
              krw: '₩88,800',
              jpy: '¥8,990',
            },
            period: '/Month',
            features: [
              {
                text: '13 Custom Voice Model to Train',
                tooltips: [
                  'Train 13 custom voice models every month. Unused credit does not carry over to the following month',
                ],
              },
              {
                text: 'Speed - Very Fast',
              },
              {
                text: 'Generate Unlimited Number of AI Covers',
                tooltips: ['You may keep and utilize unlimited number of AI voice models'],
              },
              {
                text: 'Vocal Extraction and AI Mixing',
                tooltips: ['Sorisori applies AI mixing technology to enhance song rendition'],
              },
              {
                text: 'Train Quality - High',
                tooltips: [
                  'Upload up to 90 minutes of audio that train for ~2 hours',
                  'Uses highest speed GPU. You may expect higher quality in training than the training quality of PRO plan',
                ],
              },
              {
                text: 'Sound Quality: High (WAV)',
                tooltips: ['Provide WAV file'],
              },
              {
                text: 'Vocal Extractor Feature: Unlimited',
                tooltips: ['Extract vocals, instrument and back vocals'],
              },
              {
                text: 'Beta - AI Speech: 20000 characters',
                tooltips: [
                  'You can change text to speech up to 20000 characters(Up to 1000 characters per generation) a month.',
                ],
              },
              {
                text: 'Beta - AI Speech Training: 3 model',
                tooltips: [
                  'Train three custom voice model every month. Unused credit does not carry over to the following month',
                ],
              },
            ],
          },
        ],
        sectionHelp: 'Support',
        faqTab: {
          title: 'FAQ',
          placeholder: 'Type to Search...',
          faqs: [
            {
              title: 'AI Training Questions',
              qnas: [
                {
                  question: 'What kind of file types are supported?',
                  answers: [
                    'Six file types are supported: mp3, wav, m4a, mkv, mp4, flac, ogg. It is crucial to obtain high-quality original audio sources. Converting files may result in loss of audio quality. The m4a extension corresponds to the format used by mobile phone recorders.',
                  ],
                },
                {
                  question: 'Is Vocal Extraction Necessary?',
                  answers: [
                    'The training process of Sorisori AI includes an excellent automatic vocal extraction feature. There is no need to extract the vocal separately; however, this process helps you to evaluate the quality of the extracted vocals before training.',
                  ],
                },
                {
                  question: 'Can I upload multiple songs?',
                  answers: [
                    'Yes, you can upload multiple files. Similarly, if you record manually, uploading multiple divided recordings is acceptable.',
                  ],
                },
                {
                  question: 'Recommended Microphone for Recording',
                  answers: [
                    'The voice recorder on your phone is sufficient. However, it is recommended to record in a quiet environment without accompaniment.',
                  ],
                },
                {
                  question: 'Uploading Songs Recorded in Karaoke Rooms',
                  answers: [
                    'It is not recommended due to the typically low audio quality and strong sound effects in karaoke recordings.',
                  ],
                },
                {
                  question: 'Voice Quality',
                  answers: [
                    'After recording, always check if your voice is recorded well. Sometimes, issues may arise if the microphone is too close or too far.',
                    '',
                  ],
                },
                {
                  question: 'If I delete a model, can I train again?',
                  answers: ['Deleting does not restore training opportunities'],
                },
              ],
            },
            {
              title: 'AI Cover Related Questions',
              qnas: [
                {
                  question: 'Cancellation During Training',
                  answers: [
                    'Check the file type of the audio file. Ensure that file extensions are appropriately converted during the process.',
                  ],
                },
                {
                  question: 'What kind of file types are supported?',
                  answers: [
                    'Six file types are supported: mp3, wav, m4a, mkv, mp4, flac, ogg. It is crucial to obtain high-quality original audio sources. Converting files may result in loss of audio quality. The m4a extension corresponds to the format used by mobile phone recorders.',
                  ],
                },
                {
                  question: 'Is Vocal Extraction Necessary?',
                  answers: [
                    'The training process of Sorisori AI includes an excellent automatic vocal extraction feature. There is no need to extract the vocal separately; however, this process helps you to evaluate the quality of the extracted vocals beforehand.',
                  ],
                },
                {
                  question: 'Narration Cover',
                  answers: ['Yes, narration can be covered.'],
                },
                {
                  question: 'Download Issue in Production Records Tab',
                  answers: [
                    'Try using standard browsers, including Chrome, Safari, Firefox, etc., as some apps might cause download issues.',
                  ],
                },
              ],
            },
            {
              title: 'AI Cover Production Tips',
              qnas: [
                {
                  question: 'Audio Quality Importance',
                  answers: [
                    'AI cover quality heavily depends on audio quality. We recommend purchasing official audio file for the best results.',
                  ],
                },
                {
                  question: 'Avoid Effects and Strong Choruses',
                  answers: [
                    'Avoid heavily processed audio with effects or strong choruses, as they may complicate clean vocal separation.',
                  ],
                },
                {
                  question: 'Harmonies',
                  answers: ['Avoid songs with multiple singers or heavy harmonies.'],
                },
              ],
            },
            {
              title: 'Payment Related Questions',
              qnas: [
                {
                  question: 'Upgrade Fee Calculation',
                  answers: ['The upgrade fee is the difference between the two subscription plans.'],
                },
                {
                  question: 'Canceling Subscription for a Single Month',
                  answers: [
                    'Go to the subscription tab and press "cancel" after clicking "change" to cancel for the current month.',
                  ],
                },
                {
                  question: 'Carrying Over Unused Models',
                  answers: ['Unused models do not carry over to the next month.'],
                },
                {
                  question: 'Carrying Over Unused Covers',
                  answers: ['Unused cover opportunities also do not carry over to the next month.'],
                },
                {
                  question: 'Card Payment Issues',
                  answers: [
                    'Currently, only internationally approved cards are accepted. Development is ongoing to enable payment through domestic cards and other services.',
                  ],
                },
                {
                  question: 'Refund Policy',
                  answers: [
                    'Refunds request has to be made within 21 days of payment, and a refund fee of $3 is applied.',
                  ],
                },
              ],
            },
            {
              title: 'Other Questions',
              qnas: [
                {
                  question: 'Deleting Account',
                  answers: [
                    'Contact via Discord for guidance or wait for the upcoming feature allowing users to withdraw directly.',
                  ],
                },
                {
                  question: 'What is Discord?',
                  answers: [
                    'Discord is a community where SoundSound AI users can exchange information, share work, and receive evaluations.',
                  ],
                },
              ],
            },
          ],
        },
        contactTab: {
          title: 'Contact Us',
          placeholder: {
            type: 'Contact Type',
            email: 'Email',
            text: 'Message',
            refund:
              "Refund Policy.\n\n1. There should be no usage record for the corresponding month.\n2. Refund request must be made within 3 weeks (21 days).\n3. A refund fee of $3 will be charged.\n4. You need to unsubscribe your account yourself. Go to Subscription tab, press any 'Change' button and you can unsubscribe",
          },
          message: {
            type: 'Please select a contact type',
            email: 'Please enter a valid email',
            content: 'Please enter a message',
            loading: 'Submitting request. Please wait.',
            success: 'Inquiry submitted successfully',
            fail: 'Could not submit the inquiry. Please try again.',
            useDiscord:
              'Please refer to the FAQ or use Discord community for questions regarding training/cover functionality',
          },
          contactType: ['Training/Cover Functionality', 'API Inquiry', 'Refund', 'Other Business Inquiry'],
          submit: 'Submit',
        },
        emailPreferenceTab: {
          title: 'Email Preference',
          header: 'Stay connected for exclusive offers, new features, and special announcements. Never miss a beat!',
          choiceLabels: {
            announcement: 'Announcement',
            notification: 'Notification',
            newsletter: 'Newsletter',
            promotion: 'Promotion',
          },
          submit: {
            save: 'Save',
          },
          modal: {
            success: 'Saved successfully',
            error: 'Something went wrong. Please Try Again',
          },
        },
        profileTab: {
          title: 'My Profile',
          sections: {
            login: {
              title: 'Account',
              logout: 'Log out',
            },
            payment: {
              title: 'Payment',
              changePayment: 'Change payment',
              updateSubscription: 'Update subscription',
              cancelSubscriptionChange: 'Undo subscription change',
              history: 'View payment history',
              cancel: 'Cancel subscription',
              currentSubscription: 'Current subscription',
              nextPaymentDate: 'Next billing date',
              currentCycle: 'Current cycle',
              nextSubscriptionType: 'Next cycle subscription',
            },
            question: {
              title: 'Questions',
              contentPre: 'Please use the ',
              contentLink: 'Contact Us',
              contentPost: ' tab for questions regarding payment/refund',
            },
          },
          subscriptionType: {
            FREE: 'FREE',
            BASIC: 'BASIC',
            PRO: 'PRO',
            PREMIUM: 'PREMIUM',
          },
        },
        paymentModal: {
          title: 'Payment History',
          date: 'date',
          info: 'info',
          amount: 'amount',
        },
      },
    },
    ko: {
      translation: {
        languageDetector: 'ko',
        requestFailed: '다시 시도해 주시기 바랍니다',
        pageLoadFailed: '페이지 로딩에 실패하였습니다. 새로고침을 해주세요',
        signIn: '로그인',
        defaultModels: ['샘플 음성 A(남)'],
        yes: '예',
        no: '아니오',
        sectionProduction: ['AI 커버'],
        trainingTab: {
          title: 'AI 음성 학습',
          tabs: ['직접 학습', '외부 모델'],
          stepOne: {
            title: '1단계: 음성 업로드',
            tooltip: {
              titleOne: '업로드 도움말',
              contentsOne: [
                '양보단 질. 저품질 30분 보단 고품질 15분 음원',
                '오토튠 같은 과도한 음향 효과가 들어간 곡은 X',
                '라이브 음원보단 정식음원 추천',
                '꼭! 솔로곡 만을 사용',
                '대화음성보다는 가창음성을 사용',
                '랩 커버를 원하는 경우 랩 음원 추천',
              ],
              titleTwo: '직접 녹음을 하는 경우',
              contentsTwo: [
                '휴대폰 녹음기도 사용 가능',
                '한번에 모두 녹음할 필요 X',
                '무반주 녹음을 추천',
                '코인노래방 녹음은 X',
                '업로드전 녹음이 잘 되었는지 확인필수!',
              ],
            },
            trainingSubTitles: ['학습시킬 음성 파일들을 업로드해주세요'],
            externalSubTitles: ['외부 모델 파일들을 업로드해주세요'],
            dragAndDropText: '파일을 끌어서 놓기',
            dragAndDropDuration: '(총합 {{minDuration}}분 이상 {{maxDuration}}분 미만)',
            dragAndDropButton: '파일 선택',
            durationText: '음원 길이 총합',
            durationUnit: '분',
            tabs: {
              fileUpload: {
                vocalExtractionText: '보컬만 추출한 파일들인가요?',
                vocalExtractionYes: '예',
                vocalExtractionNo: '아니요',
                vocalExtractionTooltip: {
                  title: '보컬 추출 관련 도움말',
                  contents: ['보컬 추출된 음원들인 경우에만 "예" 클릭', '일반적인 음원들인 경우 해당사항 없음'],
                },
              },
            },
          },
          stepTwo: {
            title: '2단계: 이름 정하기',
            subTitles: ['AI음성의 이름을 정하시고 학습을 시작 해주세요'],
            placeholder: 'AI음성의 이름을 정해주세요',
          },
          submit: {
            upload: '업로드',
            free: '구독서비스를 이용해주세요',
            inProgress: '학습중',
            noRemaining: '사용 가능한 횟수를 모두 사용하였습니다',
            start: '학습 시작',
            uploading: '업로드 중입니다',
            remainingCountText: '이번달 남은 횟수',
            tooltip: {
              contents: [
                '음성학습은 0.5-1시간 소요됩니다',
                '사용자가 몰리는경우 길어질 수 있습니다',
                '"제작 기록"탭에서 상태를 확인하실 수 있습니다',
              ],
            },
          },
          modal: {
            exceedModelCount:
              '무료/기본 요금제에서는 최대 3개의 음성 모델만 보유 가능합니다. 요금제를 업그레이드 하시거나 기존 모델중 하나를 삭제해주세요.',
            exceedMaxDuration: '{{maxDuration}}분 이상 음악 파일을 업로드 할수 없습니다.',
            minDuration: '최소 {{minDuration}}분 이상 음악 파일을 업로드 해주세요',
            notSupportedFileType: '지원되지 않은 파일 형식 입니다',
            imgModalConfirm: '시작하기',
            imgModalCancel: '돌아가기',
            noLabel: '이름을 넣어 주세요',
            missingFile: '모든 파일을 넣어주세요',
            noFile: '파일을 업로드 해주세요',
            longLabel: '이름은 25자를 넘을 수 없습니다',
          },
        },
        communityUploadTab: {
          title: '모델 공유',
          tabs: [' 내 모델 ', '외부 모델'],
          stepOne: {
            title: '1단계:이미지 업로드',
            subTitles: ['모델에 어울리는 사진을 올려주세요'],
            tabs: {
              fileUpload: {
                dragAndDropText: '파일을 끌어서 놓기',
              },
            },
          },
          stepTwo: {
            title: '2단계: 목소리 모델 고르기',
            trainingSubTitles: ['목소리를 골라 주세요'],
            externalSubTitles: ['목소리 모델 파일을 업로드 해 주세요 '],
            dragAndDropText: ['Path 파일 업로드', 'Index 파일 업로드'],
            dragAndDropButton: '파일 선택',
            voice: '목소리',
          },
          stepThree: {
            title: '3단계: 이름 정하기',
            subTitle: '모델의 이름을 정해주세요',
            genderLabel: '모델의 성별을 선택해 주세요',
            gender: ['남자', '여자'],
            anonymousText: '닉네임 숨기기',
          },
          stepFour: {
            title: '4단계: 모델에 태그 달기',
            subTitle: '모델에 어울리는 태그들을 달아주세요',
            tags: ['장르', '나라'],
          },
          submit: {
            upload: '업로드',
            start: '모델 공유',
            uploading: 'Uploading. Please Wait',
          },
          modal: {
            noImage: '이미지를 업로드 해주세요',
            noModel: '목소리를 골라주세요',
            noTag: '모델에 어울리는 태그들을 달아주세요',
            noLabel: '모델의 이름을 정해주세요',
            longLabel: '이름은 25자를 넘을 수 없습니다',
          },
        },
        inferenceTab: {
          title: 'AI 커버 제작',
          stepOne: {
            tooltip: {
              title: 'AI커버 제작 도움말',
              contents: [
                '음원의 음질은 결과물에 매우 중요',
                '음원 사이트에서 정식구매한 음원을 추천',
                '오토튠같은 음향효과가 많은 곡은 X',
                '화음이 많은 곡은 비추천',
              ],
            },
            title: '1단계: 노래 업로드',
            subTitles: ['커버 하려는 곡을 업로드 해주세요'],
            tabs: {
              fileUpload: {
                title: '파일업로드',
                dragAndDropText: '파일을 끌어서 놓기',
                dragAndDropButton: '파일 선택',
                dragAndDropDuration: '(최대 75 MB)',
                vocalExtractionText: '보컬만 추출한 파일인가요?',
                vocalExtractionYes: '예',
                vocalExtractionNo: '아니요',
                vocalExtractionTooltip: {
                  title: '보컬 추출 관련 도움말',
                  contents: ['이미 보컬 추출된 음원인 경우에만 “예" 클릭', '일반적인 음원의 경우 해당사항 없음'],
                },
              },
              youtube: {
                title: '유튜브 링크',
                linkPlaceholder: '10분 미만만 가능',
                linkbutton: '사용하기',
                durationUnit: ['분', '초'],
              },
              recording: {
                title: '음성녹음',
                startButton: '녹음 시작',
                saveButton: '저장',
                recordingButton: '녹음중',
                userRecording: '유저 음성녹음',
              },
            },
          },
          stepTwo: {
            title: '2단계: AI음성을 선택해주세요',
            modelInput: {
              placeHolder: 'AI음성을 선택',
              myModel: '내 모델',
            },
            dragAndDropText: '목소리 모델을 찾기위해 클릭',
          },
          stepThree: {
            title: '3단계: 설정',
            subTitles: '믹싱값, 파일확장자, 파일이름을 설정해주세요',
            labelInput: {
              placeHolder: '이름을 정해 주세요',
            },
            ouputFormatLabel: '원하시는 파일 포멧을 정해주세요',
            tooltip: {
              outputFormatMessage: 'WAV 파일은 프로/프리미엄 사용자에게만 해당됩니다',
              upgradePrompt: '여기를 클릭하여 업그레이드하세요',
              subscribePrompt: '여기를 클릭하여 구독하세요',
              title: 'AI 커버 이름 설정 관련 도움말',
              contents: ['직접 정하지 않을 경우 "음성 - 출처" 설정', '이후 제작기록에서 변경 가능'],
            },
          },
          submit: {
            completedAndLoadingPlayer:
              '제작 완료. 오디오 플레이어 로딩중 입니다. 대기하지 않으셔도 "제작기록"탭에서 다운로드 가능합니다',
            alreadyInProgress: '커버 제작중',
            noRemaining: '사용 가능한 횟수를 모두 사용하였습니다',
            start: '변환 시작',
            uploading: '업로드 중입니다 잠시만 기다려 주세요',
            tryAgain: '다시 시도 해주세요.',
            inProgress:
              '변환중입니다. 제작이 완료될때까지 대기하시면 음원을 바로 재생 할 수 있으며 대기하지 않으셔도 "제작기록"탭에서 제작 상태 확인 및 다운로드가 가능합니다',
            remainingCountText: '이번달 남은 횟수',
            remainingCountUnit: '곡',
            tooltip: {
              contents: [
                'AI커버 제작은 약 1-4분 소요 됩니다',
                '사용자가 몰리는경우 약간 길어질 수 있습니다',
                '"제작 기록"탭에서 상태를 확인하실 수 있습니다',
              ],
            },
          },
          modal: {
            watermark: '무료버전에는 음성 워터마크가 포함되어있습니다. 유료전환시 워터마크는 사라지게됩니다',
            exceedModelCount:
              '기본 요금제에서는 최대 3개의 음성 모델만 보유 가능합니다. 진행하시려면 기존 모델을 삭제해주세요.',
            exceedMaxSize: '최대 75MB 파일만 업로드 가능합니다',
            tooManyFiles: '파일 1개만 업로드 가능합니다',
            notSupportedFileType: '지원되지 않은 파일 형식 입니다',
            inProgress: '이미 학습중입니다.',
            noRemaining: '학습 가능 횟수를 모두 사용 하였습니다',
            emptyYoutubeLink: '커버하실 곡을 업로드 해주세요',
            noModalSelected: '음성모델을 선택 해주세요',
            noLabel: '이름을 넣어 주세요',
            invalidYoutubeLink: '올바르지 않은 유튜브 링크입니다.',
            youtubeLinkTooLong: '10분 이상 유튜브 음악을 업로드 할수 없습니다.',
            longLabel: '이름은 100자를 넘을 수 없습니다',
          },
        },
        ttsInferenceTab: {
          title: 'AI 문자음성 변환',

          stepOne: {
            modelInput: {
              placeHolder: 'AI음성을 선택',
              myModel: '내 모델',
            },
            ttsTextAreaPlaceholder: '원하는 글을 작성 하시면 음성으로 변환해 드립니다',

            dragAndDropText: '목소리 모델을 찾기위해 클릭',
          },
          submit: {
            completedAndLoadingPlayer:
              '제작 완료. 오디오 플레이어 로딩중 입니다. 대기하지 않으셔도 "AI 문자음성변환"-"제작기록"탭에서 다운로드 가능합니다',
            alreadyInProgress: '변환중',
            noRemaining: '사용 가능한 글자수를 모두 사용하였습니다',
            start: '변환 시작',
            uploading: '업로드 중입니다 잠시만 기다려 주세요',
            tryAgain: '다시 시도 해주세요.',
            inProgress:
              '변환중입니다. 제작이 완료될때까지 대기하시면 음원을 바로 재생 할 수 있으며 대기하지 않으셔도 "AI 문자음성변환"-"제작기록"탭에서 제작 상태 확인 및 다운로드가 가능합니다',
            remainingCountText: 'AI 문자음성변환 가능한 글자 수',
            remainingCountUnit: '자',
            remainingMonthly: '이번달 남은 글자 수',
            setting: '  세부조정',
          },
          modal: {
            selectModalTitle: 'AI 음성',
            addNewModelText: '새 음성 만들기',
            exceedTextSize: '한번에 변환 가능한 글자 수를 초과했습니다. 글자수 : {{characterLimitPerInference}}',
            inProgress: '이미 학습중입니다',
            noRemaining: '이번 달에 사용가능한 한도를 초과하였습니다',
            noModalSelected: '음성모델을 선택 해주세요',
            noText: '문자를 입력해 주세요',
            variability: '변동성',
            stable: '단조로움',
            variable: '변동적',
            similarity: '유사도',
            low: '낮음',
            high: '높음',
            longLabel: '이름은 50자를 넘을 수 없습니다',
          },
        },
        ttsTrainingTab: {
          title: 'AI 문자음성 학습',
          stepOne: {
            tooltip: {
              contentsOne: [
                '양보단 질. 저품질 10분 보단 고품질 5분 음원',
                '배경 소음이 없는 깨끗한 음성 데이터를 사용',
                '대화음성보다는 가창음성을 사용',
                '10mb 보다 작은 파일만 업로드 가능',
                '최대 10개의 파일을 업로드 가능',
              ],
            },
            sizeLimitText: '(각 파일은 10MB보다 작아야 합니다)',
            numberLimitText: '(최대 10개의 파일)',
            dragAndDropDuration: '(총합 {{minDuration}}초 이상 {{maxDuration}}분 미만)',
            second: '초',
          },
          modal: {
            sizeTooLarge: '각 파일은 10MB보다 작아야 합니다.',
            tooManyFiles: '최대 10개의 파일까지만 업로드할 수 있습니다.',
            duplicateFiles: '중복된 파일을 업로드할 수 없습니다.',
            exceedMaxDuration: '{{maxDuration}}분 이상 음악 파일을 업로드 할수 없습니다.',
            minDuration: '최소 {{minDuration}}초 이상 음악 파일을 업로드 해주세요',
          },
          submit: {
            tooltip: {
              contents: [
                '음성학습은 0.5-10분 소요됩니다',
                '사용자가 몰리는경우 길어질 수 있습니다',
                "'AI 문자음성변환'-'제작 기록'탭에서 상태를 확인하실 수 있습니다",
              ],
            },
          },
        },
        ttsAppBar: {
          sectionTTS: '문자음성변환',
          subSectionTTS: ['AI 변환', 'AI 음성 학습', '문자음성 제작기록'],
        },
        ttsHistoryTab: {
          title: '문자음성변환 제작기록',
          tabs: {
            inference: {
              title: '문자음성변환',
            },
            training: {
              title: 'AI 음성',
            },
          },
          infoIcon: {
            voice: '음성',
            variability: '변동성',
            similarity: '유사도',
          },
        },
        videoEditorTab: {
          title: '영상 편집',
          addSource: 'Source',
        },
        addSourceModal: {
          title: '자료 추가',
          sourceOptions: [
            'AI커버',
            'TTS',
            '유튜브',
            '파일 업로드',
          ],
          searchbar: '검색',
          pagination: {
            loader: '로딩중...',
          },
          voice: 'AI음성',
          youtube: {
            placeholder: '10분 미만만 가능',
            button: '검색',
            invalidYoutubeLink: '올바르지 않은 유튜브 링크입니다.',
            youtubeLinkTooLong: '10분 이상 유튜브 음악을 업로드 할수 없습니다.',
          },
          upload: {
            dragAndDropText: '파일을 끌어서 놓기',
            sizeLimitText: '(Max 75 MB)',
            acceptedAudio: '.mp3, .wav, .flac, and .ogg',
            acceptedVideo: '.mp4 and .mkv',
            acceptedImage: '.jpeg, .png, and .webp',
            button: 'Select file',
            notSupportedFileType: 'This file format is not supported',
            tooManyFiles: 'Please upload one file at a time',
            exceedMaxSize: 'You can only upload files up to 75MB',
          },
          loading: [
            '업로드중',
            '(용량이 큰 경우 시간이 걸릴 수 있습니다)',
          ],
        },
        textEditorModal: {
          title: [
            '텍스트 추가',
            '텍스트 편집',
          ],
          placeholder: '문자를 입력해 주세요',
          add: '추가',
          save: '저장',
        },
        videoExportModal: {
          title: '영상 다운로드',
          placeholder: '영상 제목을 넣어주세요',
          watermark: '워터마크 표기',
          start: '시작',
          rendering: [
            '렌더링이 끝날때까지 창을 나가지 마세요',
            '약 1분정도 걸립니다',
          ],
          success: [
            '영상이 준비되었습니다',
            '다운로드가 자동으로 시작되지 않는다면 아래 버튼을 클릭해주세요',
          ],
          tooltip: {
            contents: [
              '영상이 길수록 렌더링에 시간이 더 소요될 수 있습니다',
            ]
          },
          videoRenderError: 'Could not render the video. Please try again',
        },
        // MR제거 페이지
        vocalExtractorTab: {
          title: 'MR 제거',
          stepOne: {
            title: '1단계: 음성 업로드',
            tabs: {
              fileUpload: {
                title: '파일업로드',
                dragAndDropText: '파일을 끌어서 놓기',
                dragAndDropButton: '파일 선택',
                dragAndDropDuration: '(파일당 최대 75 MB)',
              },
              youtube: {
                title: '유튜브 링크',
                linkPlaceholder: '10분 미만만 가능',
                linkbutton: '사용하기',
                durationUnit: ['분', '초'],
              },
            },
            vocalExtractorSubTitles: ['MR 제거할 음성 파일을 업로드해주세요'],
            tooltip: {
              contents: [
                '프리미엄 유저는 한 번에 최대 10개의 파일을 업로드할 수 있습니다',
                '프로 유저는 한 번에 최대 5개의 파일을 업로드할 수 있습니다',
                '기본/무료 유저는 한 번에 1개의 파일을 업로드할 수 있습니다',
                '오디오 파일의 총 길이는 60분을 초과할 수 없습니다',
              ],
            },
            maxUploadNumText: {
              premiumCustomAdmin: '최대 10개 파일 업로드 가능',
              pro: '최대 5개 파일 업로드 가능',
              basicFree: '최대 1개 파일 업로드 가능',
            },
          },
          stepTwo: {
            title: '2단계: 설정',
            subTitles: '파일확장자와 파일이름을 설정해주세요',
            labelInput: {
              placeHolder: '파일이름을 정해 주세요',
            },
            tooltip: {
              outputFormatMessage: 'WAV 파일은 프로/프리미엄 사용자에게만 해당됩니다',
              upgradePrompt: '여기를 클릭하여 업그레이드하세요',
              subscribePrompt: '여기를 클릭하여 구독하세요',
            },
          },
          submit: {
            alreadyInProgress: '제작중',
            upload: '업로드',
            inProgress: '변환중입니다."제작기록"탭에서 제작 상태 확인 및 다운로드가 가능합니다',
            noRemaining: '사용 가능한 횟수를 모두 사용하였습니다',
            start: '시작',
            uploading: '업로드 중입니다 잠시만 기다려 주세요',
            tryAgain: '다시 시도 해주세요',
            remainingCountText: '이번달 남은 횟수',
            remainingCountUnit: '곡',
          },
          modal: {
            notSupportedFileType: '지원되지 않은 파일 형식 입니다',
            invalidYoutubeLink: '올바르지 않은 유튜브 링크입니다.',
            youtubeLinkTooLong: '10분 이상 유튜브 음악을 업로드 할수 없습니다.',
            noRemaining: '사용 가능 횟수를 모두 사용 하였습니다',
            emptyYoutubeLink: 'MR제거할 음성파일을 업로드 해주세요',
            inProgress: '이미 학습중입니다',
            exceedMaxSize: '파일당 최대 75MB 파일만 업로드 가능합니다',
            exceedMaxDuration: '총 재생 시간은 60분을 초과할 수 없습니다',
            premiumCustomAdmin: '프리미엄/커스텀',
            pro: '프로',
            filePlural: '개를',
            tooManyFiles: ['유저는 최대 파일 ', '업로드할 수 있습니다'],
            noFiles: 'MR제거할 음성파일을 추가해주세요',
            lackRemaining: '사용 가능 횟수를 초과합니다',
            uploadStatusNotValid: 'MR 제거 남은 횟수 부족 혹은 이미 진행중 입니다',
            tryAgain: '업로드 실패한 파일들:',
            noLabel: '파일이름을 정해 주세요',
            longLabel: '이름은 50자를 넘을 수 없습니다',
          },
        },
        historyTab: {
          title: '제작기록',
          inProgress: '제작중',
          failed: '취소됨',
          freeVersionTag: '무료버전',
          freePrefix: '무료버전 소리소리AI 광고포함',
          tooltip: {
            contents: [
              'AI커버 제작은 평균 2-4분 소요됩니다',
              '음성학습은 평균 0.5-1시간 소요됩니다',
              "작업이 취소가 된 경우, '문의사항' 탭을 이용해주세요",
            ],
          },
          tabs: {
            inference: {
              title: 'AI 커버',
            },
            training: {
              title: 'AI 음성',
            },
            vocalExtract: {
              title: 'MR 제거',
            },
            community: {
              title: '커뮤니티',
            },
          },
          changeButton: '변경',
          cancelButton: '취소',
          confirmModelDelete: '현재 모델을 삭제하셔도 재학습은 불가능합니다. 삭제하시겠습니까?',
          confirmInferenceDelete: '정말 삭제하시겠습니까?',
          confirmVocalExtractDelete: '정말 삭제하시겠습니까?',
          searchbar: '검색',
          infoIcon: {
            source: '출처',
            voice: '음성',
            pitch: '키조절',
            mixing: {
              title: '믹싱',
              vocalLevel: '음성 볼륨',
              reverbLevel: '리버브 볼륨',
              reverbLength: '리버브 길이',
            },
            errorMessage: '오류',
          },
          pagination: {
            loader: '로딩중...',
          },
          modal: {
            error: '웹페이지를 새로고침 해주세요',
            label: '이름',
            username: '닉네임',
            image: '이미지',
            filterError: '음성 필터를 찾을수 없습니다',
          },
          trainingInfo: {
            files: '사용한 음원',
            extracted: '보컬 추출기능 사용됨',
            shared: '커뮤니티에 공유됨',
            yes: '예',
            no: '아니오',
            status: '상태',
            errorFile: '오류가 있는 파일',
          },
          voice: 'AI음성',
        },
        subscriptionTab: {
          title: '요금제',
          pageTitleOne: '소리소리AI',
          popular: '인기 요금제',
          cancel: {
            message: '구독 취소 하시겠습니까?',
            success: '구독 취소 완료!',
            fail: '구독 취소 실패. 다시 시도해 주세요.',
          },
          cancelChange: {
            message: '구독 변경 취소 하시겠습니까?',
            success: '구독 변경 취소 완료!',
            fail: '구독 변경 취소 실패. 다시 시도해 주세요.',
          },
          onetime: {
            message: '구매 하시겠습니까?',
            success: '구매 완료!',
            fail: '결제 실패 하였습니다. 다시 시도해 주시길 바랍니다.',
          },
          downgrade: {
            message: '요금제 변경을 진행하시겠습니까?',
            success: '요금제 변경이 완료되었습니다!',
            fail: '요금제 변경을 실패 하였습니다. 다시 시도해 주시기 바랍니다',
          },
          upgrade: {
            message: '요금제 변경을 진행하시겠습니까? 기존에 있던 결제방식으로 결제가 시도 됩니다.',
            success: '요금제 변경이 완료되었습니다!',
            fail: '요금제 변경을 실패 하였습니다. 다시 시도해 주시기 바랍니다',
            paymentFailed: '결제 실패 하였습니다. 내 프로필에서 결제 수단 변경 하시거나 다시 시도해 주시기 바랍니다.',
          },
          mobilePaymentRequired: '모바일 앱에서 구독을 설정하셨기 때문에 구독 관리는 모바일 앱에서 해주시기 바랍니다.',
          tabs: {
            subscription: {
              title: '구독',
              pageTitleTwo: '구독 서비스',
              status: '사용중',
              pastDue: '결제 미완료',
              month: '월',
              subscribe: '구독하기',
              change: '변경하기',
            },
            onetime: {
              title: '개별 상품',
              pageTitleTwo: '개별 상품',
              purchase: '구매하기',
              history: '구매 기록',
              invalidSubscriptionType: '구매 하기 위해서는 구독 서비스를 이용해주세요',
            },
          },
          paymentModal: {
            usePromoCode: '프로모션 코드 사용',
            promoCodePlaceholder: '프로모션 코드',
            applyButton: '사용',
            promoCodeApplied: '프로모션 코드 적용',
            invalidPromoCode: '올바르지 않은 프로모션 코드입니다',
            genericError: '다시 시도해 주세요',
            promoCodeError: {
              doesNotExist: '유효하지 않은 할인 코드입니다. 할인 코드를 확인해주세요',
              invalidSubscriptionType: '이 할인 코드는 프로와 프리미엄에만 적용됩니다',
              onlyFirstTime: '이 할인 코드는 신규 사용자에게만 유효합니다'
            }
          },
        },
        communityTab: {
          title: '커뮤니티',
          subTitle: '원하는 음성을 선택해서 AI 커버를 만들어보세요!',
          inProgress: '제작중',
          tags: '태그',
          failed: '취소됨',
          freeVersionTag: '무료버전',
          freePrefix: '무료버전 소리소리AI 광고포함',
          voiceOptions: ['커뮤니티', '내 음성', '좋아요'],
          sortOptions: ['최신순', '좋아요수', '제작횟수', '모델이름'],
          tooltip: {
            contents: [
              'AI커버 제작은 평균 2-4분 소요됩니다',
              '음성학습은 평균 2-4시간 소요됩니다',
              "작업이 취소가 된 경우, '문의사항' 탭을 이용해주세요",
            ],
          },
          tabs: {
            inference: {
              title: 'AI 커버',
            },
            training: {
              title: 'AI 음성',
            },
            vocalExtract: {
              title: 'MR 제거',
            },
          },
          changeButton: '변경',
          cancelButton: '취소',
          confirmModelDelete: '현재 모델을 삭제하셔도 재학습은 불가능합니다. 삭제하시겠습니까?',
          confirmInferenceDelete: '정말 삭제하시겠습니까?',
          confirmVocalExtractDelete: '정말 삭제하시겠습니까?',
          searchbar: '검색',
          infoIcon: {
            source: '출처',
            voice: '음성',
            pitch: '키조절',
          },
          pagination: {
            loader: '로딩중...',
          },
          modal: {
            error: '웹페이지를 새로고침 해주세요.',
          },
          button: {
            share: '모델 공유',
          },
        },
        supportTab: {
          title: '디스코드',
          link: 'https://discord.gg/AVREYrRZv4',
        },
        pipeline: {
          inferenceStatusMessage: {
            formatError:
              '파일 형식과 확장자가 일치하지 않거나, 파일이 손상되었거나 안전하지 않습니다. 파일을 확인해주세요',
            volumeError:
              '파일의 목소리 음량이 너무 낮거나, 파일에 목소리가 없습니다. 녹음 설정을 확인하거나 오디오 편집 소프트웨어를 사용하여 음량을 높여주세요.',
            invalidFileError: '파일을 열 수 없습니다. 파일을 확인해주세요.',
            progress10: '진행 중 10%',
            progress20: '진행 중 20%',
            progress40: '진행 중 40%',
            progress50: '진행 중 50%',
            progress60: '진행 중 60%',
            progress80: '진행 중 80%',
            progress100: '진행 중 100%',
            complete: '완료',
            generalMessage:
              'AI 커버에 실패했습니다. 다시 시도해 주세요. 문제가 지속되면 SORISORI 지원 팀에 문의해 주세요.',
            starting: '시작 중',
          },
          trainingStatusMessage: {
            formatError: '파일 형식과 확장자가 일치하지 않거나, 파일이 손상되었거나 안전하지 않습니다',
            volumeError:
              '파일의 목소리 음량이 너무 낮거나, 파일에 목소리가 없습니다. 녹음 설정을 확인하거나 오디오 편집 소프트웨어를 사용하여 음량을 높여주세요.',
            invalidFileError: '파일을 열 수 없습니다. 파일을 확인해주세요.',
            progress10: '진행 중 10%',
            progress20: '진행 중 20%',
            progress40: '진행 중 40%',
            progress50: '진행 중 50%',
            progress60: '진행 중 60%',
            progress80: '진행 중 80%',
            progress100: '진행 중 100%',
            complete: '완료',
            generalMessage:
              '학습에 실패했습니다. 다시 시도해 주세요. 문제가 지속되면 SORISORI 지원 팀에 문의해 주세요.',
            starting: '시작 중',
          },
        },
        modal: {
          confirmationYes: '예',
          confirmationNo: '아니오',
          combined: '통합본',
          vocal: '보컬',
          backVocal: '코러스',
          instrument: '반주',
          fileUploadFail: '파일 업로드에 실패 하였습니댜. 파일 체크 후 다시 시도해 주세요',
          trainingInProgressError: '이미 학습중입니다',
          noRemainingError: '사용 가능한 횟수를 모두 사용하였습니다',
          genericError: '파일 업로드에 실패 하였습니다. 다시 시도해주세요',
          pageLoadFail: '페이지 로딩에 실패하였습니다. 페이지를 새로고침 해주세요',
          pageLoadFailOnSubmit:
            "파일 업로드에 성공 하였지만 페이지 로딩에 실패하였습니다. 페이지를 새로고침 해주세요. 기록은 '제작기록' 탭에서 확인 가능합니다",
          gotoProfile: '내 프로필 보기',
        },
        mixingModal: {
          label: '믹스',
          title: '믹싱',
          sliders: {
            contents: ['키 조절', '음성 볼륨', '리버브 볼륨', '리버브 길이'],
          },
          tooltip: {
            contents: [
              '남성음성으로 여성곡을 커버할경우 -4 ~ -6 추천',
              '여성음성으로 남성곡을 커버할경우 +4 ~ +6 추천',
              '과도한 키 조절은 음질을 저하 시킬 수 있습니다',
              '보컬 트랙의 음량',
              '0db는 원래 곡의 보컬 음량입니다',
              '리버브 효과의 크기',
              '0은 리버브효과가 없으며, 6은 리버브효과가 최대치로 세팅됩니다',
              '리버브의 효과의 길이',
              '발라드곡에는 2초가량이 추천되며, 빠른곡일수록 더 낮은 수치를 추천드립니다',
            ],
          },
          demo: {
            toggle: '믹싱',
            status: ['믹싱안됨', '믹싱됨'],
            description: '믹싱 기능의 차이를 들어보세요',
          },
          dialog: {
            title: '현재 요금제로는 사용 불가능한 기능입니다',
            text: '프로나 프리미엄으로 업그레이드 하시면 믹싱 기능을 사용하실 수 있습니다',
          },
          upgradeButton: ['요금제 변경', ''],
          alert: '각 믹서의 값을 설정해 주세요',
        },
        freeInferenceMessageModal: {
          messeageOne: ['무료버전에는 광고가 포함 되어 있으며', '커버를 45초간 들어보실 수 있습니다'],
          messeageTwo: ['유료 전환시 광고는 사라지며', '전곡을 즐겨보실 수 있습니다'],
          button: '요금제 확인',
        },
        freeTrainingMessageModal: {
          messages: ['AI학습은 유료 전환 후', '사용 가능합니다'],
          button: '요금제 확인',
        },
        freeVocalExtractorMessageModal: {
          free: {
            message: [
              '무료 유저는',
              '최대 1개의 파일을 업로드할 수 있습니다',
              '더 많은 파일을 업로드 하시려면',
              '구독 해주세요',
            ],
          },
          basic: {
            message: [
              '기본 유저는',
              '최대 1개의 파일을 업로드할 수 있습니다',
              '더 많은 파일을 업로드 하시려면',
              '구독 해주세요',
            ],
          },
          button: '구독하기',
        },
        onetimeItems: {
          training: {
            name: '추가 모델 제작',
            price: {
              usd: '횟수당 $7',
              thb: '횟수당 ฿250',
              krw: '횟수당 8,500원',
              jpy: '횟수당 ¥990',
            },
          },
          vocalExtract: {
            name: '추가 MR제거 100회',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '5,000원',
              jpy: '¥590',
            },
          },
          inference: {
            tooltip: '전 곡을 광고 없이 제작 가능합니다',
            name: '추가 커버제작 5회',
            price: {
              usd: '$4.99',
              thb: '฿75',
              krw: '5,000원',
              jpy: '¥590',
            },
          },
          tts: {
            name: '추가 문자음성변환 3000자',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '5,000원',
              jpy: '¥590',
            },
          },
        },
        subscriptionItems: [
          {
            name: '무료',
            model: 'FREE', //영어로 나두기
            price: {
              usd: '$0',
              thb: '฿0',
              krw: '0원',
              jpy: '¥0',
            },
            period: '',
            features: [
              {
                text: '속도 - 느림',
              },
              {
                text: 'AI커버 제작 월 3곡',
                tooltips: ['매월 AI커버를 3회 제작할 수 있습니다'],
              },
              {
                text: '보컬 추출및 AI믹싱 과정 포함',
                tooltips: ['소리소리의 AI기술을 이용하여 원곡에 가깝게 오디오 믹싱을 해드립니다'],
              },
              {
                text: '학습 성능 - 보통',
                tooltips: [
                  '10분미만의 음성을 약 5분간 학습합니다',
                  '하위 레벨의 GPU를 사용합니다. 유료 요금제를 사용하시면 더 높은 성능을 기대 하실 수 있습니다',
                ],
              },
              {
                text: '음질 - 보통 (MP3)',
                tooltips: ['MP3파일을 제공합니다', '워터마크를 포함합니다'],
              },
              {
                text: 'MR제거 - 3곡',
                tooltips: ['보컬및 코러스와 반주 추출 파일을 제공하는 기능을 총 3회 제공합니다'],
              },
              {
                text: '베타 - 문자음성변환: 20자',
                tooltips: ['매월 문자음성변환 기능을 20자까지 제공합니다'],
              },
            ],
          },
          {
            name: '기본',
            model: 'BASIC', //영어로 나두기
            price: {
              usd: '$9.99',
              thb: '฿350',
              krw: '14,800원',
              jpy: '¥1,699',
            },
            discountedPrice: {
              usd: '',
              thb: '฿279',
              krw: '',
              jpy: '',
            },
            discountRate: {
              usd: '',
              thb: '20% Off',
              krw: '',
              jpy: '',
            },
            period: '/월',
            features: [
              {
                text: 'AI음성 학습 횟수 월 1회',
                tooltips: [
                  '매월 결제일마다 학습 가능 횟수가 초기화 됩니다. 사용 되지 않은 학습 횟수는 이월 되지 않습니다',
                ],
              },
              {
                text: '속도 - 보통',
              },
              {
                text: '제작 가능한 AI커버 월 200곡',
                tooltips: [
                  '매월 결제일마다 제작가능한 곡 수가 초기화 됩니다. 사용 되지 않은 제작 횟수는 이월 되지 않습니다',
                ],
              },
              {
                text: '보컬 추출및 AI믹싱 과정 포함',
                tooltips: ['소리소리의 AI기술을 이용하여 원곡에 가깝게 오디오 믹싱을 해드립니다'],
              },
              {
                text: '학습 성능 - 좋음',
                tooltips: [
                  '최대30분의 음성을 약 2시간동안 학습합니다',
                  '중위 레벨의 GPU를 사용합니다. 무료 버전보다 훨씬 높은 성능을 기대할 수 있습니다.',
                ],
              },
              {
                text: '음질 - 보통 (MP3)',
                tooltips: ['MP3파일을 제공합니다'],
              },
              {
                text: 'MR제거기능 횟수 월 10회',
                tooltips: ['보컬및 코러스와 반주 추출 파일을 제공하는 기능을 매월 10회 사용가능합니다'],
              },
              {
                text: '베타 - 문자음성 변환: 월 200자',
                tooltips: ['매월 문자음성변환 기능을 200자(한 변환에 50자까지)까지 제공합니다'],
              },
              {
                text: '베타 - 문자음성 학습: 월 1회',
                tooltips: [
                  '문자음성 변환에 사용가능한 목소리 학습 기능을 월 2회 제공합니다. 매월 결제일마다 학습 가능 횟수가 초기화 됩니다. 사용 되지 않은 학습 횟수는 이월 되지 않습니다',
                ],
              },
            ],
          },
          {
            name: '프로',
            model: 'PRO', //영어로 나두기
            price: {
              usd: '$24.80',
              thb: '฿880',
              krw: '28,800원',
              jpy: '¥3,490',
            },
            discountedPrice: {
              usd: '',
              thb: '฿619',
              krw: '',
              jpy: '',
            },
            discountRate: {
              usd: '',
              thb: '30% Off',
              krw: '',
              jpy: '',
            },
            period: '/월',
            features: [
              {
                text: 'AI음성 학습 횟수 월 3회',
                tooltips: [
                  '매월 결제일마다 학습 가능 횟수가 초기화 됩니다. 사용 되지 않은 학습 횟수는 이월 되지 않습니다',
                ],
              },
              {
                text: '속도 - 빠름',
              },
              {
                text: '제작 가능한 AI커버 월 1000곡',
                tooltips: [
                  '매월 결제일마다 제작가능한 곡 수가 초기화 됩니다. 사용 되지 않은 제작 횟수는 이월 되지 않습니다',
                ],
              },
              {
                text: '보컬 추출및 AI믹싱 과정 포함',
                tooltips: ['소리소리의 AI기술을 이용하여 원곡에 가깝게 오디오 믹싱을 해드립니다'],
              },
              {
                text: '학습 성능 - 매우 좋음',
                tooltips: [
                  '최대 45분의 음성을 약 2시간동안 학습합니다',
                  '상위 레벨의 GPU를 사용합니다. 기본 요금제보다 높은 성능을 기대할수있습니다',
                ],
              },
              {
                text: '음질 - 높음 (WAV)',
                tooltips: ['WAV파일을 제공합니다'],
              },
              {
                text: 'MR제거기능 횟수 월 50회',
                tooltips: ['보컬및 코러스와 반주 추출 파일을 제공하는 기능을 매월 50회 사용가능합니다'],
              },
              {
                text: '베타 - 문자음성 변환: 월 4000자',
                tooltips: ['매월 문자음성변환 기능을 4000자(한 변환에 200자까지)까지 제공합니다'],
              },
              {
                text: '베타 - 문자음성 학습: 월 2회',
                tooltips: [
                  '문자음성 변환에 사용가능한 목소리 학습 기능을 월 2회 제공합니다. 매월 결제일마다 학습 가능 횟수가 초기화 됩니다. 사용 되지 않은 학습 횟수는 이월 되지 않습니다',
                ],
              },
            ],
          },
          {
            name: '프리미엄',
            model: 'PREMIUM', //영어로 나두기
            price: {
              usd: '$68.80',
              thb: '฿2400',
              krw: '88,800원',
              jpy: '¥8,990',
            },
            discountedPrice: {
              usd: '',
              thb: '฿1680',
              krw: '',
              jpy: '',
            },
            discountRate: {
              usd: '',
              thb: '30% Off',
              krw: '',
              jpy: '',
            },
            period: '/월',
            features: [
              {
                text: 'AI음성 학습 횟수 월 13회',
                tooltips: [
                  '매월 결제일마다 학습 가능 횟수가 초기화 됩니다. 사용 되지 않은 학습 횟수는 이월 되지 않습니다',
                ],
              },
              {
                text: '속도 - 매우 빠름',
              },
              {
                text: '제작 가능한 AI커버 무제한',
                tooltips: ['매월 제작가능한 곡 수가 무제한 입니다'],
              },
              {
                text: '보컬 추출및 AI믹싱 과정 포함',
                tooltips: ['소리소리의 AI기술을 이용하여 원곡에 가깝게 오디오 믹싱을 해드립니다'],
              },
              {
                text: '학습 성능 - 최상',
                tooltips: [
                  '최대 90분의 음성을 3시간 이상 학습합니다',
                  '최상위 레벨의 GPU를 사용합니다. 프로 요금제보다 높은 성능을 기대할수있습니다',
                ],
              },
              {
                text: '음질 - 높음 (WAV)',
                tooltips: ['WAV파일을 제공합니다'],
              },
              {
                text: 'MR제거 기능 횟수 무제한',
                tooltips: ['보컬및 코러스와 반주 추출 파일을 제공하는 기능을 매월 무제한 사용가능합니다'],
              },
              {
                text: '베타 - 문자음성 변환: 월 20000자',
                tooltips: ['매월 문자음성변환 기능을 20000자(한 변환에 1000자까지)까지 제공합니다'],
              },
              {
                text: '베타 - 문자음성 학습: 월 3회',
                tooltips: [
                  '문자음성 변환에 사용가능한 목소리 학습 기능을 월 3회 제공합니다. 매월 결제일마다 학습 가능 횟수가 초기화 됩니다. 사용 되지 않은 학습 횟수는 이월 되지 않습니다',
                ],
              },
            ],
          },
        ],
        sectionHelp: '고객지원',
        faqTab: {
          title: '자주묻는질문',
          placeholder: '검색어를 입력하세요...',
          faqs: [
            {
              title: 'AI학습 관련 질문',
              qnas: [
                {
                  question: '업로드시에 어떤 파일 확장자를 지원하나요?',
                  answers: [
                    'mp3, wav, m4a, mkv, mp4, flac, ogg 여섯 가지를 지원합니다. 높은 품질의 원본 음원을 구하는 것이 중요하며, 파일을 변환할수록 음원에 손실을 일으키게 됩니다. m4a의 경우, 휴대폰 녹음기에 해당되는 확장자 입니다.',
                  ],
                },
                {
                  question: '보컬 추출을 해야하나요?',
                  answers: [
                    '소리소리AI의 학습과정에는, 매우 우수한 성능의 보컬추출 기능이 이미 포함되어 있기 때문에 따로 하실 필요가 없습니다.',
                    '기본적으로는 불필요하지만, 학습전에 보컬 추출된 음원의 퀄리티를 직접 확인하고 커버를 제작할수 있다는 장점이 있습니다.',
                    '"MR제거기능"을 통해서 보컬추출을 진행하실 수 있습니다.',
                  ],
                },
                {
                  question: '여러 곡을 업로드해서 학습해도 되나요?',
                  answers: [
                    '네, 여러 파일을 업로드 하실 수 있습니다.',
                    '같은 이유로, 직접 녹음하시는 경우에도 여러번에 나눠 녹음하신 뒤 복수의 파일을 업로드하셔도 문제되지 않습니다',
                  ],
                },
                {
                  question: '어떤 마이크로 녹음을 해야하나요?',
                  answers: [
                    '단순한 휴대폰 녹음기도 충분합니다. 다만 조용한 환경에서 무반주로 녹음하시는 것을 추천드립니다.',
                  ],
                },
                {
                  question: '노래방에서 부른 곡을 업로드해도 되나요?',
                  answers: [
                    '노래방 같은 경우, 애초에 음질도 좋지 않고, 무엇보다 음향효과가 너무 강하기 때문에 피해주시기 바랍니다. 파열음을 동반한 노래방 특유의 울리는 듯한 소리가 같이 학습될 수 있어 추천드리지 않습니다.',
                  ],
                },
                {
                  question:
                    '녹음이 완료된 뒤에는 본인의 음성이 잘 녹음 되었는지 꼭! 확인 해 주세요. 간혹 마이크가 너무 가깝거나 멀어서 음성이 파열되거나 잘 안들리는 경우가 있습니다.',
                  answers: [],
                },
                {
                  question: '제작 결과가 마음에 안드는데, 삭제하고 새로 만들 수 있나요?',
                  answers: [
                    '삭제를 하시더라도 학습 횟수가 복구되진 않습니다. 일단 학습의 시작과 동시에 서버비용이 발생하게 되다 보니, 단순히 제작하신걸 지워도 재학습을 할 수 없는점 이해해 주시기 바랍니다.',
                  ],
                },
              ],
            },
            {
              title: 'AI 커버 제작 관련 질문',
              qnas: [
                {
                  question: '학습 중에 자꾸 취소가 돼요.',
                  answers: [
                    '업로드 하신 음원파일을 확인해 주시기 바랍니다. 가장 먼저 재생이 잘 되는지 확인해 주시고, 파일을 변환하실 때 파일 확장자를 단순히 .mp3나 .wav로 바꾸신게 아닌지 확인해 주시기 바랍니다. 동영상을 사용하고 싶으신 경우 인터넷에서 간단한 변환과정을 거쳐주시기 바랍니다.',
                  ],
                },
                {
                  question: '업로드시에 어떤 파일 확장자를 지원하나요?',
                  answers: [
                    'mp3, wav, m4a, mkv, mp4, flac, ogg 여섯 가지를 지원합니다. 높은 품질의 원본 음원을 구하는 것이 중요하며, 파일을 변환할수록 음원에 손실을 일으키게 됩니다. m4a의 경우, 휴대폰 녹음기에 해당되는 확장자 입니다.',
                  ],
                },
                {
                  question: '보컬 추출을 별도로 해야하나요?',
                  answers: [
                    '소리소리AI의 커버 제작과정에는, 매우 우수한 성능의 보컬추출 기능이 자동으로 포함되어 있으므로 따로 진행하실 필요가 없습니다.',
                    '다만 직접 추출을 하실경우, 추출된 보컬의 퀄리티를 직접 확인하고 커버를 제작할수 있다는 장점이 있습니다. 이 경우 "보컬만 추출한 파일인가요?" 라는 질문에 "예"를 선택해 주시기 바랍니다.',
                  ],
                },
                {
                  question:
                    '가수 그룹 멤버중 한명만 학습시키고 싶으면 그 사람만 편집으로 부분부분 잘라서 학습시켜도 될까요?',
                  answers: [
                    '네, 하나의 음원에서 개별 멤버의 목소리를 학습시키고 싶으신 경우, 직접 부분부분 잘라서 편집을 하셔서 학습 시켜주셔야 합니다.',
                  ],
                },
                {
                  question: '피치변환은 어떻게 활용해야 하나요?',
                  answers: [
                    '남성과 여성 보컬은 일반적으로 4-6키가 차이납니다. 예를 들어 남성 AI음성으로 여성곡을 커버하시려는 경우, 키조절 도구에서 +4 에서 +6사이를 시도해보시는 것을 추천드립니다. 다만 피치변환 자체가 음질을 하락시키는 효과가 있기 때문에, AI음성이나 커버 음원에 따라 다소 불안정한 변환이 있을 수 있습니다.',
                  ],
                },
                {
                  question: '나레이션도 커버가 되나요?',
                  answers: [
                    '네 가능합니다. 평균적으로는 가창 커버보다 퀄리티가 낮을 수 있지만, 학습 재료의 품질에 따라 자연스러운 결과물을 확인하실 수 있습니다.',
                  ],
                },
                {
                  question: '제작기록 탭에서 다운로드가 안됩니다.',
                  answers: [
                    '네이버 앱이나 네이버 이메일 앱 등, 일반적인 인터넷 브라우저가 아닌 어플을 통해 로그인하신 경우에 문제가 될 수 있습니다.',
                    '기본 인터넷 앱, 크롬, 사파리, 파이어폭스 등 일반적인 인터넷 브라우저로 접속해주시면 해결되실 겁니다.',
                  ],
                },
              ],
            },
            {
              title: 'AI 커버 제작 팁',
              qnas: [
                {
                  question:
                    '음원의 품질은 AI커버에 특히 더 큰 영향을 끼칩니다. 유튜브 음원같은 경우 음원에 많은 손실이 있기 때문에, 멜론이나 벅스 등의 음원 사이트에서 정식 구매한 음원을 사용하시는 것을 가장 추천드립니다.',
                  answers: [],
                },
                {
                  question:
                    '오토튠같은 음향효과가 많거나, 코러스(백보컬)가 너무 센 음원들은 음성을 깨끗하게 분리해서 학습시키는데 어려움이 있을 수 있으므로 피하시는 것을 추천드립니다.',
                  answers: [],
                },
                {
                  question: '같은 이유로 여러명이 가창을 하거나 화음이 많은 곡은 사용하시기 어렵습니다.',
                  answers: [],
                },
              ],
            },
            {
              title: '결제 관련 질문',
              qnas: [
                {
                  question: '요금제를 업그레이드 하고 싶은데, 돈을 두배로 내게 되나요?',
                  answers: ['두 요금제의 차액만큼만 결제가 됩니다.'],
                },
                {
                  question: '이번 달만 사용하고 싶은데, 구독 취소는 어떻게하나요?',
                  answers: ['요금제 탭으로 가신 뒤, 변경하기를 누르시고 구독 취소를 눌러주시면 됩니다.'],
                },
                {
                  question: '학습하지 않은 모델의 갯수는 이월되나요?',
                  answers: ['학습하지 않은 모델 갯수는 다음달로 이월되지 않습니다.'],
                },
                {
                  question: '제작하지 않은 커버 갯수는 이월되나요?',
                  answers: ['제작하지 않은 커버 갯수 또한 다음달로 이월되지 않습니다.'],
                },
                {
                  question: '카드 결제가 안됩니다.',
                  answers: [
                    '현재는 해외결제 승인된 카드만 사용 가능합니다. 국내카드, 카카오페이, 토스페이 등으로도 결제하실 수 있도록 개발중입니다.',
                  ],
                },
                {
                  question: '환불 요청은 어떻게 하나요?',
                  answers: [
                    '환불 가능한 조건은 아래와 같습니다.',
                    '- 해당 달에 사용 기록이 없으셔야 합니다.',
                    '- 환불요청은 결제일로부터 3주(21일)내로 이루어져야 합니다.',
                    '- 요금제와 상관없이 3천원의 환불 수수료가 발생합니다.',
                    "- 구독 해지는 스스로 해주셔야 합니다. 요금제 탭으로 가신 뒤 아무 '변경하기' 버튼이나 눌러주신 뒤 구독 해지를 해주시면 됩니다",
                  ],
                },
              ],
            },
            {
              title: '기타 질문',
              qnas: [
                {
                  question: '회원탈퇴는 어떻게 하나요?',
                  answers: [
                    '디스코드로 오셔서 문의 남겨주시면 안내를 도와드리겠습니다.',
                    '직접 탈퇴도 하실 수 있도록 해당 기능은 현재 개발중입니다.',
                  ],
                },
                {
                  question: '디스코드는 뭔가요?',
                  answers: [
                    '많은 소리소리AI 유저님들께서 활동하고 계시는 커뮤니티 입니다. 유저님들께서 서로 다양한 정보교류와 공유가 가능하며, 작업물들에 대한 평가도 받아볼 수 있는 유익한 공간입니다.',
                  ],
                },
                {
                  question: '이번달에 사용량을 모두 사용했는데, 음성학습이나 MR제거를 추가로 할수있나요?',
                  answers: [
                    '요금제 페이지에 가셔서, 개별상품 탭을 누르시면 구매 가능하십니다.',
                    '일반적으론 추가 모델 제작 개별 상품을 구매하시는 것보다 상위 요금제를 사용하시는게 훨씬 경제적입니다.',
                  ],
                },
              ],
            },
          ],
        },
        contactTab: {
          title: '문의사항',
          placeholder: {
            type: '문의 종류',
            email: '이메일',
            text: '내용을 입력하세요',
            refund:
              "환불 가능한 조건은 다음과 같습니다.\n\n1. 해당 달에 사용 기록이 없으셔야 합니다.\n2. 환불 요청은 결제일로부터 3주(21일)내로 이루어져야 합니다.\n3. 요금제와 상관없이 3천원의 수수료가 발생합니다. \n4. 구독 해지는 스스로 해주셔야 합니다. 요금제 탭으로 가신 뒤 아무 '변경하기' 버튼이나 눌러주신 뒤 구독 해지를 해주시면 됩니다",
          },
          message: {
            type: '문의 종류를 선택해주세요',
            email: '이메일 형식이 올바르지 않습니다',
            content: '내용이 입력되지 않았습니다',
            loading: '정보를 전송중입니다. 잠시만 기다려주세요',
            success: '등록이 완료되었습니다',
            fail: '전송에 실패했습니다. 다시 시도해주세요',
            useDiscord: '학습 및 제작 관련 질문은 자주묻는질문 탭과 디스코드 커뮤니티를 활용해주시면 감사하겠습니다',
          },
          contactType: ['학습/제작 기능 관련 문의', 'API 문의', '환불 문의', '기타 비즈니스 문의'],
          submit: '등록하기',
        },
        emailPreferenceTab: {
          title: '이메일 설정',
          header: '특별한 오퍼와 새로운 기능들, 그리고 중요한 소식들을 받아보세요',
          choiceLabels: {
            announcement: '공지사항',
            notification: '알림',
            newsletter: '뉴스레터',
            promotion: '프로모션',
          },
          submit: {
            save: '저장하기',
          },
          modal: {
            success: '등록이 완료되었습니다',
            error: '문제가 발생하였습니다. 다시 시도 해주세요.',
          },
        },
        profileTab: {
          title: '내 프로필',
          sections: {
            login: {
              title: '로그인 정보',
              logout: '로그아웃',
            },
            payment: {
              title: '구독 정보',
              changePayment: '결제 수단 변경',
              updateSubscription: '요금제 변경',
              cancelSubscriptionChange: '구독 변경 취소',
              history: '결제 기록 보기',
              cancel: '구독 취소',
              currentSubscription: '현재 요금제',
              nextPaymentDate: '다음 결제일',
              currentCycle: '현재 주기',
              nextSubscriptionType: '다음 요금제',
            },
            question: {
              title: '문의 사항',
              contentPre: '결제 및 환불 관련 기타 질문은',
              contentLink: '문의사항',
              contentPost: '탭을 이용해 주시면 감사하겠습니다',
            },
          },
          subscriptionType: {
            FREE: '무료',
            BASIC: '기본',
            PRO: '프로',
            PREMIUM: '프리미엄',
          },
        },
        paymentModal: {
          title: '결제 기록',
          date: '날짜',
          info: '정보',
          amount: '금액',
        },
      },
    },
    th: {
      translation: {
        languageDetector: 'th',
        requestFailed: 'คำขอไม่สำเร็จ โปรดลองอีกครั้ง',
        pageLoadFailed: 'ไม่สามารถโหลดได้ โปรดรีเฟรชหน้าเว็บนี้',
        signIn: 'เข้าสู่ระบบ',
        yes: 'ใช่',
        no: 'ไม่',
        defaultModels: ['เสียงตัวอย่าง (ชาย)'],
        sectionProduction: ['Cover เพลง AI'],
        trainingTab: {
          title: 'สร้างโมเดลเสียง AI',
          tabs: ['สร้างโมเดลใหม่', 'โมเดลพร้อมใช้'],
          stepOne: {
            title: 'ขั้นตอนที่ 1: อัปโหลดเสียงของคุณ',
            tooltip: {
              titleOne: 'คำแนะนำ',
              contentsOne: [
                'คุณภาพสำคัญกว่าความยาว เสียงที่คุณภาพดี 15 นาที จะใช้ได้ดีกว่าเสียงที่มีคุณภาพต่ำยาว 30 นาที',
                'ไม่ควรใช้ เสียงที่ใส่เอฟเฟกต์มากเกินไป เช่น ออโต้จูน',
                'เสียงที่อัดในสตูดิโอ จะใช้ได้ดีกว่าเสียงที่อัดจากการแสดงสด',
                'โปรดใช้เพลงร้องเดี่ยว ไม่ใช้เพลงที่ร้องคู่',
                'ใช้เสียงร้องเพลงมากกว่าเสียงสนทนา',
                'ถ้าคุณอยากสร้างเพลงแร็พ ให้อัพโหลดเสียงของคุณตอนที่คุณกำลังแร็พอยู่',
              ],
              titleTwo: 'กรณีใช้เสียงตัวเอง',
              contentsTwo: [
                'สามารถใช้มือถืออัดเสียงตัวเองได้',
                'สามารถอัพโหลดคลิปเสียงหลายๆคลิปได้ ไม่จำเป็นต้องเป็นคลิปยาวคลิปเดียว',
                'แนะนำให้อัดเสียงในสภาพแวดล้อมที่เงียบสนิท',
                'อย่าบันทึกเสียงในห้องคาราโอเกะ',
                'กรุณาตรวจสอบว่าการบันทึกสำเร็จหรือไม่ก่อนที่จะอัปโหลด',
              ],
            },
            trainingSubTitles: ['อัปโหลดไฟล์เสียง'],
            externalSubTitles: ['อัปโหลดโมเดลพร้อมใช้'],
            dragAndDropText: 'ลากและวางไฟล์',
            dragAndDropDuration: '(ความยาวเสียงของไฟล์ทั้งหมดรวมกัน {{minDuration}}-{{maxDuration}} นาที)',
            dragAndDropButton: 'เลือกไฟล์',
            durationText: 'ความยาวเสียงทั้งหมด',
            durationUnit: 'นาที',
            tabs: {
              fileUpload: {
                vocalExtractionText: 'ไฟล์นี้เป็นไฟล์ที่แยกเฉพาะเสียงร้องใช่หรือไม่?',
                vocalExtractionYes: 'ใช่',
                vocalExtractionNo: 'ไม่ใช่',
                vocalExtractionTooltip: {
                  title: 'อะไรคือการแยกเสียงร้องออกจากเพลง?',
                  contents: [
                    'คลิก “ใช่” เฉพาะในกรณีที่ไฟล์ที่กำลังจะอัพโหลด ได้ถูกแยกเสียงร้องออกมาแล้วเท่านั้น',
                    'ไม่ต้องใช้ในกรณีส่วนใหญ่',
                  ],
                },
              },
            },
          },
          stepTwo: {
            title: 'ขั้นตอนที่ 2: ตั้งชื่อ',
            subTitles: ['กรุณาตั้งชื่อเสียง AI และเทรนโมเดล'],
            placeholder: 'Give a label to your model',
          },
          submit: {
            upload: 'อัพโหลด',
            free: 'กรุณาสมัครสมาชิก',
            inProgress: 'อยู่ระหว่างดำเนินการ',
            noRemaining: 'คุณไม่มีเครดิตเหลือแล้ว',
            start: 'เริ่มเทรน',
            uploading: 'กำลังอัพโหลด',
            remainingCountText: 'จำนวนเครดิตที่เหลือในเดือนนี้',
            tooltip: {
              contents: [
                'การเทรนนิ่งจะใช้เวลาประมาณ 0.5-1 ชม',
                'อาจใช้เวลานานกว่านี้หากมีผู้ใช้จำนวนมาก',
                'คุณสามารถตรวจสอบสถานะได้ในแท็บ “ประวัติ”',
              ],
            },
          },
          modal: {
            exceedModelCount:
              'จำนวนเสียง AI ที่สามารถเก็บไว้ในระบบได้เต็มแล้ว โปรดไปที่แท็บการผลิตและลบเสียงที่มีอยู่ของคุณออก หรือเปลี่ยนไปใช้แพลนที่สูงขึ้น',
            exceedMaxDuration: 'คุณไม่สามารถอัปโหลดคลิปเสียงที่นานกว่า {{maxDuration}} นาทีได้',
            minDuration: 'กรุณาอัปโหลดคลิปเสียงที่มีความยาวอย่างน้อย {{minDuration}} นาที',
            notSupportedFileType: 'ไม่รองรับไฟล์ประเภทนี้',
            imgModalConfirm: 'เริ่ม',
            imgModalCancel: 'กลับไป',
            noLabel: 'กรุณาตั้งชื่อ',
            missingFile: 'โปรดส่งไฟล์ทั้งหมด',
            noFile: 'Please upload files',
            longLabel: 'Label cannot exceed 25 characters',
          },
        },
        communityUploadTab: {
          title: 'อัพโหลดที่คอมมิวนิตี้',
          tabs: [' แชร์ ', 'โมเดลจากที่อื่น'],
          stepOne: {
            tabs: {
              fileUpload: {
                dragAndDropText: 'ลากและวางไฟล์ที่นี่',
              },
            },
            title: 'ขั้นตอนที่ 1: อัพโหลดรูป',
            subTitles: ['อัพโหลดรูปโปรไฟล์ที่นี่'],
          },
          stepTwo: {
            title: 'ขั้นตอนที่ 2: เลือกโมเดลเสียง',
            trainingSubTitles: ['เลือกโมเดลเสียงที่คุณอยากแชร์'],
            externalSubTitles: ['โปรดอัพโหลดโมเดลเสียงที่คุณอยากแชร์'],
            dragAndDropText: ['อัพโหลดไฟล์ Path', 'อัพโหลดไฟล์ Index'],
            dragAndDropButton: 'เลือกไฟล์',
            voice: 'เสียง',
          },
          stepThree: {
            title: 'ขั้นตอนที่ 3: ตั้งชื่อให้โมเดล',
            subTitle: 'โปรดตั้งชื่อให้โมเดล',
            genderLabel: 'โปรดเลือกเพศของโทนเสียงโมเดลที่คุณอยากแชร์',
            gender: ['ชาย', 'หญิง'],
            anonymousText: 'ซ่อนชื่อโปรไฟล์ของฉัน',
          },
          stepFour: {
            title: 'ขั้นตอนที่ 4: เลือก Tag',
            subTitle: 'โปรดเลือก Tag ประเภทที่เหมาะสมกับโมเดลเสียงของคุณ',
            tags: ['ประเภท', 'ประเทศ'],
          },
          submit: {
            upload: 'แชร์',
            start: 'แชร์',
            uploading: 'กำลังอัพโหลด โปรดรอสักครู่',
          },
          modal: {
            noImage: 'Please upload an image',
            noModel: 'Please select a voice model to share',
            noTag: 'Please select at least one tag for each field',
            noLabel: 'Please give a label',
            longLabel: 'Label cannot exceed 25 characters',
          },
        },
        inferenceTab: {
          title: 'สร้าง Cover เพลง AI',
          stepOne: {
            tooltip: {
              title: 'คำถามที่พบบ่อยในการสร้าง Cover เพลง AI',
              contents: [
                'คุณภาพเสียงของเสียงต้นแบบมีความสำคัญมากต่อผลลัพธ์',
                'เสียงที่อัดในสตูดิโอ จะใช้ได้ดีกว่าเสียงที่อัดจากการแสดงสดง',
                'ไม่ควรใช้ เสียงที่ใส่เอฟเฟกต์มากเกินไป เช่น ออโต้จูน',
                'ไม่แนะนำสำหรับเพลงที่มีเสียงคอรัสเยอะๆ',
              ],
            },
            title: 'ขั้นตอนที่ 1: อัปโหลดเพลง',
            subTitles: ['โปรดอัปโหลดเพลงที่คุณต้องการ Cover'],
            tabs: {
              fileUpload: {
                title: 'อัปโหลดไฟล์',
                dragAndDropText: 'ลากและวางไฟล์',
                dragAndDropButton: 'เลือกไฟล์',
                dragAndDropDuration: '(รองรับไฟล์ได้สูงสุด 75 MB)',
                vocalExtractionText: 'ไฟล์นี้เป็นไฟล์ที่แยกเฉพาะเสียงร้องใช่หรือไม่?',
                vocalExtractionYes: 'ใช่',
                vocalExtractionNo: 'ไม่ใช่',
                vocalExtractionTooltip: {
                  title: 'อะไรคือการแยกเสียงร้องออกจากเพลง?',
                  contents: [
                    'คลิก “ใช่” เฉพาะในกรณีที่ไฟล์ที่กำลังจะอัพโหลด ได้ถูกแยกเสียงร้องออกมาแล้วเท่านั้น',
                    'ไม่ต้องใช้ในกรณีส่วนใหญ่',
                  ],
                },
              },
              youtube: {
                title: 'ลิงค์ยูทูป',
                linkPlaceholder: 'ไม่เกิน 10 นาทีเท่านั้น',
                linkbutton: 'ใช้',
                durationUnit: ['นาที', 'วินาที'],
              },
              recording: {
                title: 'การบันทึกเสียง',
                startButton: 'เริ่มการบันทึก',
                saveButton: 'บันทึก',
                recordingButton: 'การบันทึก',
                userRecording: 'เสียงบันทึกจากผู้ใช้งาน',
              },
            },
          },
          stepTwo: {
            title: 'ขั้นตอนที่ 2: เลือกเสียง AI',
            modelInput: {
              placeHolder: 'เลือก AI Voice Model',
              myModel: 'โมเดลของฉัน',
              defaultModel: 'โมเดลพื้นฐาน',
            },
            keyInput: {
              title: 'การปรับคีย์',
              tooltip: {
                contents: [
                  'เมื่อคัฟเวอร์เพลงผู้หญิงด้วยเสียงผู้ชาย แนะนำให้ใช้ -4 ถึง -6',
                  'เมื่อคัฟเวอร์เพลงผู้ชายด้วยเสียงผู้หญิง แนะนำให้ใช้ +4 ถึง +6',
                  'การปรับคีย์มากเกินไปอาจทำให้คุณภาพเสียงลดลง',
                ],
              },
            },
            dragAndDropText: 'คลิปที่นี่เพื่อเลือกโมเดลเสียง AI',
          },
          stepThree: {
            title: 'ขั้นตอนที่ 3: Configuration',
            subTitles: 'เลือกการตั้งค่ามิกเซอร์, รูปแบบเอาท์พุต, และตั้งชื่อ Cover ของคุณ',
            labelInput: {
              placeHolder: 'กรุณาตั้งชื่อ',
            },
            ouputFormatLabel: 'กรุณาเลือกประเภทไฟล์ในการออก Cover',
            tooltip: {
              outputFormatMessage: 'ไฟล์ประเภท WAV สำหรับแพลน PRO/PREMIUM เท่านั้น',
              upgradePrompt: 'คลิกที่นี่เพื่ออัพเกรด',
              subscribePrompt: 'คลิกที่นี่เพื่อสมัครสมาชิก',
              title: 'สิ่งนี้หมายความว่า?',
              contents: ["ถ้าไม่มีการตั้งค่า จะตั้งต้นที่ 'เสียง - ที่มา'", 'สามารถเปลี่ยนได้ทีหลังที่ "ประวัติ'],
            },
          },
          submit: {
            completedAndLoadingPlayer:
              'สร้างสำเร็จเรียบร้อย กำลังโหลดเครื่องเล่นเสียง หรือผู้ใช้งานสามารถดาวน์โหลดเพลงได้ในแท็บ ‘ประวัติ’',
            alreadyInProgress: 'อยู่ระหว่างดำเนินการ',
            noRemaining: 'เครดิตของเดือนนี้หมดแล้ว',
            start: 'เริ่มการแปลงเสียง',
            uploading: 'กำลังอัพโหลด กรุณารอสักครู่',
            tryAgain: 'กรุณาลองอีกครั้ง.',
            inProgress:
              'กำลังอยู่ระหว่างดำเนินการ - คุณสามารถที่จะทิ้งหน้านี้ไว้ หรือ ปิดหน้านี้ลง และ ตรวจสอบสถานะได้ในแท็บ “ประวัติ”',
            remainingCountText: 'จำนวนครั้งที่เหลือในเดือนนี้',
            remainingCountUnit: 'เพลง',
            tooltip: {
              contents: [
                'การสร้าง Cover เพลง AI ใช้เวลาประมาณ 1-4 นาที',
                'อาจใช้เวลานานขึ้นเล็กน้อยหากมีผู้ใช้จำนวนมาก',
                'คุณสามารถตรวจสอบสถานะได้ในแท็บ “ประวัติ”',
              ],
            },
          },
          modal: {
            watermark:
              'เวอร์ชั่นฟรีจะมาพร้อมกับลายน้ำในไฟล์เสียง ผู้ใช้งานสามารถสมัครสมาชิกเพื่อรับไฟล์เสียงที่ไม่มีลายน้ำได้',
            exceedModelCount:
              'Basic Plan จัดให้คุณเก็บ voice model ได้สูงสุด 3 โมเดลเท่านั้น หากต้องการดำเนินการต่อ โปรดลบโมเดลที่มีอยู่',
            exceedMaxSize: 'คุณสามารถอัปโหลดไฟล์ได้สูงสุด 75MB เท่านั้น',
            tooManyFiles: 'สามารถอัพโหลดได้เพียง 1 ไฟล์เท่านั้น',
            notSupportedFileType: 'ไม่รองรับรูปแบบไฟล์นี้',
            inProgress: 'กำลังดำเนินการ',
            noRemaining: 'คุณได้ใช้เครดิตที่มีอยู่หมดแล้ว',
            emptyYoutubeLink: 'อัพโหลดเพลงที่คุณต้องการคัฟเวอร์',
            noModalSelected: 'โปรดเลือกโมเดล',
            noLabel: 'กรุณาตั้งชื่อ',
            invalidYoutubeLink: 'ลิงก์ YouTube ไม่ถูกต้อง',
            youtubeLinkTooLong: 'คุณไม่สามารถใช้ลิงค์ของ YouTube ที่นานกว่า 10 นาทีได้',
            longLabel: 'Label cannot exceed 100 characters',
          },
        },
        ttsInferenceTab: {
          title: 'AI แปลงข้อความเป็นเสียงพูด',
          stepOne: {
            modelInput: {
              placeHolder: 'เลือกโมเดลเสียง AI',
              myModel: 'โมเดลของฉัน',
            },
            ttsTextAreaPlaceholder: 'พิมพ์ข้อความที่จะแปลงเป็นเสียงพูดที่นี่',
            dragAndDropText: 'คลิกเพื่อหาและเลือกโมเดลเสียง',
          },
          submit: {
            completedAndLoadingPlayer:
              "แปลงข้อความเป็นเสียงเรียบร้อย กำลังโหลดเครื่องเล่นเสียง หรือผู้ใช้งานสามารถเข้าไปดาวน์โหลดไฟล์ได้ที่ 'เสียงพูด AI' ใน แท็บด์ 'ประวัติ TTS'",
            alreadyInProgress: 'กำลังดำเนินการ',
            noRemaining: 'ไม่มีเครดิตเหลือสำหรับเดือนนี้',
            start: 'เริ่ม',
            uploading: 'กำลังอัพโหลด - โปรดรอสักครู่',
            tryAgain: 'โปรดลองอีกครั้ง',
            inProgress:
              "กำลังแปลงข้อความเป็นเสียง -  คุณสามารถที่จะทิ้งหน้านี้ไว้ หรือ ปิดหน้านี้ลง และ ตรวจสอบสถานะได้ใน 'เสียงพูด AI' ใน แท็บด์ 'ประวัติ TTS'",
            remainingCountText: '# จำนวนเครดิตที่เหลือในเดือนนี้สำหรับแปลงข้อความเป็นเสียง',
            remainingCountUnit: 'ตัวอักษร',
            remainingMonthly: 'เครดิตคงเหลือ',
            setting: 'การตั้งค่า',
          },
          modal: {
            selectModalTitle: 'โมเดลเสียง AI',
            addNewModelText: 'คลิกเพื่อสร้างโมเดลเสียงใหม่',
            exceedTextSize: 'สามารถแปลงได้ไม่เกิน {{characterLimitPerInference}} ตัวอักษรในเวลาเดียวกัน',
            inProgress: 'อยู่ในระหว่างการแปลงเสียง',
            noRemaining: 'ไม่มีเครดิตเหลือสำหรับเดือนนี้',
            noModalSelected: 'โปรดเลือกโมเดลเสียง',
            noText: 'โปรดใส่ข้อความ',
            variability: 'ความเสถียรของเสียง',
            stable: 'เสถียร',
            variable: 'ความขึ้นลงของโทนเสียง',
            similarity: 'ความเหมือน',
            low: 'ต่ำ',
            high: 'สูง',
            longLabel: 'Label cannot exceed 50 characters',
          },
        },
        ttsTrainingTab: {
          title: 'เทรนนิ่งเสียงสำหรับแปลงข้อความเป็นเสียง AI',
          stepOne: {
            tooltip: {
              contentsOne: [
                'เน้นคุณภาพมากกว่าปริมาณ ใช้เสียงตัวอย่างคุณภาพดีความยาว 5 นาทีนั้นดีกว่าใช้เสียงคุณภาพไม่ดีความยาว 10 นาที',
                'โปรดใช้เสียงตัวอย่างที่ชัดเจน ไม่มีเสียงอื่นๆ รบกวน',
                'โปรดเลือกใช้เสียงตัวอย่างจากการพูดคุยแทนเสียงร้อง',
                'แต่ละไฟล์ควรมีขนาดไม่เกิน 10mb',
                'สามารถอัพโหลดได้สูงสุด 10 ไฟล์',
              ],
            },
            sizeLimitText: '(แต่ละไฟล์ควรมีขนาดไม่เกิน 10mb)',
            numberLimitText: '(สูงสุด 10 ไฟล์)',
            dragAndDropDuration: '(ความยาวเสียงของไฟล์ทั้งหมดรวมกัน {{minDuration}}วินาที-{{maxDuration}}นาที)',
            second: 'วินาที',
          },
          modal: {
            sizeTooLarge: 'แต่ละไฟล์ควรมีขนาดไม่เกิน 10mb',
            tooManyFiles: 'สามารถอัพโหลดได้สูงสุดไม่เกิน 10 ไฟล์',
            exceedMaxDuration: 'คุณไม่สามารถอัปโหลดคลิปเสียงที่นานกว่า {{maxDuration}} นาทีได้',
            minDuration: 'กรุณาอัปโหลดคลิปเสียงที่มีความยาวอย่างน้อย {{minDuration}} วินาที',
            duplicateFiles: 'ไม่สามารถอัปโหลดไฟล์ที่ซ้ำกันได้',
          },
          submit: {
            tooltip: {
              contents: [
                'การเทรนโมเดลเสียงใช้เวลา 0.5-10 นาทีโดยประมาณ',
                'ระยะเวลาการเทรนโมเดลอาจขึ้นกับแพ็กเกจของสมาชิก',
                "คุณสามารถเข้าไปดูสถานะได้ที่'เสียง AI' ใน แท็บด์ 'ประวัติ TTS'",
              ],
            },
          },
        },
        ttsAppBar: {
          sectionTTS: 'เสียงพูด AI',
          subSectionTTS: ['แปลงข้อความเป็นเสียงพูด (TTS)', 'เทรนเสียงพูด TTS', 'ประวัติ TTS'],
        },
        ttsHistoryTab: {
          title: 'ประวัติ TTS',
          tabs: {
            inference: {
              title: 'เสียงพูด AI',
            },
            training: {
              title: 'โมเดลเสียง AI',
            },
          },
          infoIcon: {
            voice: 'เสียง',
            variability: 'ความเสถียรของเสียง',
            similarity: 'ความเหมือนของเสียง',
          },
        },
        videoEditorTab: {
          title: 'Video Editor',
          addSource: 'Source',
        },
        addSourceModal: {
          title: 'Add a source',
          sourceOptions: [
            'AI Cover',
            'TTS',
            'Youtube',
            'Upload',
          ],
          searchbar: 'Search',
          pagination: {
            loader: 'Loading...',
          },
          voice: 'AI Voice',
          youtube: {
            placeholder: 'Max 10 minutes',
            button: 'Search',
            invalidYoutubeLink: 'This is an incorrect YouTube link',
            youtubeLinkTooLong: 'The YouTube video may not exceed 10 minutes',
          },
          upload: {
            dragAndDropText: 'Drag and drop a video/audio/image file',
            sizeLimitText: '(Max 75 MB)',
            acceptedAudio: '.mp3, .wav, .flac, and .ogg',
            acceptedVideo: '.mp4 and .mkv',
            acceptedImage: '.jpeg, .png, and .webp',
            button: 'Select file',
            notSupportedFileType: 'This file format is not supported',
            tooManyFiles: 'Please upload one file at a time',
            exceedMaxSize: 'You can only upload files up to 75MB',
          },
          loading: [
            'Uploading file',
            '(may take up to a minute for larger files)',
          ],
        },
        textEditorModal: {
          title: [
            'Add text',
            'Edit text',
          ],
          placeholder: 'Type your text here...',
          add: 'Add',
          save: 'Save',
        },
        videoExportModal: {
          title: 'Export video',
          placeholder: 'Give your exported video a label',
          watermark: 'Show watermark',
          start: 'Start',
          rendering: [
            'Please stay on the page',
            'Exporting your video (may take up to a minute)',
          ],
          success: [
            'Video is ready',
            'Your download will start automatically. If not, click the button below.',
          ],
          tooltip: {
            contents: [
              'Export time will depend on the length of your video',
              'You will be taken to the History tab where you can check the progress',
            ]
          },
          videoRenderError: 'Could not render the video. Please try again',
        },
        // vocalExtractorTab
        vocalExtractorTab: {
          title: 'ตัวแยกเสียง',
          stepOne: {
            title: 'ขั้นตอนที่ 1: อัพโหลดไฟล์เสียง',
            tabs: {
              fileUpload: {
                title: 'ไฟล์เสียง',
                dragAndDropText: 'ลากและวางไฟล์ที่นี่',
                dragAndDropButton: 'เลือกไฟล์',
                dragAndDropDuration: '(สูงสุด 75 MB)',
              },
              youtube: {
                title: 'Youtube',
                linkPlaceholder: 'สูงสุด 10 นาที',
                linkbutton: 'ค้นหา',
                durationUnit: ['นาที', 'วินาที'],
              },
            },
            vocalExtractorSubTitles: ['อัพโหลดไฟล์เพื่อทำการคัดแยกเสียง'],
            tooltip: {
              contents: [
                'แผน Premium สามารถอัพโหลดไฟล์ได้สูงสุด 10 ไฟล์ต่อครั้ง',
                'แผน Pro สามารถอัพโหลดไฟล์ได้สูงสุด 5 ไฟล์ต่อครั้ง',
                'แผน ฟรีและ Basic สามารถอัพโหลดไฟล์ได้ 1 ไฟล์ต่อครั้ง',
                'ความยาวของไฟล์เสียงทั้งหมดรวมกันต้องไม่เกิน 60 นาที',
              ],
            },
            maxUploadNumText: {
              premiumCustomAdmin: 'อัพโหลดสูงสุด 10 ไฟล์',
              pro: 'อัพโหลดสูงสุด 5 ไฟล์',
              basicFree: 'อัพโหลดสูงสุด 1 ไฟล์',
            },
          },
          stepTwo: {
            title: 'ขั้นตอนที่ 2: กำหนดค่า',
            subTitles: 'เลือกประเภทเอาท์พุตและตั้งชื่อไฟล์แยกเสียงนี้',
            labelInput: {
              placeHolder: 'ตั้งชื่อไฟล์แยกเสียง',
            },
            tooltip: {
              outputFormatMessage: 'สามารถโหลดไฟล์ประเภท WAV ได้ในแผน PRO/PREMIUM เท่านั้น',
              upgradePrompt: 'อัพเกรดที่นี่',
              subscribePrompt: 'สมัครที่นี่',
            },
          },
          submit: {
            alreadyInProgress: 'กำลังดำเนินการ',
            upload: 'อัพโหลด',
            inProgress:
              'กำลังดำเนินการ - ผู้ใช้งานสามารถรอหรือออกจากหน้านี้ และสามารถเข้าไปดาวน์โหลดไฟล์ได้ที่หน้า “ประวัติ” ที่เมนูด้านข้าง',
            noRemaining: 'โปรดสมัครสมาชิกเพื่อใช้งานตัวแยกเสียงเพิ่มเติม',
            start: 'เริ่ม',
            uploading: 'กำลังอัพโหลด โปรดรอซักครู่',
            tryAgain: 'กรุณาลองอีกครั้ง',
            remainingCountText: '# เครดิตคงเหลือ สำหรับตัวแยกเสียง',
            remainingCountUnit: '',
          },
          modal: {
            notSupportedFileType: 'ไม่สามารถรองรับไฟล์ประเภทนี้ได้',
            invalidYoutubeLink: 'ลิงก์ Youtube นี้ไม่ถูกต้อง',
            youtubeLinkTooLong: 'วีดีโอจาก Youtube ความยาวต้องไม่เกิน 10 นาที',
            noRemaining: 'โปรดสมัครสมาชิกเพื่อรับเครดิตการเทรนโมเดลเพิ่มเติม',
            emptyYoutubeLink: 'โปรดอัพโหลดเพลงที่ต้องการใช้ตัวถอดเสียง',
            inProgress: 'อยู่ระหว่างดำเนินการถอดเสียง',
            exceedMaxSize: 'สามารถอัพโหลดไฟล์ได้ถึง 75MB',
            exceedMaxDuration: 'ความยาวของเสียงทั้งหมดรวมกันต้องไม่เกิน 60 นาที',
            premiumCustomAdmin: 'Premium/Custom',
            pro: 'Pro',
            filePlural: ' ไฟล์',
            tooManyFiles: ['ผู้ใช้งานสามารถอัพโหลด', 'ไฟล์ต่อครั้ง'],
            noFiles: 'โปรดอัพโหลดไฟล์เพื่อแยกเสียง',
            lackRemaining: 'โปรดสมัครสมาชิกเพื่อใช้ตัวแยกเสียงเพิ่มเติม',
            uploadStatusNotValid: 'ไม่มีเครดิตหลงเหลือ หรือ กำลังรอการดำเนินการ',
            tryAgain: 'โปรดลองอีกครั้ง:',
            noLabel: 'Please give a label to your vocal extract',
            longLabel: 'Label cannot exceed 50 characters',
          },
        },
        historyTab: {
          title: 'ประวัติ',
          inProgress: 'อยู่ระหว่างดำเนินการ',
          failed: 'Failed',
          freeVersionTag: 'ทดลองใช้ฟรี',
          freePrefix: 'เวอร์ชั่นฟรีจะมาพร้อมลายน้ำ SorisoriAI ในไฟล์เสียง',
          tooltip: {
            contents: [
              'การสร้างไฟล์เสียง ใช้เวลาโดยเฉลี่ย 2-4 นาที',
              'การเทรนโมเดลเสียงใช้เวลาโดยเฉลี่ย 0.5-1 ชั่วโมง',
              'อาจใช้เวลานานขึ้นหากมีผู้ใช้งานจำนวนมาก',
            ],
          },
          tabs: {
            inference: {
              title: 'AI คัฟเวอร์',
            },
            training: {
              title: 'เทรนนิ่งเสียง AI',
            },
            vocalExtract: {
              title: 'ตัวแยกเสียง',
            },
            community: {
              title: 'คอมมิวนิตี้',
            },
          },
          changeButton: 'เปลี่ยน',
          cancelButton: 'ยกเลิก',
          confirmModelDelete:
            'คุณแน่ใจว่าต้องการลบหรือไม่? การลบโมเดลจะไม่ช่วยให้คุณได้เครดิตในการสร้างโมเดลที่ได้ใช้ไปแล้วคืนมาหรือเพิ่มขึ้น',
          confirmInferenceDelete: 'คุณแน่ใจหรือว่าต้องการลบ?',
          confirmVocalExtractDelete: 'คุณแน่ใจหรือว่าต้องการลบ?',
          searchbar: 'ค้นหา',
          infoIcon: {
            source: 'แหล่งที่มา',
            voice: 'เสียง',
            pitch: 'คีย์เสียง',
            mixing: {
              title: 'Mixer settings',
              vocalLevel: 'Vocal Level',
              reverbLevel: 'Reverb Level',
              reverbLength: 'Reverb Length',
            },
            errorMessage: 'ข้อผิดพลาด',
          },
          pagination: {
            loader: 'กำลังโหลด...',
          },
          modal: {
            error: 'โปรดรีเฟรชหน้านี้',
            label: 'ชื่อ',
            username: 'Username',
            image: 'ภาพ',
            filterError: 'ไม่พบฟิลเตอร์เสียง',
          },
          trainingInfo: {
            files: 'ไฟล์ที่ถูกใช้',
            extracted: 'เสียงร้องที่ถูกแยกแล้ว',
            shared: 'แชร์ในคอมมิวนิตี้แล้ว',
            yes: 'ใช่',
            no: 'ไม่',
            status: 'สถานะ',
            errorFile: 'ไฟล์ที่มีข้อผิดพลาด',
          },
          voice: 'เสียง AI',
        },
        subscriptionTab: {
          title: 'สมัครสมาชิก',
          pageTitleOne: 'SorisoriAI',
          popular: 'ยอดนิยม',
          cancel: {
            message: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกสมาชิก?',
            success: 'ยกเลิกสมาชิกเรียบร้อย!',
            fail: 'ไม่สามารถยกเลิกสมาชิกได้ โปรดลองอีกครั้ง',
          },
          cancelChange: {
            message: 'คุณแน่ใจหรือไม่ว่าต้องการคืนค่าการเปลี่ยนแปลงการสมัครสมาชิก?',
            success: 'สำเร็จ คืนค่าการเปลี่ยนแปลงเรียบร้อย!',
            fail: 'ไม่สามารถคืนค่าการเปลี่ยนแปลงได้ โปรดลองอีกครั้ง',
          },
          onetime: {
            message: 'คุณแน่ใจหรือไม่ว่าต้องการดำเนินการคำสั่งซื้อนี้?',
            success: 'สั่งซื้อสำเร็จ!',
            fail: 'ไม่สามารถสั่งซื้อได้ โปรดลองอีกครั้ง',
          },
          downgrade: {
            message: 'คุณแน่ใจหรือไม่ว่าต้องการลดแผนสมาชิก?',
            success: 'ลดแผนสมาชิกสำเร็จ!',
            fail: 'ไม่สามารถลดแผนสมาชิกได้ โปรดลองอีกครั้ง',
          },
          upgrade: {
            message:
              'คุณแน่ใจหรือไม่ว่าต้องการอัพเกรดแผนสมาชิก? เมื่อคลิก "ใช่" เราจะดำเนินการผ่านวิธีการชำระเงินปัจจุบันของคุณ',
            success: 'อัพเกรดแผนสมาชิกสำเร็จ!',
            fail: 'ไม่สามารถอัพเกรดได้ โปรดลองอีกครั้ง',
            paymentFailed: 'การชำระเงินล้มเหลว โปรดอัพเดตวิธีการชำระเงินที่หน้าโปรไฟล์ของคุณและลองอีกครั้ง',
          },
          mobilePaymentRequired:
            'ผู้ใช้งานสามารถจัดการแผนสมาชิกของคุณได้ในแอปพลิเคชั่นมือถือที่ใช้สมัครสมาชิกมาก่อนหน้านี้',
          tabs: {
            subscription: {
              title: 'สมัครสมาชิก',
              pageTitleTwo: 'สมัครสมาชิก',
              status: 'แพลนปัจจุบัน',
              pastDue: 'ชำระเงินล้มเหลว',
              month: 'เดือน',
              subscribe: 'สมัคร',
              change: 'เปลี่ยน',
            },
            onetime: {
              title: 'ซื้อครั้งเดียว',
              pageTitleTwo: 'ซื้อครั้งเดียว',
              purchase: 'ซื้อ',
              history: 'ประวัติการสั่งซื้อ',
              invalidSubscriptionType: 'กรุณาสมัครสมาชิกก่อนซื้อ',
            },
          },
          paymentModal: {
            usePromoCode: 'ใช้โค้ดส่วนลด',
            promoCodePlaceholder: 'โค้ดส่วนลด',
            applyButton: 'ใช้โค้ด',
            promoCodeApplied: 'ใช้โค้ดส่วนลดแล้ว',
            invalidPromoCode: 'โค้ดส่วนลดไม่ถูกต้อง',
            genericError: 'ไม่สามารถทำตามคำขอได้ โปรดลองอีกครั้ง',
            promoCodeError: {
              doesNotExist: 'Invalid promo code. Please check the promo code',
              invalidSubscriptionType: 'This promo code is only valid for PRO and PREMIUM plans',
              onlyFirstTime: 'This promo code is valid for first-time users only'
            }
          },
        },
        communityTab: {
          title: 'คอมมิวนิตี้',
          subTitle: 'เลือกเสียงที่คุณต้องการแล้วสร้าง AI Cover ได้เลย!',
          inProgress: 'อยู่ระหว่างดำเนินการ',
          tags: 'ประเภท',
          failed: 'ไม่สำเร็จ',
          freeVersionTag: 'ทดลองใช้ฟรี',
          freePrefix: 'เวอร์ชั่นฟรีจะมาพร้อมลายน้ำ SorisoriAI ในไฟล์เสียง',
          voiceOptions: ['คอมมิวนิตี้', 'เสียงของฉัน', 'Favorites'],
          sortOptions: ['ล่าสุด', 'likes', 'ครั้งที่ใช้', 'ชื่อ'],
          tooltip: {
            contents: [
              'การสร้างไฟล์เสียง ใช้เวลาโดยเฉลี่ย 2-4 นาที',
              'การเทรนโมเดลเสียงใช้เวลาโดยเฉลี่ย 2-4 ชั่วโมง',
              'อาจใช้เวลานานขึ้นหากมีผู้ใช้งานจำนวนมาก',
            ],
          },
          tabs: {
            inference: {
              title: 'AI คัฟเวอร์',
            },
            training: {
              title: 'เทรนนิ่งเสียง AI',
            },
            vocalExtract: {
              title: 'ตัวแยกเสียง',
            },
          },
          changeButton: 'เปลี่ยน',
          cancelButton: 'ยกเลิก',
          confirmModelDelete:
            'คุณแน่ใจว่าต้องการลบหรือไม่? การลบโมเดลจะไม่ช่วยให้คุณได้เครดิตในการสร้างโมเดลที่ได้ใช้ไปแล้วคืนมาหรือเพิ่มขึ้น',
          confirmInferenceDelete: 'คุณแน่ใจหรือว่าต้องการลบ?',
          confirmVocalExtractDelete: 'คุณแน่ใจหรือว่าต้องการลบ?',
          searchbar: 'ค้นหา',
          infoIcon: {
            source: 'แหล่งที่มา',
            voice: 'เสียง',
            pitch: 'คีย์',
          },
          pagination: {
            loader: 'กำลังโหลด...',
          },
          modal: {
            error: 'โปรดรีเฟรชหน้านี้',
          },
          button: {
            share: 'แชร์',
          },
        },
        supportTab: {
          title: 'คอมมิวนิตี้',
          link: 'https://discord.gg/AVREYrRZv4',
        },
        pipeline: {
          inferenceStatusMessage: {
            formatError: 'ไม่สามารถเปิดไฟล์ได้เนื่องจากฟอร์แมทไฟล์หรือนามสกุลไฟล์ไม่ถูกต้อง',
            volumeError:
              'ระดับเสียงร้องในไฟล์อาจเบาเกินไป หรือไม่พบเสียงร้องในไฟล์เสียงนี้ โปรดเพิ่มความดังให้เสียงร้องและลองอีกครั้ง',
            invalidFileError: 'ไม่สามารถเปิดไฟล์ได้ โปรดตรวจสอบไฟล์อีกครั้ง',
            progress10: 'อยู่ระหว่างดำเนินการ 10%',
            progress20: 'อยู่ระหว่างดำเนินการ 20%',
            progress40: 'อยู่ระหว่างดำเนินการ 40%',
            progress50: 'อยู่ระหว่างดำเนินการ 50%',
            progress60: 'อยู่ระหว่างดำเนินการ 60%',
            progress80: 'อยู่ระหว่างดำเนินการ 80%',
            progress100: 'อยู่ระหว่างดำเนินการ 100%',
            complete: 'เสร็จสิ้น',
            generalMessage: 'AI Cover ล้มเหลว โปรดลองอีกครั้ง หากปัญหายังคงมีอยู่ โปรดติดต่อทีมสนับสนุนของ SORISORI',
            starting: 'กำลังเริ่มต้น',
          },
          trainingStatusMessage: {
            formatError: 'ไม่สามารถเปิดไฟล์ได้เนื่องจากฟอร์แมทไฟล์หรือนามสกุลไฟล์ไม่ถูกต้อง',
            volumeError:
              'ระดับเสียงร้องในไฟล์อาจเบาเกินไป หรือไม่พบเสียงร้องในไฟล์เสียงนี้ โปรดเพิ่มความดังให้เสียงร้องและลองอีกครั้ง',
            invalidFileError: 'ไม่สามารถเปิดไฟล์ได้ โปรดตรวจสอบไฟล์อีกครั้ง',
            progress10: 'อยู่ระหว่างดำเนินการ 10%',
            progress20: 'อยู่ระหว่างดำเนินการ 20%',
            progress40: 'อยู่ระหว่างดำเนินการ 40%',
            progress50: 'อยู่ระหว่างดำเนินการ 50%',
            progress60: 'อยู่ระหว่างดำเนินการ 60%',
            progress80: 'อยู่ระหว่างดำเนินการ 80%',
            progress100: 'อยู่ระหว่างดำเนินการ 100%',
            complete: 'เสร็จสิ้น',
            generalMessage: 'การฝึกอบรมล้มเหลว โปรดลองอีกครั้ง หากปัญหายังคงมีอยู่ โปรดติดต่อทีมสนับสนุนของ SORISORI',
            starting: 'กำลังเริ่มต้น',
          },
        },
        modal: {
          confirmationYes: 'ใช่',
          confirmationNo: 'ไม่ใช่',
          combined: 'รวมกัน',
          backVocal: 'เสียงคอรัส',
          vocal: 'เสียงร้องหลัก',
          instrument: 'ดนตรีประกอบ',
          fileUploadFail: 'ไม่สามารถอัพโหลดไฟล์ได้ในขณะนี้ กรุณาตรวจสอบไฟล์และลองใหม่อีกครั้ง',
          trainingInProgressError: 'มีการเทรนนิ่งอยู่ในขณะนี้',
          genericError: 'ไม่สามารถดำเนินการตามคำขอได้ กรุณาลองอีกครั้ง',
          noRemainingError: 'คุณไม่มีเครดิตเหลือแล้ว',
          pageLoadFail: 'ไม่สามารถโหลดหน้าเว็บได้ กรุณารีเฟรชหน้านี้',
          pageLoadFailOnSubmit:
            'การอัพโหลดสำเร็จ แต่ไม่สามารถโหลดหน้าเว็บได้ในขณะนี้ กรุณารีเฟรชหน้านี้ หรือสามารถตรวจสอบสถานะของไฟล์ได้ที่แท็บ ‘ประวัติ’ ที่เมนูด้านข้าง',
          gotoProfile: 'ไปหน้าโปรไฟล์ของฉัน',
        },
        mixingModal: {
          label: 'มิกซ์',
          title: 'การมิกซ์เสียง',
          sliders: {
            contents: ['คีย์เสียงร้อง', 'ความดังเสียงร้อง', 'ระดับเสียงสะท้อน', 'ความยาวของเสียงสะท้อน'],
          },
          tooltip: {
            contents: [
              '-4 ถึง -6 คือช่วงคีย์ที่แนะนำเมื่อใช้เสียงร้องเพศชายในเพลงคีย์ผู้หญิง',
              '+4 ถึง +6 คือช่วงคีย์ที่แนะนำเมื่อใช้เสียงร้องเพศหญิงในเพลงคีย์ผู้ชาย',
              'การปรับคีย์ที่มากเกินไปอาจส่งผลต่อคุณภาพของเสียงผลลัพท์',
              'ความดังของเสียงร้อง',
              '0db คือความดังระดับเดียวกับเสียงร้องต้นฉบับ',
              'ระดับของเอฟเฟกต์เสียงสะท้อน (Reverb)',
              '0 คือไม่มีเสียงสะท้อน, 6 คือเสียงสะท้อนระดับมาก',
              'ความยาวของหางเสียงสะท้อนในหน่วยวินาที',
              '2s คือความยาวที่แนะนำสำหรับเพลงช้าซึ้ง และแนะนำให้ความยาวเสียงสะท้อนสั้นลงสำหรับเพลงเร็ว',
            ],
          },
          demo: {
            toggle: 'มิกซ์ซิ่ง',
            status: ['ไม่มิกซ์', 'มิกซ์'],
            description: 'ฟังความแตกต่างระหว่างเสียงที่ยังไม่มิกซ์และที่มิกซ์แล้ว',
          },
          dialog: {
            title: 'ขออภัย คุณไม่สามารถเข้าถึงฟีเจอร์นี้ได้',
            text: 'อัพเกรดเป็นแผน PRO หรือ PREMIUM เพื่อเข้าถึงฟีเจอร์การมิกซ์เสียงของเรา!',
          },
          upgradeButton: ['อัพเกรดเป็น ', 'PRO'],
        },
        freeInferenceMessageModal: {
          messeageOne: ['เวอร์ชั่นฟรีมาพร้อมโฆษณาขั้น และเสียงทดลองความยาว 45 วินาที'],
          messeageTwo: ['โปรดสมัครสมาชิกเพื่อสร้าง', 'เพลงเต็มความยาวแบบไม่มีโฆษณา'],
          button: 'สมัครสมาชิก',
        },
        freeTrainingMessageModal: {
          messages: ['สามารถเทรนนิ่งโมเดลเสียง AI', 'เมื่อสมัครสมาชิก', 'หรือสั่งซื้อบริการแบบครั้งเดียว'],
          button: 'สมัครสมาชิก',
        },
        freeVocalExtractorMessageModal: {
          free: {
            message: ['ผู้ใช้งานฟรี', 'สามารถอัพโหลดได้ครั้งละ 1 ไฟล์', 'โปรดอัพเดรดเพื่อเพิ่มการอัพโหลด'],
          },
          basic: {
            message: ['ผู้ใช้งานแผน Basic', 'สามารถอัพโหลดได้ครั้งละ 1 ไฟล์', 'โปรดอัพเดรดเพื่อเพิ่มการอัพโหลด'],
          },
          button: 'คลิกที่นี่เพื่อสมัครสมาชิก',
        },
        onetimeItems: {
          training: {
            name: 'สร้างโมเดลเพิ่ม',
            price: {
              usd: '$7',
              thb: '฿250',
              krw: '₩8,500',
              jpy: '¥990',
            },
          },
          vocalExtract: {
            name: '100 ตัวแยกเสียงเพิ่มเติม',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
          inference: {
            tooltip: 'เพลงทั้งหมดสามารถผลิตได้โดยไม่ต้องโฆษณา',
            name: 'สร้าง Cover ได้ 5 เครดิต',
            price: {
              usd: '$4.99',
              thb: '฿75',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
          tts: {
            name: '3000 TTS Credits',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
        },
        subscriptionItems: [
          {
            name: 'FREE',
            model: 'FREE', //leave in english
            price: {
              usd: '$0',
              thb: '฿0',
              krw: '₩0',
              jpy: '¥0',
            },
            period: '',
            features: [
              {
                text: 'อัตราความเร็ว - ช้า',
              },
              {
                text: 'เครดิตการสร้าง AI คัฟเวอร์: 3 เพลง',
                tooltips: ['สร้าง AI คัฟเวอร์ได้ถึง 3 เพลงด้วยเสียงจากโมเดลที่เทรนเอาไว้'],
              },
              {
                text: 'ตัวแยกเสียงและตัวมิกซ์เสียง AI',
                tooltips: ['Sorisori มาพร้อมตัวมิกซ์เสียง AI เพื่อคุณภาพของเพลงคัพเวอร์ที่ดียิ่งขึ้น'],
              },
              {
                text: 'คุณภาพการเทรนนิ่งเสียง - ปกติ',
                tooltips: [
                  'อัพโหลดไฟล์เสียงความยาวสูงสุด 30 นาที ใช้เวลาการเทรนนิ่งโดยประมาณ 2 ชั่วโมง',
                  'อัพโหลดด้วยความเร็ว GPU ปกติ ผู้ใช้งานสามารถสมัครสมาชิกเพื่อเทรนนิ่งเสียงด้วยคุณภาพที่ดียิ่งขึ้น',
                ],
              },
              {
                text: 'คุณภาพเสียง: ปกติ (MP3)',
                tooltips: ['ให้ไฟล์ประเภท MP3', 'มีลายน้ำในไฟล์เสียง'],
              },
              {
                text: 'เครดิตตัวแยกเสียง: 3 เพลง',
                tooltips: ['ผู้ใช้งานสามารถคัดแยกเสียงร้องหลัก ดนตรีประกอบ และเสียงคอรัสได้ถึง 3 เพลง'],
              },
              {
                text: 'เบต้า - เสียงพูด AI: 20 ตัวอักษร',
                tooltips: ['ผู้ใช้งานสามารถแปลงข้อความเป็นเสียงพูดได้ถึง 20 ตัวอักษรต่อเดือน'],
              },
            ],
          },
          {
            name: 'BASIC',
            model: 'BASIC', //leave in english
            price: {
              usd: '$9.99',
              thb: '฿350',
              krw: '₩14,800',
              jpy: '¥1,699',
            },
            discountedPrice: {
              usd: '',
              thb: '฿279',
              krw: '',
              jpy: '',
            },
            discountRate: {
              usd: '',
              thb: 'ส่วนลด 20%',
              krw: '',
              jpy: '',
            },
            period: '/เดือน',
            features: [
              {
                text: '1 เครดิตสำหรับการเทรนโมเดลเสียงด้วยตัวเอง',
                tooltips: [
                  'เทรนนิ่งโมเดลเสียงได้ 1 โมเดลต่อเดือน เครดิตโมเดลเสียงที่ไม่ได้ใช้ในแต่ละเดือนไม่สามารถทบไปเดือนถัดไปได้',
                ],
              },
              {
                text: 'อัตราความเร็ว - ปกติ',
              },
              {
                text: 'เครดิตการสร้าง AI คัฟเวอร์: 200 เพลง',
                tooltips: [
                  'สามารถสร้าง AI คัฟเวอร์ได้สูงสุด 200 เพลงต่อเดือน เครดิต AI คัฟเวอร์ที่ไม่ได้ใช้ในแต่ละเดือนไม่สามารถทบไปเดือนถัดไปได้',
                ],
              },
              {
                text: 'ตัวแยกเสียงและตัวมิกซ์เสียง AI',
                tooltips: ['Sorisori มาพร้อมตัวมิกซ์เสียง AI เพื่อคุณภาพของเพลงคัพเวอร์ที่ดียิ่งขึ้น'],
              },
              {
                text: 'คุณภาพการเทรนนิ่งเสียง: สูง',
                tooltips: [
                  'อัพโหลดไฟล์เสียงความยาวสูงสุด 30 นาที ใช้เวลาการเทรนนิ่งโดยประมาณ 2 ชั่วโมง',
                  'อัพโหลดด้วยความเร็ว GPU ปกติ ผู้ใช้งานสามารถเทรนนิ่งเสียงด้วยคุณภาพที่ดียิ่งกว่าเวอร์ชั่นฟรี',
                ],
              },
              {
                text: 'คุณภาพเสียง: ปกติ (MP3)',
                tooltips: ['ให้ไฟล์ประเภท MP3'],
              },
              {
                text: 'เครดิตตัวแยกเสียง: 10 เพลง',
                tooltips: ['ผู้ใช้งานสามารถคัดแยกเสียงร้องหลัก ดนตรีประกอบ และเสียงคอรัสได้ถึง 10 เพลง'],
              },
              {
                text: 'เบต้า - เสียงพูด AI: 200 ตัวอักษร',
                tooltips: [
                  'ผู้ใช้งานสามารถแปลงข้อความเป็นเสียงพูดได้ถึง 200 ตัวอักษรต่อเดือน (สูงสุด 50 ตัวอักษรต่อครั้ง)',
                ],
              },
              {
                text: 'เบต้า - เทรนเสียงพูด AI: 1 โมเดล',
                tooltips: [
                  'สามารถเทรนโมเดลเสียงพูดเองได้ 1 โมเดลต่อเดือน เครดิตที่ไม่ถูกใช้ ไม่สามารถทบไปเดือนต่อไปได้',
                ],
              },
            ],
          },
          {
            name: 'PRO',
            model: 'PRO', //leave in english
            price: {
              usd: '$24.80',
              thb: '฿880',
              krw: '₩28,800',
              jpy: '¥3,490',
            },
            discountedPrice: {
              usd: '',
              thb: '฿619',
              krw: '',
              jpy: '',
            },
            discountRate: {
              usd: '',
              thb: 'ส่วนลด 30%',
              krw: '',
              jpy: '',
            },
            period: '/เดือน',
            features: [
              {
                text: '3 เครดิตสำหรับการเทรนโมเดลเสียงด้วยตัวเอง',
                tooltips: [
                  'เทรนนิ่งโมเดลเสียงได้ 3 โมเดลต่อเดือน เครดิตโมเดลเสียงที่ไม่ได้ใช้ในแต่ละเดือนไม่สามารถทบไปเดือนถัดไปได้',
                ],
              },
              {
                text: 'อัตราความเร็ว - เร็ว',
              },
              {
                text: 'เครดิตการสร้าง AI คัฟเวอร์: 1000 เพลง',
                tooltips: [
                  'สามารถสร้าง AI คัฟเวอร์ได้สูงสุด 1000 เพลงต่อเดือน เครดิต AI คัฟเวอร์ที่ไม่ได้ใช้ในแต่ละเดือนไม่สามารถทบไปเดือนถัดไปได้',
                ],
              },
              {
                text: 'ตัวแยกเสียงและตัวมิกซ์เสียง AI',
                tooltips: ['Sorisori มาพร้อมตัวมิกซ์เสียง AI เพื่อคุณภาพของเพลงคัพเวอร์ที่ดียิ่งขึ้น'],
              },
              {
                text: 'คุณภาพการเทรนนิ่งเสียง - สูงมาก',
                tooltips: [
                  'อัพโหลดไฟล์เสียงความยาวสูงสุด 30 นาที ใช้เวลาการเทรนนิ่งโดยประมาณ 2 ชั่วโมง',
                  'อัพโหลดด้วยความเร็ว GPU สูง ผู้ใช้งานสามารถเทรนนิ่งเสียงด้วยคุณภาพที่ดียิ่งกว่าแพลน BASIC',
                ],
              },
              {
                text: 'คุณภาพเสียง: สูง (WAV)',
                tooltips: ['ให้ไฟล์ประเภท WAV'],
              },
              {
                text: 'เครดิตตัวแยกเสียง: 50 เพลง',
                tooltips: ['ผู้ใช้งานสามารถคัดแยกเสียงร้องหลัก ดนตรีประกอบ และเสียงคอรัสได้ถึง 50 เพลง'],
              },
              {
                text: 'เบต้า - เสียงพูด AI: 4000 ตัวอักษร',
                tooltips: [
                  'ผู้ใช้งานสามารถแปลงข้อความเป็นเสียงพูดได้ถึง 4000 ตัวอักษรต่อเดือน (สูงสุด 200 ตัวอักษรต่อครั้ง)',
                ],
              },
              {
                text: 'เบต้า - เทรนเสียงพูด AI: 2 โมเดล',
                tooltips: [
                  'สามารถเทรนโมเดลเสียงพูดเองได้ 2 โมเดลต่อเดือน เครดิตที่ไม่ถูกใช้ ไม่สามารถทบไปเดือนต่อไปได้',
                ],
              },
            ],
          },
          {
            name: 'PREMIUM',
            model: 'PREMIUM', //leave in english
            price: {
              usd: '$68.80',
              thb: '฿2400',
              krw: '₩88,800',
              jpy: '¥8,990',
            },
            discountedPrice: {
              usd: '',
              thb: '฿1680',
              krw: '',
              jpy: '',
            },
            discountRate: {
              usd: '',
              thb: 'ส่วนลด 30%',
              krw: '',
              jpy: '',
            },
            period: '/เดือน',
            features: [
              {
                text: '13 เครดิตสำหรับการเทรนโมเดลเสียงด้วยตัวเอง',
                tooltips: [
                  'เทรนนิ่งโมเดลเสียงได้ 13 โมเดลต่อเดือน เครดิตโมเดลเสียงที่ไม่ได้ใช้ในแต่ละเดือนไม่สามารถทบไปเดือนถัดไปได้',
                ],
              },
              {
                text: 'อัตราความเร็ว - เร็วมาก',
              },
              {
                text: 'เครดิตการสร้าง AI คัฟเวอร์: ไม่จำกัด',
                tooltips: ['สามารถสร้าง AI คัฟเวอร์ได้ไม่จำกัดจำนวน'],
              },
              {
                text: 'ตัวแยกเสียงและตัวมิกซ์เสียง AI',
                tooltips: ['Sorisori มาพร้อมตัวมิกซ์เสียง AI เพื่อคุณภาพของเพลงคัพเวอร์ที่ดียิ่งขึ้น'],
              },
              {
                text: 'คุณภาพการเทรนนิ่งเสียง - สูงมาก',
                tooltips: [
                  'อัพโหลดไฟล์เสียงความยาวสูงสุด 90 นาที ใช้เวลาการเทรนนิ่งโดยประมาณ 2 ชั่วโมง',
                  'อัพโหลดด้วยความเร็ว GPU สูงสุด ผู้ใช้งานสามารถเทรนนิ่งเสียงด้วยคุณภาพที่ดียิ่งกว่าแพลน PRO',
                ],
              },
              {
                text: 'คุณภาพเสียง: สูง (WAV)',
                tooltips: ['ให้ไฟล์ประเภท WAV'],
              },
              {
                text: 'เครดิตตัวแยกเสียง: ไม่จำกัด',
                tooltips: ['ผู้ใช้งานสามารถคัดแยกเสียงร้องหลัก ดนตรีประกอบ และเสียงคอรัสได้ไม่จำกัด'],
              },
              {
                text: 'เบต้า - เสียงพูด AI: 20000 ตัวอักษร',
                tooltips: [
                  'ผู้ใช้งานสามารถแปลงข้อความเป็นเสียงพูดได้ถึง 20000 ตัวอักษรต่อเดือน (สูงสุด 1000 ตัวอักษรต่อครั้ง)',
                ],
              },
              {
                text: 'เบต้า - เทรนเสียงพูด AI: 3 โมเดล',
                tooltips: [
                  'สามารถเทรนโมเดลเสียงพูดเองได้ 3 โมเดลต่อเดือน เครดิตที่ไม่ถูกใช้ ไม่สามารถทบไปเดือนต่อไปได้',
                ],
              },
            ],
          },
        ],
        sectionHelp: 'ช่วยเหลือ',
        faqTab: {
          title: 'คำถามที่พบบ่อย',
          placeholder: 'พิมพ์เพื่อค้นหา...',
          faqs: [
            {
              title: 'คำถามเกี่ยวกับการเทรนและการสร้างโมเดลเสียง AI',
              qnas: [
                {
                  question: 'สามารถใช้ประเภทไฟล์ใดได้บ้าง?',
                  answers: [
                    'Sorisori.ai สามารถรับไฟล์ได้ทั้งหมด 6 ชนิด: mp3, wav, m4a, mkv, mp4, flac, ogg. สิ่งสำคัญที่สุดคือคุณภาพของเสียงต้นแบบ การแปลงชนิดไฟล์อาจส่งผลต่อคุณภาพเสียงที่ลดลง ไฟล์เสียงชนิด m4a จะใกล้เคียงกับคุณภาพของการอัดเสียงจากมือถือ',
                  ],
                },
                {
                  question: 'ตัวแยกเสียง (Vocal Extractor) จำเป็นหรือไม่?',
                  answers: [
                    'Sorisori AI มีบริการตัวแยกเสียงอัตโนมัติที่จะช่วยคัดแยกเสียงได้อย่างมีประสิทธิภาพ เช่น การคัดแยกเสียงร้องออกจากเสียงดนตรี ทั้งนี้ ขั้นตอนการแยกเสียงนั้นขึ้นอยู่กับความจำเป็นของแต่ละบุคคล หากผู้ใช้งานมีเสียงต้นแบบที่คุณภาพสูงและไม่มีเสียงอื่นๆ รบกวน ก็ไม่จำเป็นต้องใช้ตัวแยกเสียงแต่อย่างใด',
                  ],
                },
                {
                  question: 'สามารถอัพโหลดหลายๆ ไฟล์ในการเทรน AI ได้หรือไม่?',
                  answers: [
                    'ในการเทรน AI สามารถอัพโหลดหลายๆ ไฟล์พร้อมกันได้ หากเป็นเสียงที่ผู้ใช้งานกดอัดเอง สามารถกดอัดแยกไฟล์และอัพโหลดแยกกันได้เช่นเดียวกัน',
                  ],
                },
                {
                  question: 'ไมโครโฟนที่แนะนำสำหรับการอัดเสียงเพื่อเทรน AI',
                  answers: [
                    'โปรแกรมอัดเสียงในมือถือสามารถให้คุณภาพที่เพียงพอต่อการเทรน AI แนะนำให้อัดเสียงในพื้นที่ที่เงียบสนิท ไม่มีเสียงรบกวน',
                  ],
                },
                {
                  question: 'การอัพโหลดเพลงที่อัดมาจากห้องคาราโอเกะ',
                  answers: ['ไม่แนะนำให้อัพโหลดไฟล์เสียงที่อัดมาจากห้องร้องคาราโอเกะเนื่องจากปัญหาเรื่องคุณภาพเสียง'],
                },
                {
                  question: 'คุณภาพเสียง',
                  answers: [
                    'โปรดตรวจสอบคุณภาพเสียงหลังจากการอัดเสียงทุกครั้ง ในบางกรณี ระยะห่างของไมค์อาจส่งผลต่อคุณภาพของเสียงได้',
                    '',
                  ],
                },
                {
                  question: 'หากเราลบโมเดลเสียง เราสามารถเทรนใหม่ได้หรือไม่?',
                  answers: ['การลบโมเดลเสียงจะไม่คืนเครดิตการเทรนเสียง'],
                },
              ],
            },
            {
              title: 'คำถามเกี่ยวกับการสร้างคัฟเวอร์ AI',
              qnas: [
                {
                  question: ' การยกเลิกระหว่างการเทรนนิ่ง',
                  answers: [
                    "ระหว่างการอัพโหลด โปรดตรวจเช็คประเภทไฟล์ให้ถูกต้อง และโปรดรอจนกว่าระบบจะขึ้นว่า 'กำลังอยู่ระหว่างดำเนินการ'",
                  ],
                },
                {
                  question: 'ประเภทไฟล์ที่สามารถใช้ทำ AI คัฟเวอร์ได้',
                  answers: [
                    'สามารถรับไฟล์ได้ทั้งหมด 6 ชนิด: mp3, wav, m4a, mkv, mp4, flac, ogg. สิ่งสำคัญที่สุดคือคุณภาพของเสียงต้นแบบ การแปลงชนิดไฟล์อาจส่งผลต่อคุณภาพเสียงที่ลดลง ไฟล์เสียงชนิด m4a จะใกล้เคียงกับคุณภาพของการอัดเสียงจากมือถือ',
                  ],
                },
                {
                  question: 'ตัวแยกเสียง (Vocal Extractor) จำเป็นหรือไม่?',
                  answers: [
                    'Sorisori AI มีบริการตัวแยกเสียงอัตโนมัติที่จะช่วยคัดแยกเสียงได้อย่างมีประสิทธิภาพ เช่น การคัดแยกเสียงร้องออกจากเสียงดนตรี ทั้งนี้ ในการสร้าง AI คัฟเวอร์นั้นไม่จำเป็นต้องใช้ตัวแยกเสียงแต่อย่างใด',
                  ],
                },
                {
                  question: 'ใช้เสียงตัวอย่างคัฟเวอร์ได้หรือไม่?',
                  answers: ['สามารถใช้เสียงตัวอย่างสร้างคัฟเวอร์ได้'],
                },
                {
                  question: 'ปัญหาการดาวน์โหลดจากหน้าเว็บ',
                  answers: [
                    'แนะนำให้ใช้ Browsers เช่า Chrome, Safari, Firefox เป็นต้น ในการใช้งานและดาวน์โหลดเพลง หากใช้ผ่านโปรแกรมหรือแอปอื่นๆ อาจส่งผลต่อการดาวน์โหลดได้',
                  ],
                },
              ],
            },
            {
              title: 'คำแนะนำเกี่ยวกับการสร้างคัฟเวอร์ AI',
              qnas: [
                {
                  question: 'คุณภาพของเสียงต้นฉบับ',
                  answers: [
                    'คุณภาพของคัฟเวอร์AI ขึ้นอยู่กับคุณภาพของเสียงต้นฉบับ เราขอแนะนำให้เลือกที่มาของต้นฉบับเสียงที่ให้คุณภาพสูงที่สุด',
                  ],
                },
                {
                  question: 'หลีกเลี่ยงเสียงเอฟเฟกต์และคอรัส',
                  answers: [
                    'โปรดหลีกเลี่ยงการใช้เสียงต้นฉบับที่ผ่านการแปลงเสียงด้วยเอฟเฟกต์หรือมีเสียงคอรัสแทรก แนะนำให้ใช้ตัวแยกเสียง (Vocal Extractor) ในการแยกเสียงร้องออกจากเสียงคอรัสก่อนนำเสียงต้นฉบับมาสร้างโมเดลเสียง AI',
                  ],
                },
                {
                  question: 'การประสานเสียง',
                  answers: [
                    'โปรดหลีกเลี่ยงเพลงหรือเสียงต้นฉบับที่มีนักร้องหลายคนร้องพร้อมกันในเวลาเดียวกัน โปรดเลี่ยงโดยเฉพาะเสียงต้นฉบับที่มีการร้องประสานเสียง',
                  ],
                },
              ],
            },
            {
              title: 'คำถามเกี่ยวกับการชำระเงิน',
              qnas: [
                {
                  question: 'การคำนวณส่วนต่างค่าธรรมเนียมในการอัพเกรด',
                  answers: ['ค่าธรรมเนียมในการอัพเกรดจะคิดจากส่วนต่างของค่าสมาชิก (Subscription Plan) ในแต่ละแผน'],
                },
                {
                  question: 'การยกเลิกการสมัครสมาชิกในแต่ละเดือน',
                  answers: [
                    'ผู้ใช้งานสามารถยกเลิกการสมัครสมาชิกในแต่ละเดือนได้โดยไปที่หน้า "สมัครสมาชิก" (Subscription) กด "เปลี่ยน" และกด "ยกเลิกแผน (Cancel Plan)"',
                  ],
                },
                {
                  question: 'การทบเครดิตโมเดล AI ที่ไม่ได้ใช้',
                  answers: ['เครดิตของโมเดลAI ที่ไม่ได้ใช้ในแต่ละเดือนไม่สามารถทบไปใช้ในเดือนต่อไปได้'],
                },
                {
                  question: 'การทบเครดิต AI คัฟเวอร์ที่ไม่ได้ใช้',
                  answers: ['เครดิตเพลง AI คัฟเวอร์ที่ไม่ได้ใช้ ไม่สามารถทบไปใช้ในเดือนถัดไปได้'],
                },
                {
                  question: 'การชำระเงินผ่านบัตรเครดิต/เดบิต',
                  answers: [
                    'ณ ขณะนี้ สามารถชำระเงินผ่านบัตรเครดิต/เดบิต ที่สามารถใช้ชำระเงินระหว่างประเทศ (International) ได้เท่านั้น เรากำลังพัฒนาให้สามารถรับการชำระเงินด้วยบัตรและบริการชำระเงินภายในประเทศรูปแบบอื่นๆ ได้ในอนาคตต่อไป',
                  ],
                },
                {
                  question: 'การคืนเงิน',
                  answers: [
                    'ผู้ใช้งานต้องทำเรื่องขอคืนเงินภายใน 21 วันหลังจากการชำระเงิน และมีค่าธรรมเนียมการคืนเงินมูลค่า $3 (ราคาสุทธิขึ้นอยู่กับค่าเงินบาทในช่วงเวลานั้นๆ)',
                  ],
                },
              ],
            },
            {
              title: 'คำถามเพิ่มเติม',
              qnas: [
                {
                  question: 'การลบบัญชีผู้ใช้งาน',
                  answers: [
                    'เบื้องต้นผู้ใช้งานสามารถติดต่อ Sorisori.ai ผ่าน Discord เพื่อทำเรื่องในการลบบัญชีผู้ใช้งาน เรากำลังพัฒนาระบบที่จะเพิ่ม Feature ให้ผู้ใช้งานสามารถจัดการบัญชีได้เองในอนาคต',
                  ],
                },
                {
                  question: 'Discord คืออะไร?',
                  answers: [
                    'Discord คือพื้นที่สำหรับคอมมิวนิตี้ของผู้ใช้งาน AI เสียง มีไว้สำหรับผู้ใช้งานได้แลกเปลี่ยนผลงาน ข้อมูลข่าวสารต่างๆ ร่วมกัน',
                  ],
                },
              ],
            },
          ],
        },
        contactTab: {
          title: 'ติดต่อเรา',
          placeholder: {
            type: 'ประเภทการติดต่อ',
            email: 'อีเมล',
            text: 'ข้อความ',
            refund:
              'นโยบายการคืนเงิน\n\n1. จะต้องไม่มีประวัติการใช้งานในเดือนนั้นๆ .\n2. ผู้ใช้งานต้องทำเรื่องขอคืนเงินภายใน 3 อาทิตย์ (21 วัน).\n3. มีค่าธรรมเนียมการคืนเงินมูลค่า $3 (ราคาสุทธิขึ้นอยู่กับค่าเงินบาทในช่วงเวลานั้นๆ).\n4. ผู้ใช้งานจะต้องยกเลิกการสมัครสมาชิกในแต่ละเดือนด้วยตนเอง โดยไปที่หน้า "สมัครสมาชิก" (Subscription) กด "เปลี่ยน" และกด "ยกเลิกแผน" (Cancel Plan)',
          },
          message: {
            type: 'โปรดเลือกประเภทการติดต่อ',
            email: 'โปรดใส่อีเมลของคุณ',
            content: 'โปรดเขียนข้อความที่นี่',
            loading: 'กำลังส่งคำร้อง กรุณารอสักครู่',
            success: 'การส่งคำร้องสำเร็จเรียบร้อย',
            fail: 'ไม่สามารถส่งคำร้องได้ โปรดลองอีกครั้ง',
            useDiscord:
              'หากมีคำถามเพิ่มเติมเกี่ยวกับการทำ Cover หรือการสร้างโมเดลเสียง สามารถเข้าไปที่ ""คำถามที่พบบ่อย"" หรือไปที่กลุ่ม Discord ของเรา​',
          },
          contactType: ['การสร้างโมเดลเสียง/สร้าง Cover', 'เกี่ยวกับ API', 'ขอคืนเงิน', 'ติดต่อเรื่องอื่นๆ'],
          submit: 'ส่งคำร้อง',
        },
        emailPreferenceTab: {
          title: 'การตั้งค่าอีเมล',
          header: 'อย่าพลาดข้อเสนอสุดพิเศษ ฟีเจอร์ใหม่ๆ และอัพเดตสุดพิเศษก่อนใครกับเรา!',
          choiceLabels: {
            announcement: 'ประกาศ',
            notification: 'การแจ้งเตือน',
            newsletter: 'จดหมายข่าว',
            promotion: 'โปรโมชั่น',
          },
          submit: {
            save: 'บันทึก',
          },
          modal: {
            success: 'บันทึกสำเร็จ',
            error: 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
          },
        },
        profileTab: {
          title: 'โปรไฟล์ของฉัน',
          sections: {
            login: {
              title: 'บัญชี',
              logout: 'ออกจากระบบ',
            },
            payment: {
              title: 'การชำระเงิน',
              changePayment: 'เปลี่ยนการชำระเงิน',
              updateSubscription: 'อัพเดตแผนสมาชิก',
              cancelSubscriptionChange: 'คืนค่าการเปลี่ยนแปลงสมาชิก',
              history: 'ดูประวัติการชำระเงิน',
              cancel: 'ยกเลิกแผนสมาชิก',
              currentSubscription: 'แผนสมาชิกปัจจุบัน',
              nextPaymentDate: 'วันเรียกชำระเงินครั้งถัดไป',
              currentCycle: 'รอบบิลปัจจุบัน',
              nextSubscriptionType: 'รอบบิลครั้งถัดไป',
            },
            question: {
              title: 'คำถาม',
              contentPre: 'โปรดใช้ ',
              contentLink: 'ติดต่อเรา',
              contentPost: ' แท็บสำหรับคำถามเกี่ยวกับการชำระ/คืนเงิน',
            },
          },
          subscriptionType: {
            FREE: 'FREE',
            BASIC: 'BASIC',
            PRO: 'PRO',
            PREMIUM: 'PREMIUM',
          },
        },
        paymentModal: {
          title: 'ประวัติการชำระเงิน',
          date: 'วันที่',
          info: 'ข้อมูล',
          amount: 'จำนวน',
        },
      },
    },
    ja: {
      translation: {
        languageDetector: 'ja',
        requestFailed: '再度お試しください',
        pageLoadFailed: 'ページの読み込みに失敗しました。リロードしてください',
        signIn: 'サインイン',
        defaultModels: ['サンプル音声 A(男)'],
        yes: 'はい',
        no: 'いいえ',
        sectionProduction: ['AIカバー'],
        trainingTab: {
          title: 'AI音声学習',
          tabs: ['直接学習', '外部モデル'],
          stepOne: {
            title: 'ステップ１: 音声アップロード',
            tooltip: {
              titleOne: 'アップロード注意点',
              contentsOne: [
                '量より質。低品質30分より高品質15分の音源を',
                'オートチューンのような過度な音響効果の入った曲はダメ',
                'ライブ音源よりは正式音源をお勧めします',
                '必ず！ソローの曲を使うこと',
                '会話音声より歌声を使う事',
                'ラップカバーの場合、ラップの音源がお勧め',
              ],
              titleTwo: '直接録音する場合',
              contentsTwo: [
                'モバイルレコーダーでもOK',
                '一発で録音する必要はないです',
                '歌声だけアップロードすることをお勧めします',
                'カラオケでの録音はお勧めしません',
                'アップロードする前に録音をもう一回確認',
              ],
            },
            trainingSubTitles: ['学習させる音声ファイルをアップロードしてください'],
            externalSubTitles: ['外部モデルをアップロードしてください'],
            dragAndDropText: 'ドラッグ・アンド・ドロップ',
            dragAndDropDuration: '(総合{{minDuration}}分以上、{{maxDuration}}分)',
            dragAndDropButton: 'ファイル選択',
            durationText: '全体の音源の長さ',
            durationUnit: '分',
            tabs: {
              fileUpload: {
                vocalExtractionText: 'ボーカルだけが抽出されていますか？',
                vocalExtractionYes: 'はい',
                vocalExtractionNo: 'いいえ',
                vocalExtractionTooltip: {
                  title: 'ボーカル抽出注意点',
                  contents: [
                    'ボーカルだけが抽出された音源である場合に"はい"をクリック',
                    '普通の音源の場合には該当なし',
                  ],
                },
              },
            },
          },
          stepTwo: {
            title: 'ステップ２: モデル名付け',
            subTitles: ['AI音声に名前を付けて学習を始めてください'],
            placeholder: 'Give a label to your model',
          },
          submit: {
            upload: 'アップロード',
            free: 'サブスクライブしてください',
            inProgress: '学習中',
            noRemaining: '使用可能回数が残っていません',
            start: '学習開始',
            uploading: 'アップロード中です',
            remainingCountText: '今月使用可能回数',
            tooltip: {
              contents: [
                '音声学習には0.5~1時間掛かります',
                'サーバーにアクセスが集中している場合、学習が遅れる可能性があります',
                '"ヒストリー"タブから学習状況を確認できます',
              ],
            },
          },
          modal: {
            exceedModelCount:
              '無料・基本プランでは最大3個の音声モデルを所持できます。プランのアップグレード、または所持している音声モデルのいずれかを削除してください',
            exceedMaxDuration: '{{maxDuration}}分以上の音声ファイルはアップロードできません',
            minDuration: '{{minDuration}}分以上の音声ファイルをアップロードしてください',
            notSupportedFileType: '認識できないファイル形式です',
            imgModalConfirm: '進む',
            imgModalCancel: '戻る',
            noLabel: '名前を入力してください',
            missingFile: 'すべてのファイルを入れてください',
            noFile: 'Please upload files',
            longLabel: 'Label cannot exceed 25 characters',
          },
        },
        communityUploadTab: {
          title: 'モデルの共有',
          tabs: ['自分のモデル ', '外部ファイル'],
          stepOne: {
            title: 'ステップ１:イメージアップロード',
            subTitles: ['モデルに合うイメージをアップロードしてください'],
            tabs: {
              fileUpload: {
                dragAndDropText: 'ファイルをドラッグ・アンド・ドロップ',
              },
            },
          },
          stepTwo: {
            title: 'ステップ２: ボイスモデル選択',
            trainingSubTitles: ['ボイスを選択してください'],
            externalSubTitles: ['ボイスモデルをアップロードしてください'],
            dragAndDropText: ['Path ファイルをアップロード', 'Index ふファイルをアップロード'],
            dragAndDropButton: 'ファイル選択',
            voice: 'ボイス',
          },
          stepThree: {
            title: 'ステップ３: モデル名付け',
            subTitle: 'モデルに名前を付けてください',
            genderLabel: 'モデルの性別を選択してください',
            gender: ['男性', '女性'],
            anonymousText: 'ユーザーネームを隠します',
          },
          stepFour: {
            title: 'ステップ４: モデルのタグ付け',
            subTitle: 'モデルに合うタグをすべて付けてください',
            tags: ['ジャンル', '国'],
          },
          submit: {
            upload: 'アップロード',
            start: 'モデルの共有',
            uploading: 'アップロード中。お待ちください',
          },
          modal: {
            noImage: 'Please upload an image',
            noModel: 'Please select a voice model to share',
            noTag: 'Please select at least one tag for each field',
            noLabel: 'Please give a label',
            longLabel: 'Label cannot exceed 25 characters',
          },
        },
        inferenceTab: {
          title: 'AIカバー',
          stepOne: {
            tooltip: {
              title: 'AIカバーの注意点',
              contents: [
                '音源の音質は極めて重要',
                '音楽にて買った正式音源を使用してください',
                'オートチューンのような過度な音響効果の入った曲はダメ',
                'コーラスの多い曲はお勧めしません',
              ],
            },
            title: 'ステップ１: 曲アップロード',
            subTitles: ['カバーする曲をアップロードしてください'],
            tabs: {
              fileUpload: {
                title: 'ファイルをアップロード',
                dragAndDropText: 'ドラッグ・アンド・ドロップ',
                dragAndDropButton: 'ファイルを選択',
                dragAndDropDuration: '(最大容量 75 MB)',
                vocalExtractionText: 'ボーカルだけが抽出されていますか？',
                vocalExtractionYes: 'はい',
                vocalExtractionNo: 'いいえ',
                vocalExtractionTooltip: {
                  title: 'ボーカル抽出注意点',
                  contents: [
                    'ボーカルだけが抽出された音源である場合に"はい"をクリック',
                    '普通の音源の場合には該当なし',
                  ],
                },
              },
              youtube: {
                title: 'youtubeリンク',
                linkPlaceholder: '10分未満から可能',
                linkbutton: '使用する',
                durationUnit: ['分', '秒'],
              },
              recording: {
                title: '音声録音',
                startButton: '録音開始',
                saveButton: 'セーブ',
                recordingButton: '録音中',
                userRecording: 'ユーザー音声録音',
              },
            },
          },
          stepTwo: {
            title: '2段階: AI音声を選んでください',
            modelInput: {
              placeHolder: 'AI音声選択',
              myModel: '自分のモデル',
              defaultModel: '基本モデル',
            },
            dragAndDropText: 'ボイスモデルを探す',
          },
          stepThree: {
            title: '3段階: 設定',
            subTitles: 'ミキサーの値、 ファイル拡張子, ファイル名を設定してください',
            labelInput: {
              placeHolder: 'ファイル名を入力してください',
            },
            ouputFormatLabel: 'ファイル形式を選択してください',
            tooltip: {
              outputFormatMessage: 'WAV形式はプロ・プレミアムプランから選択できます',
              upgradePrompt: 'ここからプランのアップグレード',
              subscribePrompt: 'ここからサブスクライブ',
              title: 'AIカバー名前設定に関するヘルプ',
              contents: ['直接設定しない場合、「音声 - 参照元」で設定されます', '後で制作記録で変更可能'],
            },
          },
          submit: {
            completedAndLoadingPlayer:
              'カバー完了。オーディオプレイヤーをロードしています。いつでも"ヒストリー"タブからダウンロードできます',
            alreadyInProgress: 'カバー中',
            noRemaining: '使用可能回数が残っていません',
            start: '変換開始',
            uploading: 'アップロードしています。少しお待ちください',
            tryAgain: 'しばらく経ってからやり直してください',
            inProgress:
              '変換しています。完了次第音源の再生ができます。"ヒストリー"タブから直ぐにカバー結果の確認やダウンロードできます',
            remainingCountText: '今月使用可能回数',
            remainingCountUnit: '曲',
            tooltip: {
              contents: [
                'AIカバーには1~4分ほど掛かります',
                'サーバーにアクセスが集中している場合、学習が遅れる可能性があります',
                '"ヒストリー"タブから学習状況を確認できます',
              ],
            },
          },
          modal: {
            watermark:
              '無料プランではウォーターマーク（透かし）が含まれます。有料プランにアップグレードすることでウォーターマークは無くなります',
            exceedModelCount:
              '無料・基本プランでは最大3個の音声モデルを所持できます。プランのアップグレード、または所持している音声モデルのいずれかを削除してください',
            exceedMaxSize: '最大75MBのファイルまでアップロードできます',
            tooManyFiles: 'ファイルは最大1個までアップロードできます',
            notSupportedFileType: '認識できないファイル形式です',
            inProgress: 'すでに学習中です',
            noRemaining: '使用可能回数が残っていません',
            emptyYoutubeLink: 'カバーする曲をアップロードしてください',
            noModalSelected: 'ボイスモデルを選択してください',
            noLabel: '名前を入れてください',
            invalidYoutubeLink: 'リンクを認識できません',
            youtubeLinkTooLong: '10分未満から可能',
            longLabel: 'Label cannot exceed 100 characters',
          },
        },
        ttsInferenceTab: {
          title: 'AIテキスト音声変換',
          stepOne: {
            modelInput: {
              placeHolder: 'AI音声モデルを選択',
              myModel: '自分のモデル',
            },
            ttsTextAreaPlaceholder: 'テキスト入力で音声への変換ができます',
            dragAndDropText: '音声モデルを探すにはクリック',
          },
          submit: {
            completedAndLoadingPlayer:
              '製作完了。オーディオプレイヤーをロード中です。"AIテキスト音声変換"-"ヒストリー"タブからすぐダウンロードできます',
            alreadyInProgress: '変換中',
            noRemaining: '使用可能な文字数が残っていません',
            start: '変換開始',
            uploading: 'アップロード中です。少しお待ちください',
            tryAgain: 'またやり直してください',
            inProgress:
              '変換中です。製作完了後、すぐ音源を再生することができます。"AIテキスト音声変換"-"ヒストリー"タブからもすぐダウンロードできます',
            remainingCountText: 'AIテキスト変換可能な残り文字数',
            remainingCountUnit: '字',
            remainingMonthly: '今月残り文字数',
            setting: '  細部調整',
          },
          modal: {
            selectModalTitle: 'AI音声',
            addNewModelText: '新しい音声を製作',
            exceedTextSize: '一回当たり変換可能な文字数を超えています。文字数 : {{characterLimitPerInference}}',
            inProgress: 'すでに学習中です',
            noRemaining: '今月使用可能な回数が残っていません',
            noModalSelected: '音声モデルを選択してください',
            noText: 'テキストを入力してください',
            variability: '変動性',
            stable: '単調さ',
            variable: '変動的',
            similarity: '類似度',
            low: '低い',
            high: '高い',
            longLabel: 'Label cannot exceed 50 characters',
          },
        },
        ttsTrainingTab: {
          title: 'AIテキスト音声変換',
          stepOne: {
            tooltip: {
              contentsOne: [
                '量より質。低品質10分より高品質5分の音源を',
                '背景音の入ってないきれいな音声を使うこと',
                '会話音声より歌声を使う事',
                '最大10MBのファイルまでアップロードできます',
                'ファイルは最大10個までアップロードできます',
              ],
            },
            sizeLimitText: '(各ファイルは10MB以下である必要があります)',
            numberLimitText: '(ファイルは最大10個まで)',
            dragAndDropDuration: '(総合{{minDuration}}秒以上、{{maxDuration}}分)',
            second: '秒',
          },
          modal: {
            sizeTooLarge: '各ファイルは10MB以下である必要があります.',
            tooManyFiles: 'ファイルは最大10個までアップロードできます.',
            exceedMaxDuration: '{{maxDuration}}分以上の音声ファイルはアップロードできません',
            minDuration: '{{minDuration}}秒以上の音声ファイルをアップロードしてください',
            duplicateFiles: '重複したファイルをアップロードすることはできません.',
          },
          submit: {
            tooltip: {
              contents: [
                '音声学習には0.5~10分掛かります',
                'サーバーにアクセスが集中している場合、学習が遅れる可能性があります',
                "'AIテキスト音声変換'-'ヒストリー'タブから確認できます",
              ],
            },
          },
        },
        ttsAppBar: {
          sectionTTS: '文字音声変換',
          subSectionTTS: ['AI変換', 'AI音声学習', '文字音声変換ヒストリー'],
        },
        ttsHistoryTab: {
          title: 'テキスト音声変換ヒストリー',
          tabs: {
            inference: {
              title: 'テキスト音声変換',
            },
            training: {
              title: 'AI音声',
            },
          },
          infoIcon: {
            voice: '音声',
            variability: '変動性',
            similarity: '類似度',
          },
        },
        videoEditorTab: {
          title: 'Video Editor',
          addSource: 'Source',
        },
        addSourceModal: {
          title: 'Add a source',
          sourceOptions: [
            'AI Cover',
            'TTS',
            'Youtube',
            'Upload',
          ],
          searchbar: 'Search',
          pagination: {
            loader: 'Loading...',
          },
          voice: 'AI Voice',
          youtube: {
            placeholder: 'Max 10 minutes',
            button: 'Search',
            invalidYoutubeLink: 'This is an incorrect YouTube link',
            youtubeLinkTooLong: 'The YouTube video may not exceed 10 minutes',
          },
          upload: {
            dragAndDropText: 'Drag and drop a video/audio/image file',
            sizeLimitText: '(Max 75 MB)',
            acceptedAudio: '.mp3, .wav, .flac, and .ogg',
            acceptedVideo: '.mp4 and .mkv',
            acceptedImage: '.jpeg, .png, and .webp',
            button: 'Select file',
            notSupportedFileType: 'This file format is not supported',
            tooManyFiles: 'Please upload one file at a time',
            exceedMaxSize: 'You can only upload files up to 75MB',
          },
          loading: [
            'Uploading file',
            '(may take up to a minute for larger files)',
          ],
        },
        textEditorModal: {
          title: [
            'Add text',
            'Edit text',
          ],
          placeholder: 'Type your text here...',
          add: 'Add',
          save: 'Save',
        },
        videoExportModal: {
          title: 'Export video',
          placeholder: 'Give your exported video a label',
          watermark: 'Show watermark',
          start: 'Start',
          rendering: [
            'Please stay on the page',
            'Exporting your video (may take up to a minute)',
          ],
          success: [
            'Video is ready',
            'Your download will start automatically. If not, click the button below.',
          ],
          tooltip: {
            contents: [
              'Export time will depend on the length of your video',
              'You will be taken to the History tab where you can check the progress',
            ]
          },
          videoRenderError: 'Could not render the video. Please try again',
        },
        // MR除去ページ
        vocalExtractorTab: {
          title: 'MR除去',
          stepOne: {
            title: 'ステップ１: 音声アップロード',
            tabs: {
              fileUpload: {
                title: 'ファイルアップグレード',
                dragAndDropText: 'ドラッグ・アンド・ドロップ',
                dragAndDropButton: 'ファイルを',
                dragAndDropDuration: ' (最大容量 75 MB)',
              },
              youtube: {
                title: ' youtubeリンク',
                linkPlaceholder: '10分未満から可能',
                linkbutton: '使用する',
                durationUnit: ['分', '秒'],
              },
            },
            vocalExtractorSubTitles: ['MR除去をする音声ファイルをアップロードしてください'],
            tooltip: {
              contents: [
                'プレミアムユーザーは一度に最大10個のファイルをアップロードできます',
                'プロユーザーは一度に最大5個のファイルをアップロードできます',
                '基本/無料ユーザーは一度に1個のファイルをアップロードできます',
                'オーディオファイルの総長は60分を超えることができません',
              ],
            },
            maxUploadNumText: {
              premiumCustomAdmin: '最大10個のファイルをアップロード可能',
              pro: '最大5個のファイルをアップロード可能',
              basicFree: '最大1個のファイルをアップロード可能',
            },
          },
          stepTwo: {
            title: 'ステップ2: 設定',
            subTitles: 'ファイル拡張子とファイル名を設定してください',
            labelInput: {
              placeHolder: 'ファイル名を決めてください',
            },
            tooltip: {
              outputFormatMessage: 'WAV形式はプロ・プレミアムプランから選択できます',
              upgradePrompt: 'ここからプランのアップグレード',
              subscribePrompt: 'ここからサブスクライブ',
            },
          },
          submit: {
            alreadyInProgress: 'カバー中',
            upload: 'アップロード',
            inProgress: '変換しています。"ヒストリー"タブから直ぐにカバー結果の確認やダウンロードできます',
            noRemaining: '使用可能回数が残っていません',
            start: '開始',
            uploading: 'アップロードしています。少しお待ちください',
            tryAgain: 'しばらく経ってからやり直してください',
            remainingCountText: '今月使用可能回数',
            remainingCountUnit: '曲',
          },
          modal: {
            notSupportedFileType: '認識できないファイル形式です',
            invalidYoutubeLink: 'リンクを認識できません',
            youtubeLinkTooLong: '10分未満から可能',
            noRemaining: '使用可能回数が残っていません',
            emptyYoutubeLink: ' MR除去をする音声ファイルをアップロードしてください',
            inProgress: 'すでに学習中です',
            exceedMaxSize: '最大75MBのファイルまでアップロードできます',
            exceedMaxDuration: '総再生時間は60分を超えることができません',
            premiumCustomAdmin: 'プレミアム/カスタム',
            pro: 'プロ',
            filePlural: '個を',
            tooManyFiles: ['ユーザーは最大ファイル', 'アップロードできます'],
            noFiles: 'MRを削除する音声ファイルを追加してください',
            lackRemaining: '使用可能な回数を超えています',
            uploadStatusNotValid: 'MR削除の残り回数が不足しているか、すでに進行中です',
            tryAgain: 'アップロードに失敗したファイル:',
            noLabel: 'Please give a label to your vocal extract',
            longLabel: 'Label cannot exceed 50 characters',
          },
        },
        historyTab: {
          title: 'ヒストリー',
          inProgress: '進行中',
          failed: '失敗',
          freeVersionTag: '無料プラン',
          freePrefix: '無料プランではウォーターマーク（透かし）が含まれます',
          tooltip: {
            contents: [
              'AIカバーには2~4分ほど掛かります',
              '音声学習には0.5~1時間掛かります',
              "作業が失敗になった場合、'問い合わせ'タブをご利用ください",
            ],
          },
          tabs: {
            inference: {
              title: 'AIカバー',
            },
            training: {
              title: 'AI音声',
            },
            vocalExtract: {
              title: 'MR除去',
            },
            community: {
              title: 'コミュニティ',
            },
          },
          changeButton: '変更',
          cancelButton: 'キャンセル',
          confirmModelDelete: 'このモデルを削除しても再学習できません。削除しますか？',
          confirmInferenceDelete: '本当に削除しますか？',
          confirmVocalExtractDelete: '本当に削除しますか？',
          searchbar: '検索',
          infoIcon: {
            source: '著作権',
            voice: '音声',
            pitch: 'キー設定',
            mixing: {
              title: 'Mixer settings',
              vocalLevel: 'Vocal Level',
              reverbLevel: 'Reverb Level',
              reverbLength: 'Reverb Length',
            },
            errorMessage: 'エラー',
          },
          pagination: {
            loader: 'ロード中...',
          },
          modal: {
            error: 'ページを更新してください',
            label: '名前',
            username: 'またはユーザーネーム',
            image: '画像',
            filterError: '音声フィルターが見つかりません',
          },
          trainingInfo: {
            files: '使用した音源',
            extracted: 'ボーカル抽出機能使用',
            shared: 'コミュニティに共有',
            yes: 'はい',
            no: 'いいえ',
            status: 'ステータス',
            errorFile: 'エラーがあるファイル',
          },
          voice: 'AI音声',
        },
        subscriptionTab: {
          title: 'プラン',
          pageTitleOne: 'ソリソリAI',
          popular: '人気プラン',
          cancel: {
            message: 'サブスクリプションをキャンセルしますか？',
            success: 'サブスクリプションのキャンセルが完了しました！',
            fail: 'サブスクリプションのキャンセルに失敗しました。再度お試しください。',
          },
          cancelChange: {
            message: 'サブスクリプションの変更をキャンセルしますか？',
            success: 'サブスクリプションの変更キャンセルが完了しました！',
            fail: 'サブスクリプションの変更キャンセルに失敗しました。再度お試しください。',
          },
          onetime: {
            message: '購入しますか？',
            success: '購入が完了しました！',
            fail: '決済に失敗しました。再度お試しください。',
          },
          downgrade: {
            message: '料金プランの変更を進めますか？',
            success: '料金プランの変更が完了しました！',
            fail: '料金プランの変更に失敗しました。再度お試しください。',
          },
          upgrade: {
            message: '料金プランの変更を進めますか？既存の決済方法で決済が試行されます。',
            success: '料金プランの変更が完了しました！',
            fail: '料金プランの変更に失敗しました。再度お試しください。',
            paymentFailed: '決済に失敗しました。マイプロフィールで決済手段を変更するか、再度お試しください。',
          },
          mobilePaymentRequired:
            'モバイルアプリでサブスクリプションを設定さ れたため、サブスクリプション管理もモバイル アプリで行ってください。',
          tabs: {
            subscription: {
              title: 'サブスクライブ',
              pageTitleTwo: 'サブスクライブ',
              status: '使用中',
              pastDue: '未決済',
              month: '月',
              subscribe: 'サブスクライブ',
              change: '変更する',
            },
            onetime: {
              title: '個別商品',
              pageTitleTwo: '個別商品',
              purchase: '購入',
              history: '購入',
              invalidSubscriptionType: '購入するにはサブスクライブする必要があります',
            },
          },
          paymentModal: {
            usePromoCode: 'プロモーションコードを使用',
            promoCodePlaceholder: 'プロモーションコード',
            applyButton: '使用',
            promoCodeApplied: 'プロモーションコード適用',
            invalidPromoCode: '無効なプロモーションコードです',
            genericError: '再度お試しください',
            promoCodeError: {
              doesNotExist: 'Invalid promo code. Please check the code',
              invalidSubscriptionType: 'This promo code is only valid for PRO and PREMIUM plans',
              onlyFirstTime: 'This promo code is valid for first-time users only'
            }
          },
        },
        communityTab: {
          title: 'コミュニティ',
          subTitle: '希望の声を選んでAIカバーを作ってみてください!',
          inProgress: 'カバー中',
          tags: 'タグ',
          failed: '失敗',
          sortOptions: ['新しい順', 'いいね順', '製作回数', 'モデル名'],
          freeVersionTag: '無料プラン',
          freePrefix: '無料プランではウォーターマーク（透かし）が含まれます',
          voiceOptions: ['コミュニティ', '自分の音声', 'いいね'],
          tooltip: {
            contents: [
              'AIカバーには2~4分ほど掛かります',
              '音声学習には2~4時間掛かります',
              "作業が失敗になった場合、'Q&A'タブをご利用ください",
            ],
          },
          tabs: {
            inference: {
              title: 'AIカバー',
            },
            training: {
              title: 'AI音声',
            },
            vocalExtract: {
              title: 'MR除去',
            },
          },
          changeButton: '変更',
          cancelButton: 'キャンセル',
          confirmModelDelete: 'このモデルを削除しても再学習できません。削除しますか？',
          confirmInferenceDelete: '本当に削除しますか？',
          confirmVocalExtractDelete: '本当に削除しますか？',
          searchbar: '検索',
          infoIcon: {
            source: '著作権',
            voice: '音声',
            pitch: 'キー設定',
          },
          pagination: {
            loader: 'ロード中...',
          },
          modal: {
            error: 'ページを更新してください',
          },
          button: {
            share: 'モデル共有',
          },
        },
        supportTab: {
          title: 'Discord',
          link: 'https://discord.gg/AVREYrRZv4',
        },
        pipeline: {
          inferenceStatusMessage: {
            formatError: 'ファイル形式と拡張子が一致していないか、ファイルが破損しているか安全ではありません',
            volumeError:
              'ファイル内の音声の音量が小さすぎるか、音声が含まれていません。録音設定を確認するか、音声編集ソフトを使用して音量を上げてください。',
            invalidFileError: 'ファイルを開くことができません。ファイルを確認してください。',
            progress10: '進行中 10%',
            progress20: '進行中 20%',
            progress40: '進行中 40%',
            progress50: '進行中 50%',
            progress60: '進行中 60%',
            progress80: '進行中 80%',
            progress100: '進行中 100%',
            complete: '完了',
            generalMessage:
              'AIカバーが失敗しました。再度お試しください。問題が続く場合は、SORISORIサポートチームに連絡してください。',
            starting: '開始中',
          },
          trainingStatusMessage: {
            formatError: 'ファイル形式と拡張子が一致していないか、ファイルが破損しているか安全ではありません',
            volumeError:
              'ファイル内の音声の音量が小さすぎるか、音声が含まれていません。録音設定を確認するか、音声編集ソフトを使用して音量を上げてください。',
            invalidFileError: 'ファイルを開くことができません。ファイルを確認してください。',
            progress10: '進行中 10%',
            progress20: '進行中 20%',
            progress40: '進行中 40%',
            progress50: '進行中 50%',
            progress60: '進行中 60%',
            progress80: '進行中 80%',
            progress100: '進行中 100%',
            complete: '完了',
            generalMessage:
              'トレーニングが失敗しました。再度お試しください。問題が続く場合は、SORISORIサポートチームに連絡してください。',
            starting: '開始中',
          },
        },
        modal: {
          confirmationYes: 'はい',
          confirmationNo: 'いいえ',
          combined: 'ミックス',
          vocal: 'ボーカル',
          backVocal: 'コーラス',
          instrument: '伴奏',
          fileUploadFail: 'ファイルアップロードに失敗しました。ファイルをもう一回確認しやり直してください',
          trainingInProgressError: 'すでに学習中です',
          noRemainingError: '使用可能な回数をすべて使用しました',
          genericError: 'ファイルのアップロードに失敗しました。再試行してください',
          pageLoadFail: 'ページを読み込めませんでした。ページを更新してください',
          pageLoadFailOnSubmit:
            "ファイルアップロードに成功しましたが、ページをロードできませんでした。ページを更新してください。'ヒストリー'タブから確認できます",
          gotoProfile: 'マイプロフィールを見る',
        },
        mixingModal: {
          label: 'ミックス',
          title: 'ミキシング',
          sliders: {
            contents: ['キー調整', '音声ボリューム', 'リバーブボリューム', 'リバーブ長さ'],
          },
          tooltip: {
            contents: [
              '男性音声で女性曲をカバーする場合 -4 ~ -6 推奨',
              '女性音声で男性曲をカバーする場合 +4 ~ +6 推奨',
              '過度なキー調整は音質を低下させる可能性があります',
              'ボーカルトラックの音量',
              '0dBは元の曲のボーカル音量です',
              'リバーブ効果の大きさ',
              '0はリバーブ効果がなく、6はリバーブ効果が最大です',
              'リバーブ効果の長さ',
              'バラード曲には約2秒を推奨し、テンポの速い曲ほど低い数値を推奨します',
            ],
          },
          demo: {
            toggle: 'ミキシング',
            status: ['ミキシング不可', 'ミキシング可'],
            description: 'ミキシング機能の違いを聞いてみましょう',
          },
          dialog: {
            title: '現在の料金プランでは使用不可能な機能です',
            text: 'プロまたはプレミアムにアップグレードするとミキシング機能を使用することができます!',
          },
          upgradeButton: ['アップグレード', 'プロ'],
          alert: 'ミキサーの値を指定してください',
        },
        freeInferenceMessageModal: {
          messeageOne: ['無料版には広告が含まれており', '45秒に制限されています'],
          messeageTwo: ['有料製品を使用して', '広告なしのフルレングスの曲を生成します'],
          button: '購読する',
        },
        freeTrainingMessageModal: {
          messages: ['AI学習は有料版に切り替え後', 'ご利用いただけます'],
          button: 'プランを確認',
        },
        freeVocalExtractorMessageModal: {
          free: {
            message: [
              '無料ユーザーは',
              '最大1つのファイルを',
              'アップロードできます',
              'より多くのファイルを',
              'アップロードしたい場合は',
              'サブスクリプションに',
              '加入してください',
            ],
          },
          basic: {
            message: [
              '基本ユーザーは',
              '最大1つのファイルを',
              'アップロードできます',
              'より多くのファイルを',
              'アップロードしたい場合は',
              'サブスクリプションに',
              '加入してください',
            ],
          },
          button: 'サブスクする',
        },
        onetimeItems: {
          training: {
            name: '追加モデル製作',
            price: {
              usd: '$7',
              thb: '฿250',
              krw: '8,500ウォン',
              jpy: '¥990',
            },
          },
          vocalExtract: {
            name: '追加MR除去100回',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '5,000ウォン',
              jpy: '¥590',
            },
          },
          inference: {
            tooltip: '全曲を広告なしで制作可能です',
            name: '追加カバー制作5回',
            price: {
              usd: '$4.99',
              thb: '฿75',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
          tts: {
            name: '3000 TTS Credits',
            price: {
              usd: '$4.99',
              thb: '฿130',
              krw: '₩5,000',
              jpy: '¥590',
            },
          },
        },
        subscriptionItems: [
          {
            name: '無料プラン',
            model: 'FREE', //leave in english
            price: {
              usd: '$0',
              thb: '฿0',
              krw: '0ウォン',
              jpy: '¥0',
            },
            period: '',
            features: [
              {
                text: '速度 - 遅い',
              },
              {
                text: 'AIカバー毎月３回',
                tooltips: ['毎月３回までAIカバーができます'],
              },
              {
                text: 'ボーカル抽出及びAIミキシングを含む',
                tooltips: ['ソリソリのAIを用いて原曲に近いオーディオミキシングをします'],
              },
              {
                text: '学習性能 - 普通',
                tooltips: [
                  '10分未満の音声を約5分間学習します',
                  '下位GPUモデルを使用します。有料プランに変更することでより高い性能を使用できます',
                ],
              },
              {
                text: '音質 - 普通(MP3)',
                tooltips: ['MP3ファイルを提供します', 'ウォーターマーク（透かし）が含まれます'],
              },
              {
                text: 'MR除去 - 3曲',
                tooltips: ['ボーカル及びコーラス、伴奏を抽出する機能を提供します'],
              },
              {
                text: 'ベータ - 文字音声変換: 月20文字',
                tooltips: ['毎月文字音声変換機能を20文字 (1回の変換に20文字まで) まで提供します'],
              },
            ],
          },
          {
            name: '基本プラン',
            model: 'BASIC', //leave in english
            price: {
              usd: '$9.99',
              thb: '฿350',
              krw: '14,800ウォン',
              jpy: '¥1,699',
            },
            period: '/月',
            features: [
              {
                text: 'AI音声学習毎月１回',
                tooltips: ['毎月決済日に学習可能回数が初期化されます。使用してない学習回数は繰り上りされません'],
              },
              {
                text: '速度 - 普通',
              },
              {
                text: '毎月200回までAIカバーができます',
                tooltips: ['毎月決済日にAIカバー可能回数が初期化されます。使用してないカバー回数は繰り上りされません'],
              },
              {
                text: 'ボーカル抽出及びAIミキシングを含む',
                tooltips: ['ソリソリのAIを用いて原曲に近いオーディオミキシングをします'],
              },
              {
                text: '学習性能 - 高い',
                tooltips: [
                  '最大30分までの音声を約2時間学習します',
                  '中位GPUモデルを使用します。無料プランより高い性能が期待されます',
                ],
              },
              {
                text: '音質 - 普通(MP3)',
                tooltips: ['MP3ファイルを提供します'],
              },
              {
                text: '毎月10回までMR除去ができます',
                tooltips: ['ボーカル及びコーラス、伴奏を抽出する機能を毎月10回使用できます'],
              },
              {
                text: 'ベータ - 文字音声変換: 月200文字',
                tooltips: ['毎月文字音声変換機能を200文字 (1回の変換に50文字まで) まで提供します'],
              },
              {
                text: 'ベータ - 文字音声 学習: 月1回',
                tooltips: [
                  '文字音声変換に使用可能なボイス学習機能を月1回提供します。毎月決済日ごとに学習可能回数が初期化されます。未使用の学習回数はくりこしされません',
                ],
              },
            ],
          },
          {
            name: 'プロプラン',
            model: 'PRO', //leave in english
            price: {
              usd: '$24.80',
              thb: '฿880',
              krw: '28,800ウォン',
              jpy: '¥3,490',
            },
            period: '/月',
            features: [
              {
                text: ' AI音声学習毎月３回',
                tooltips: ['毎月決済日に学習可能回数が初期化されます。使用してない学習回数は繰り上りされません'],
              },
              {
                text: '速度 - 速い',
              },
              {
                text: ' AIカバー毎月1000回',
                tooltips: ['毎月決済日にAIカバー可能回数が初期化されます。使用してないカバー回数は繰り上りされません'],
              },
              {
                text: 'ボーカル抽出及びAIミキシングを含む',
                tooltips: ['ソリソリのAIを用いて原曲に近いオーディオミキシングをします'],
              },
              {
                text: '学習性能 - 非常に高い',
                tooltips: [
                  '最大45分までの音声を約2時間学習します',
                  '上位GPUモデルを使用します。基本プランより高い性能が期待されます',
                ],
              },
              {
                text: '音質 - 高い(WAV)',
                tooltips: ['WAVファイルを提供します'],
              },
              {
                text: '毎月50回までMR除去ができます',
                tooltips: ['ボーカル及びコーラス、伴奏を抽出する機能を毎月50回使用できます'],
              },
              {
                text: 'ベータ - 文字音声変換: 月4000文字',
                tooltips: ['毎月文字音声変換機能を4000文字 (1回の変換に200文字まで) まで提供します'],
              },
              {
                text: 'ベータ - 文字音声 学習: 月2回',
                tooltips: [
                  '文字音声変換に使用可能なボイス学習機能を月2回提供します。毎月決済日ごとに学習可能回数が初期化されます。未使用の学習回数はくりこしされません',
                ],
              },
            ],
          },
          {
            name: 'プレミアムプラン',
            model: 'PREMIUM', //leave in english
            price: {
              usd: '$68.80',
              thb: '฿2400',
              krw: '88,800ウォン',
              jpy: '¥8,990',
            },
            period: '/月',
            features: [
              {
                text: ' AI音声学習毎月13回',
                tooltips: ['毎月決済日に学習可能回数が初期化されます。使用してない学習回数は繰り上りされません'],
              },
              {
                text: '速度 - 非常に速い',
              },
              {
                text: 'AIカバー利用回数に制限なし',
                tooltips: ['AIカバーの使用回数に制限がありません'],
              },
              {
                text: 'ボーカル抽出及びAIミキシングを含む',
                tooltips: ['ソリソリのAIを用いて原曲に近いオーディオミキシングをします'],
              },
              {
                text: '学習性能 - 最上',
                tooltips: [
                  '最大90分までの音声を約3間学習します',
                  '最上位GPUモデルを使用します。プロプランより高い性能が期待されます',
                ],
              },
              {
                text: '音質 - 高い(WAV)',
                tooltips: ['WAVファイルを提供します'],
              },
              {
                text: 'MR除去利用回数に制限なし',
                tooltips: ['ボーカル及びコーラス、伴奏を抽出する機能を制限なく使用できます'],
              },
              {
                text: 'ベータ - 文字音声変換: 月20000文字',
                tooltips: ['毎月文字音声変換機能を20000文字 (1回の変換に1000文字まで) まで提供します'],
              },
              {
                text: 'ベータ - 文字音声 学習: 月3回',
                tooltips: [
                  '文字音声変換に使用可能なボイス学習機能を月3回提供します。毎月決済日ごとに学習可能回数が初期化されます。未使用の学習回数はくりこしされません',
                ],
              },
            ],
          },
        ],
        sectionHelp: 'サポート',
        faqTab: {
          title: 'よくある質問',
          placeholder: '検索...',
          faqs: [
            {
              title: 'AI学習関連の質問',
              qnas: [
                {
                  question: 'アップロードする時に出力できるファイル形式は何がありますか？',
                  answers: [
                    'mp3, wav, m4a, mkv, mp4, flac, ogg の６つを提供しています。高めの品質の音源を学習させるのが大事であり、ファイルを変換するたびに品質は低くなります。m4aの場合、モバイルレコーダーに該当するファイル形式です',
                  ],
                },
                {
                  question: 'ボーカル抽出は必修ですか',
                  answers: [
                    'ソリソリAIの学習過程には、すでに優秀なボーカル抽出機能が含まれています。従って、ボーカル抽出は必修ではありません',
                    '基本的には不要ですが、学習する前に音源の音質が確認してからカバー曲を作れるという長所があります。',
                    '"MR除去機能"からボーカル抽出ができます',
                  ],
                },
                {
                  question: '複数の曲をアップロードして学習させることもできますか？',
                  answers: [
                    'はい、複数の曲をアップロードすることもできます',
                    '同様に、直接録音の場合にも複数回に分けてアップロードできます',
                  ],
                },
                {
                  question: '録音するときにはどのマイクを使う必要がありますか',
                  answers: ['モバイルレコーダーでも大丈夫です。静かな環境で録音することをおすすめします'],
                },
                {
                  question: 'カラオケにて録音したものを学習させても大丈夫ですか？',
                  answers: [
                    'カラオケえの録音は音質も悪いし、何より音響効果が強くてお控え目です。破裂音を伴うカラオケ特有の鳴り響く音が音声モデルに入る可能性があります',
                  ],
                },
                {
                  question:
                    '録音してからは必ず確認してください。マイクとの距離が近くて破裂音が入ったりよく聞こえないことがよくあります',
                  answers: [],
                },
                {
                  question: '学習結果が気に入りません。もう１回モデルを学習させることはできますか？',
                  answers: [
                    'モデルを削除しても学習回数は復旧されません。学習開始と同時にサーバーに費用が発生するので、削除しても学習回数をすることはできかねます。何卒ご理解お願いします',
                  ],
                },
              ],
            },
            {
              title: 'AIカバー関連の質問',
              qnas: [
                {
                  question: '学習するとき繰り返してキャンセルされます',
                  answers: [
                    'アップロードした音源ファイルを確認してください。ファイルを変換する時に単純にファイル名を.mp3や.wavに変えたのではないか確認してください。ネット上のファイル形式変換器を利用してください',
                  ],
                },
                {
                  question: 'アップロードする時に出力できるファイル形式は何がありますか？',
                  answers: [
                    'mp3, wav, m4a, mkv, mp4, flac, ogg の６つを提供しています。高めの品質の音源を学習させるのが大事であり、ファイルを変換するたびに品質は低くなります。m4aの場合、モバイルレコーダーに該当するファイル形式です',
                  ],
                },
                {
                  question: 'ボーカル抽出は必修ですか',
                  answers: [
                    'ソリソリAIの学習過程には、すでに優秀なボーカル抽出機能が含まれています。従って、ボーカル抽出は必修ではありません',
                    '直接抽出するときには抽出した音源の音質が確認してからカバー曲を作れるという長所があります。この場合、"ボーカルだけが抽出されていますか？"に"はい"を選択してください',
                  ],
                },
                {
                  question:
                    '歌手グループの一人だけを学習させたい場合、その人の部分だけ切り取って学習させることもできますか',
                  answers: [
                    'はい,1つの音源から一人だけを学習させたい場合、その部分だけ切り取って学習させる必要があります',
                  ],
                },
                {
                  question: 'キー変換（設定）はどう使えばいいですか',
                  answers: [
                    '男性ボーカルと女性ボーカルは通常4~6キー差が出てます。例えば、男性モデルを使って女性曲をカバーする場合、キー設定から+4 ~ +6位あげてみてください但しキー変換の途中、音質を落とす効果が入る可能性があります。AI音声やカバーする原曲によって多少不安定な結果が出る可能性があります',
                  ],
                },
                {
                  question: 'ナレーションも学習できますか',
                  answers: [
                    'はい,できます。曲をカバーするときよりはクオリティが低い可能性はありますが学習させる音源によって自然な結果が得られることもあります',
                  ],
                },
                {
                  question: 'ヒストリータブからダウンロードができません',
                  answers: [
                    'NAVERアプリやNAVER電子メールなど、通常のインターネットブラウザ以外からの接続の場合、不具合が起こる可能性があります',
                    '基本インターネットアプリ、chrome、Safari、Firefoxなどの通常のインターネットブラウザから接続してもらうと解決駅ると思います',
                  ],
                },
              ],
            },
            {
              title: 'AIカバーTIP',
              qnas: [
                {
                  question:
                    '音源の品質はAIカバーに極めて大きい影響を及ぼします。youtube音源の場合、音源に大きい損失がありますので、melon、Bugsのような音源サイトから買った正式音源を使用してください',
                  answers: [],
                },
                {
                  question:
                    'オートチューンのような音響効果が多い場合やコーラス（バックボーカル）の強い音源はボーカルを綺麗に分離して学習しかねますので、お勧めしません',
                  answers: [],
                },
                {
                  question: '同様に複数人のボーカルの入った曲はお勧めしません',
                  answers: [],
                },
              ],
            },
            {
              title: '決済関連の質問',
              qnas: [
                {
                  question: 'プランのアップグレードする場合、二重決済になりますか？',
                  answers: ['アップグレードするプランと現在のプランの差額分決済されます'],
                },
                {
                  question: '今月までに利用したいですが、サブスクライブの取り消しはどうしますか',
                  answers: ["'プラン'タブから'変更する'を選択し、サブスクライブ取り消し재をしてください"],
                },
                {
                  question: '未使用の学習利用回数は次の月に繰り上がれますか',
                  answers: ['利用してない学習回数は繰り上りされません'],
                },
                {
                  question: '未使用のカバー利用回数はどうなりますか',
                  answers: ['利用してないカバー利用回数も繰り上りされません'],
                },
                {
                  question: 'カードは決済ができません',
                  answers: [
                    '現在、海外決済の使えるカードのみ対応しております。国内カード、kakaoペイ、tossペイなども使用できるように開発中であります',
                  ],
                },
                {
                  question: '払い戻しをしたいです',
                  answers: [
                    '払い戻しをするためには以下の条件を満たす必要があります',
                    '- 当月使用記録がないこと',
                    '- 払い戻しは決済日から3週（21日）以内にすること',
                    '- プランを問わず3000ウォンの手数料か掛かります',
                    "- サブスクライブ取り消しを利用者様、本人がしてください。 '変更する'を選択し、サブスクライブ取り消しをしてください",
                  ],
                },
              ],
            },
            {
              title: 'その他',
              qnas: [
                {
                  question: 'アカウント削除をしたいです',
                  answers: ['discordから案内しております', '利用者様本人からできるように開発中です'],
                },
                {
                  question: 'discordって何ですか',
                  answers: [
                    '多くのソリソリAIユーザー様が利用しているコミュニティです。情報共有や、他人のカバー結果の評価することもできるプラットフォームです。',
                  ],
                },
                {
                  question: '今月使用できる回数をすべて使い尽くしました。追加で音声学習やMR除去をすることはできますか',
                  answers: [
                    'プランページから個別商品を選択すると利用できます',
                    '個別商品を買うよりプランのアップグレードの方がコスパ良いです',
                  ],
                },
              ],
            },
          ],
        },
        contactTab: {
          title: '問い合わせ',
          placeholder: {
            type: '問い合わせ内容',
            email: 'e-mail(電子メール)',
            text: '内容を入力してください',
            refund:
              "払い戻しをするためには以下の条件を満たす必要があります\n\n1. 当月使用記録がないこと\n2. 払い戻しは決済日から3週（21日）以内にすること\n3. プランを問わず3000ウォンの手数料か掛かります\n4. サブスクライブ取り消しを利用者様、本人がしてください。 '変更する'を選択し、サブスクライブ取り消し재をしてください",
          },
          message: {
            type: '問い合わせの内容を選択してください',
            email: '正しくないe-mailです',
            content: '内容を入力してください',
            loading: '情報を送信しました。少々お待ちください',
            success: '送信に成功しました',
            fail: '送信に失敗しました。やりない押してください',
            useDiscord: '学習及びカバー関連の質問はよくある質問タブ、またはdiscordコミュニティを利用してください',
          },
          contactType: ['学習・カバー機能関連の質問', 'API問い合わせ', '払い戻し問い合わせ', 'ビジネス問い合わせ'],
          submit: '登録する',
        },
        emailPreferenceTab: {
          title: 'メール設定',
          header: '特別なオファー、新機能、重要なお知らせを受け取りましょう',
          choiceLabels: {
            announcement: 'お知らせ',
            notification: '通知',
            newsletter: 'ニュースレター',
            promotion: 'プロモーション',
          },
          submit: {
            save: '保存',
          },
          modal: {
            success: '登録が完了しました',
            error: '問題が発生しました。再試行してください。',
          },
        },

        profileTab: {
          title: 'マイプロフィール',
          sections: {
            login: {
              title: 'ログイン情報',
              logout: 'ログアウト',
            },
            payment: {
              title: 'サブスクリプション情報',
              changePayment: '決済手段の変更',
              updateSubscription: '料金プランの変更',
              cancelSubscriptionChange: 'サブスクリプション変更のキャンセル',
              history: '決済履歴を見る',
              cancel: 'サブスクリプションのキャンセル',
              currentSubscription: '現在の料金プラン',
              nextPaymentDate: '次の決済日',
              currentCycle: '現在のサイクル',
              nextSubscriptionType: '次の料金プラン',
            },
            question: {
              title: 'お問い合わせ',
              contentPre: '決済および返金に関するその他の質問は',
              contentLink: 'お問い合わせ',
              contentPost: 'タブをご利用いただけると幸いです。',
            },
          },
          subscriptionType: {
            FREE: 'FREE',
            BASIC: 'BASIC',
            PRO: 'PRO',
            PREMIUM: 'PREMIUM',
          },
        },
        paymentModal: {
          title: '決済履歴',
          date: '日付',
          info: '情報',
          amount: '金額',
        },
      },
    },
  },
});

export default i18n;
