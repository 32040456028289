import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#191919',
    borderRadius: '1.5rem',
    color: '#c4c4c4',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem 3rem',
    wordBreak: 'keep-all!important',
    fontSize: '1.2rem',
    fontWeight: '400',
    justifyContent: 'space-evenly',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  sorisoriAIImg: {
    width: '65%',
    marginBottom: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1.5rem',
  },
  tossButton: {
    color: '#fff',
    fontWeight: '200',
    border: '0.5px solid',
    padding: '.35rem',
    width: '11rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '11px',
    fontSize: '18px',
    cursor: 'pointer',
    marginTop: '1rem',
  },
  stripeButton: {
    color: '#fff',
    fontWeight: '200',
    border: '0.5px solid',
    width: '11rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '11px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  orderInfo: {
    marginBottom: '0.5rem',
  },
  promoCodeContainer: {
    display: 'flex',
    marginTop: '2rem',
    width: '14rem',
  },
  usePromocode: {
    fontSize: '0.8rem',
    marginTop: '1rem',
    cursor: 'pointer',
  },
  promoCodeApplied: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  promoCodeAppliedText: {
    fontSize: '0.9rem',
  },
  deleteButtonImg: {
    width: '1.2rem!important',
    height: '1.1rem!important',
    marginBottom: '0.2rem',
    marginLeft: '0.1rem',
    cursor: 'pointer',
  },
  appliedPromocode: {
    marginLeft: '0.2rem',
    color: '#D1FE75',
  },
  promoCodeInput: {
    maxWidth: '20rem',
    width: '100%',
    height: '2.5rem',
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
}));

export { useStyles };
