import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    display: 'flex',
    paddingLeft: '0.2rem',
    fontSize: '2.5rem',
    color: '#fff',
  },
  loading: {
    width: '1rem!important',
    height: '1rem!important',
    marginLeft: '1rem',
  },
  loadingCancelSubscriptionChange: {
    width: '1rem!important',
    height: '1rem!important',
    margin: '0 1.5rem 1rem 1rem',
  },
  section: {
    display: 'flex',
  },
  sectionTitle: {
    color: '#9B9B9B',
    fontSize: '1.2rem',

    fontWeight: '400',
    marginBottom: '1rem',
    marginRight: '2rem',
    minWidth: '6.2rem',
    maxWidth: '6.2rem',
  },
  sectionContent: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
    flex: 1,
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
    },
  },
  contentContact: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
    },
    color: '#fff',

    fontWeight: '400',
    fontSize: '1rem',
  },
  loginLogo: {
    width: '0.8rem',
    height: '0.8rem',
    marginRight: '0.4rem',
  },
  contentElem: {
    marginBottom: '1rem',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  contentLink: {
    marginBottom: '1rem',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#4496E9',
    textAlign: 'right',
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingLeft: '0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
    },
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '3rem',
    alignItems: 'flex-end',
  },
  contactLink: {
    cursor: 'pointer',
    color: '#4496E9',
    margin: '0 0.2rem',
  },
  elemBig: {
    color: '#ffffff',
    fontSize: '1.2rem',
    display: 'flex',
    marginBottom: '0.7rem',
    alignItems: 'center',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  subscriptionTypeText: {
    marginLeft: '0.5rem',
    color: '#D1FE75',
    cursor: 'pointer',
  },
  nextPaymentDate: {
    fontWeight: '300',
    fontSize: '0.7rem',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  cardImg: {
    width: '3rem',
    height: '2rem',
    marginRight: '0.3rem',
  },
  cardImgVisa: {
    width: '3rem',
    height: '1rem',
    marginRight: '0.3rem',
  },
  playStoreImg: {
    width: '1.5rem',
    height: '1.5rem',
  },
  tossImg: {
    width: '7.5rem',
    height: '2.5rem',
  },
}));

export { useStyles };
