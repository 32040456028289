import { userConstants } from '../constants/user.constants';

const INITIAL_STATE = {
  loggedIn: false,
  id: null,
  name: null,
  loading: false,
  email: null,
};

export function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.SET_LOADING:
      return {
        ...state,
        loading: action.user.loading,
      };
    case userConstants.LOGOUT:
      return INITIAL_STATE;
    case userConstants.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription,
      };
    case userConstants.SET_USER_CURRENCY:
      return {
        ...state,
        currency: action.currency,
      };
    case userConstants.SET_USER:
      return {
        id: action.user.id,
        name: action.user.name,
        email: action.user.email,
        subscription: action.user.subscription,
        currency: action.user.currency,
        provider: action.user.provider,
        loggedIn: true,
      };
    default:
      return state;
  }
}
