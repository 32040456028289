import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '1rem',
    wordBreak: 'keep-all!important',
    fontSize: '1.2rem',
    fontWeight: '400',
    borderRadius: '0.3rem',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  innerTitleContainer: {
    display: 'flex',
    marginBottom: '0.8rem',
    fontSize: '1rem',
  },
  innerContainer: {
    display: 'flex',
    marginBottom: '0.8rem',
    fontSize: '0.8rem',
    alignItems: 'center',
  },
  dateElem: {
    width: '6rem',
  },
  infoElem: {
    flex: 1,
  },
  amountElem: {
    width: '4rem',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    alignSelf: 'center',
  },
  separator: {
    borderBottomWidth: '2px',
    borderBottomColor: '#5D5D5D',
    borderBottomStyle: 'solid',
    margin: '0.5rem 0',
  },
  downloadIcon: {
    width: '1.1rem!important',
    height: '1rem!important',
    cursor: 'pointer',
    marginLeft: '0.2rem',
  },
}));

export { useStyles };
